import { ariaAttr } from '@b2w/shared/utility';
import { FormControlMainProps, useFormControlCtx } from '../form-control.init';
import { ComponentProps } from '../../types';

export type UseInputFormControlOwnProps = FormControlMainProps & {
  /** Force ignore `FormControl` context if rendered under `<FormControl>` element */
  UNSAFE_skipFormControlContext?: boolean;
};

export type UseInputFormControlProps<T extends React.ElementType> =
  ComponentProps<T, UseInputFormControlOwnProps>;

/**
 * Enhance passed options with `FormControl` context. If context was not
 * provided, return options as they are.
 */
export function useInputFormControl<T extends React.ElementType = 'input'>(
  props: UseInputFormControlProps<T>
): React.ComponentPropsWithoutRef<T> {
  const {
    id,
    isDisabled,
    isReadOnly,
    isRequired,
    isInvalid,
    disabled,
    readOnly,
    required,
    UNSAFE_skipFormControlContext = false,
    ...htmlProps
  } = props;

  const formControlCtx = useFormControlCtx();
  const formControl = UNSAFE_skipFormControlContext ? null : formControlCtx;

  const disabledFinal = disabled ?? isDisabled ?? formControl?.isDisabled;
  const readonlyFinal = readOnly ?? isReadOnly ?? formControl?.isReadOnly;
  const requiredFinal = required ?? isRequired ?? formControl?.isRequired;
  const invalidFinal = isInvalid ?? formControl?.isInvalid;

  const labelIds: string[] = (htmlProps as any)['aria-describedby']
    ? [(htmlProps as any)['aria-describedby']]
    : [];

  // Error message must be described first in all scenarios.
  if (formControl?.hasErrorMessage && invalidFinal) {
    labelIds.push(formControl.errorMessageId);
  }

  if (formControl?.hasDescriptionMessage) {
    labelIds.push(formControl.descriptionMessageId);
  }

  return {
    ...htmlProps,
    id: id ?? formControl?.inputId,
    disabled: disabledFinal,
    readOnly: readonlyFinal,
    required: requiredFinal,
    'aria-readonly': ariaAttr(readonlyFinal),
    'aria-required': ariaAttr(requiredFinal),
    'aria-invalid': ariaAttr(invalidFinal),
    'aria-describedby': labelIds.join(' ') || undefined
  } as React.ComponentPropsWithoutRef<T>;
}
