import RoleManager from '@/lib/profile/role.manager';
import { PrivateProfileDTO } from '@b2w/shared/types';
import { profileService } from '@/lib/services/profile.service';
import { MenuItem } from '@/lib/menu-definition.types';
import ViewMyProfileHeader from '@/components/ViewMyProfileHeader';
import YourAccSuspendedMsg from '@/components/YourAccSuspendedMsg';
import AdminBadge from '@/components/AdminBadge';
import SignOutBtn from '@/components/SignOutBtn';
import {
  AffPageTab,
  affTabHref
} from '@/components/affiliate-dashboard/affiliate-page.utils';
import B2WPointsBadge from '@/components/dashboard/B2WPointsBadge';

export type DesktopProfilePopupMenuItemCtxData = PrivateProfileDTO;

export const desktopProfilePopupMenuItems: MenuItem<DesktopProfilePopupMenuItemCtxData>[] =
  [
    {
      type: 'custom',
      isHoverable: true,
      renderCustomItem: (_, className) => (
        <ViewMyProfileHeader avatarSizePx={45} className={className} />
      )
    },
    {
      type: 'conditional',
      visibleByData: (profile) => {
        return profileService.isProfileSuspended(profile)
          ? {
              type: 'custom',
              renderCustomItem: (_, className) => (
                <YourAccSuspendedMsg newStyle className={className} />
              )
            }
          : null;
      }
    },
    {
      type: 'link',
      text: 'Rentals',
      href: '/dashboard/rentals',
      isHoverable: true,
      isOutlined: true
    },
    {
      type: 'link',
      text: 'Favorites',
      href: '/dashboard/favorites',
      isHoverable: true,
      isOutlined: true
    },
    {
      type: 'link',
      text: 'B2W Points',
      href: '/dashboard/b2wpoints',
      isHoverable: true,
      isOutlined: true,
      iconContent: (profile) =>
        !!profile && (
          <B2WPointsBadge
            points={profile.b2wPoints.earnedCurrent}
            size="sm"
            outlined
          />
        )
    },
    {
      type: 'separator'
    },
    {
      type: 'link',
      text: 'Profile settings',
      href: '/dashboard/settings',
      isHoverable: true
    },
    {
      type: 'conditional',
      visibleByData: (profile: PrivateProfileDTO) => {
        return profile?.affiliateId
          ? {
              type: 'link',
              text: 'Affiliate dashboard',
              href: affTabHref(AffPageTab.dashboard),
              isHoverable: true
            }
          : {
              type: 'link',
              text: 'Become an affiliate',
              href: '/affiliate',
              isHoverable: true
            };
      }
    },
    {
      type: 'conditional',
      visibleByData: (profile: PrivateProfileDTO) => {
        return RoleManager.canAccessAdmin(profile)
          ? {
              type: 'separator'
            }
          : null;
      }
    },
    {
      type: 'conditional',
      visibleByData: (profile: PrivateProfileDTO) => {
        return RoleManager.canAccessAdmin(profile)
          ? {
              type: 'link',
              text: 'Admin panel',
              href: '/_admin',
              isHoverable: true,
              iconContent: () => (
                <AdminBadge
                  message="Only available to admin accounts"
                  newStyle
                />
              )
            }
          : null;
      }
    },
    {
      type: 'separator'
    },
    {
      type: 'accordion',
      text: 'Help & Support',
      links: [
        {
          type: 'link',
          text: 'Support',
          href: '/support',
          isInsideSubmenu: true,
          isHoverable: true
        },
        {
          type: 'link',
          text: 'How it works',
          href: '/howitworks',
          isInsideSubmenu: true,
          isHoverable: true
        },
        {
          type: 'link',
          text: 'Insurance',
          href: '/insurance',
          isInsideSubmenu: true,
          isHoverable: true
        },
        {
          type: 'link',
          text: 'Contact us',
          href: '/contact',
          isInsideSubmenu: true,
          isHoverable: true
        }
      ]
    },
    {
      type: 'custom',
      renderCustomItem: (_, className) => (
        <div className={className}>
          <SignOutBtn colorScheme="neutral" variant="ghost" block />
        </div>
      )
    }
  ];
