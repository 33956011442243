import {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  useCallback
} from 'react';
import { ChatRoomDTO, ProfileDTO } from '@b2w/shared/types';
import { useUser } from './user.context';
import { chatService } from '@/lib/services/chat.service';

const ChatRoomsContext = createContext<ChatRoomDTO[] | null>(null);
export const useChatRooms = () => useContext(ChatRoomsContext);

const LoadMoreRoomsContext = createContext<{
  loadMoreRooms: () => Promise<void>;
  hasMoreToLoad: boolean;
  isLoadingMore: boolean;
}>(null);
export const useLoadMoreChatRooms = () => useContext(LoadMoreRoomsContext);

export const ROOMS_PAGE_SIZE = 20;

// cache profiles to prevent fetching every time
const profileCache = new Map<string, ProfileDTO>();

export const ChatRoomsProvider = ({ children }) => {
  const { user } = useUser();
  const userId = user?.uid;

  const [chatRooms, setChatRooms] = useState<ChatRoomDTO[] | null>(null);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(false);
  const lastRoomRef = useRef<ChatRoomDTO | null>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    if (!userId) return;

    chatService
      .getChatRooms(profileCache, userId, ROOMS_PAGE_SIZE)
      .then((rooms) => {
        setChatRooms(rooms);

        if (rooms.length > 0) {
          lastRoomRef.current = rooms[rooms.length - 1];
        }

        if (rooms.length === ROOMS_PAGE_SIZE) {
          setHasMoreToLoad(true);
        }
      });
  }, [userId, setChatRooms]);

  useEffect(() => {
    if (!userId) return;

    let unsub: () => any;

    async function listenToRoomUpdates() {
      unsub = await chatService.$subToChatRoomUpdates(
        profileCache,
        userId,
        (updatedRooms) => {
          setChatRooms((_state) => {
            const rooms = _state ? Array.from(_state) : _state;

            if (!rooms || !rooms?.length) return rooms;

            updatedRooms.forEach((updRoom) => {
              const existingRoomIdx = rooms.findIndex(
                (r) => r.id === updRoom.id
              );

              if (existingRoomIdx >= 0) {
                // update room
                rooms[existingRoomIdx] = updRoom;
              } else {
                // add new room
                rooms.push(updRoom);
              }
            });

            // client-side sorting
            rooms.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
            rooms.sort((a, b) => {
              return a.latestMessage?.createdAt > b.latestMessage?.createdAt
                ? -1
                : 1;
            });

            if (rooms.length > 0) {
              lastRoomRef.current = rooms[rooms.length - 1];
            }

            return rooms;
          });
        },
        (err) => {
          console.log('[ROOMS LISTENER]', err.message);
        }
      );
    }

    listenToRoomUpdates();

    return () => {
      unsub && unsub();
    };
  }, [userId, setChatRooms]);

  const loadMoreRooms = useCallback(async () => {
    const lastRoom = lastRoomRef.current;

    if (lastRoom) {
      setIsLoadingMore(true);

      const rooms = await chatService.getChatRooms(
        profileCache,
        userId,
        ROOMS_PAGE_SIZE,
        {
          createdAt: lastRoom.createdAt,
          latestMessageCreatedAt: lastRoom.latestMessage?.createdAt
        }
      );

      setChatRooms((curr) => curr.concat(rooms));

      if (rooms.length > 0) {
        lastRoomRef.current = rooms[rooms.length - 1];
      }

      if (rooms.length < ROOMS_PAGE_SIZE) {
        setHasMoreToLoad(false);
      }

      setIsLoadingMore(false);
    }
  }, [userId]);

  return (
    <ChatRoomsContext.Provider value={chatRooms}>
      <LoadMoreRoomsContext.Provider
        value={{ loadMoreRooms, hasMoreToLoad, isLoadingMore }}
      >
        {children}
      </LoadMoreRoomsContext.Provider>
    </ChatRoomsContext.Provider>
  );
};
