import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { CommonFadeProps } from './types';
import { assignFadeCssVars, classes } from './Fade.classes';

export interface TransientFadeProps extends CommonFadeProps {
  /**
   * Unique key for current active child element
   */
  animationKey: string | number;
}

const animationClassnames = {
  enter: classes.enter,
  enterActive: classes.enterActive,
  exit: classes.exit,
  exitActive: classes.exitActive
};

/**
 * Fade animation when transitioning between elements
 *
 * Useful for animation route/tab navigation
 */
export const TransientFade = ({
  children,
  animationKey,
  className = '',
  duration = 300,
  initialOffsetX = 0,
  initialOffsetY = 0,
  finalOffsetX = 0,
  finalOffsetY = 0,
  initialScale = 1,
  finalScale = 1
}: TransientFadeProps): JSX.Element => {
  const wrapStyles = assignFadeCssVars({
    duration,
    initialOffsetX,
    finalOffsetX,
    initialOffsetY,
    finalOffsetY,
    initialScale,
    finalScale
  });

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={animationKey}
        timeout={duration}
        classNames={animationClassnames}
      >
        <div style={wrapStyles} className={className}>
          {children}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
