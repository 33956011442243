import { useIsMounted } from '@/custom-hooks/useIsMounted';
import { Tooltip, twCx } from '@b2w/react-ui/core2';
import { getRandom } from '@b2w/shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLProps, useMemo } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

type Props = HTMLProps<HTMLDivElement> & {
  message?: string;
  newStyle?: boolean;
};

const AdminBadge = ({
  message = 'This user is a legitimate admin',
  newStyle,
  className
}: Props) => {
  const isMounted = useIsMounted();
  const id = useMemo(
    () =>
      isMounted ? `admin-badge-${getRandom(0, 500).toString()}` : undefined,
    [isMounted]
  );

  if (newStyle) {
    return (
      <Tooltip title={message} distancePx={12} placement={'bottom'} arrow>
        <div
          id={id}
          className={twCx(
            'tw-inline-block px-2 tw-leading-6 tw-bg-error-200 tw-border-solid tw-border-error-600 tw-border tw-rounded-full tw-text-sm tw-text-error-900',
            className
          )}
        >
          Admin
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <Badge id={id} color="danger" className="">
        ADMIN
      </Badge>
      {isMounted && (
        <UncontrolledTooltip target={id} style={{ maxWidth: 300 }}>
          <FontAwesomeIcon icon="check" className="mr-1" />
          {message}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default AdminBadge;
