import { forwardRef, useId } from 'react';
import {
  UseInputFormControlOwnProps,
  FormLabel,
  useInputFormControl
} from '../FormControl';
import { useRadioGroupCtx } from './radio-group.init';
import { useFormControlCtx } from '../FormControl/form-control.init';
import { twCx } from '../twMerge';

const radioBgClassName = twCx(
  'tw-w-5 tw-h-5 tw-rounded-full',
  'before:tw-absolute before:tw-top-0 before:tw-left-0 before:tw-w-full before:tw-h-full',
  'before:tw-rounded-full before:tw-border',
  'before:tw-transition before:tw-duration-75',
  'active:before:tw-bg-gray-50',
  // valid border
  'before:tw-border-gray-200',
  'hover:before:tw-border-gray-300',
  'checked:before:tw-border-main-500',
  'checked:hover:before:tw-border-main-600',
  'active:checked:before:tw-border-main-700',
  // valid bg
  'checked:before:tw-bg-main-500',
  'checked:hover:before:tw-bg-main-600',
  'active:checked:before:tw-bg-main-700',
  // invalid border
  'aria-[invalid=true]:before:tw-border-error-300',
  'aria-[invalid=true]:hover:before:tw-border-error-400',
  'aria-[invalid=true]:checked:before:tw-border-error-500',
  'aria-[invalid=true]:checked:hover:before:tw-border-error-600',
  'aria-[invalid=true]:active:checked:before:tw-border-error-700',
  // invalid bg
  'aria-[invalid=true]:checked:before:tw-bg-error-500',
  'aria-[invalid=true]:checked:hover:before:tw-bg-error-600',
  'aria-[invalid=true]:active:checked:before:tw-bg-error-700'
);

const radioDefaultClassName = twCx(
  radioBgClassName,
  // circle
  `after:tw-absolute after:tw-block after:tw-content-[''] after:tw--translate-x-1/2 after:tw--translate-y-1/2 after:tw-top-1/2 after:tw-left-1/2`,
  'after:tw-w-2 after:tw-h-2 after:tw-bg-white after:tw-rounded-full',
  'after:tw-border-white',
  'after:tw-transition after:tw-duration-75',
  'after:tw-opacity-0 after:tw-scale-50',
  'checked:after:tw-opacity-100 checked:after:tw-scale-100'
);

export interface RadioProps
  extends Omit<
      React.ComponentPropsWithRef<'input'>,
      'type' | 'value' | 'name' | keyof UseInputFormControlOwnProps
    >,
    UseInputFormControlOwnProps {
  /** Value Radio represents */
  value: string;
  /** Name for group of radios */
  name?: string;
  /** Radio Label */
  label?: React.ReactNode;
  showRequiredIndicator?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    label,
    className: classNameProp,
    showRequiredIndicator,
    ...inputHtmlProps
  } = props;

  const groupCtx = useRadioGroupCtx();
  const formControlCtx = useFormControlCtx();

  const isWithinRadioGroup = !!groupCtx;
  const isWithinFormControl = !!formControlCtx;

  const inputProps = useInputFormControl<typeof Radio>(
    groupCtx ? groupCtx.getRadioProps(inputHtmlProps) : inputHtmlProps
  );

  const uuid = useId();
  const id =
    inputHtmlProps.id ?? (isWithinFormControl && !isWithinRadioGroup)
      ? inputProps.id
      : uuid;

  return (
    <div className={twCx('tw-inline-flex tw-items-center', classNameProp)}>
      <input
        {...inputProps}
        id={id}
        type="radio"
        ref={ref}
        className={twCx(
          'tw-appearance-none tw-cursor-pointer tw-relative tw-peer tw-flex-shrink-0',
          'focus-visible:tw-outline tw-outline-2 tw-outline-offset-2 tw-outline-main-500 aria-[invalid=true]:tw-outline-error-500',
          'disabled:tw-cursor-not-allowed disabled:tw-opacity-75',
          radioDefaultClassName
        )}
      />
      {!!label && (
        <FormLabel
          htmlFor={id}
          className="tw-pl-2 peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-75 tw-mb-0 tw-font-normal tw-text-base"
          requiredIndicator={showRequiredIndicator}
        >
          {label}
        </FormLabel>
      )}
    </div>
  );
});
