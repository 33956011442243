import { IconCoinsPile, IconSize, twCx } from '@b2w/react-ui/core2';
import { forwardRef } from 'react';

type B2WPointsBadgeSize = 'sm' | 'md' | '2xl';

const sizeToIconSize: Record<B2WPointsBadgeSize, IconSize> = {
  sm: 'xs',
  md: 'sm',
  '2xl': 'lg'
};

type B2WPointsBadgeProps = {
  points: number;
  /** @default 'md' */
  size?: B2WPointsBadgeSize;
  /** @default false */
  outlined?: boolean;
};

const B2WPointsBadge = forwardRef(
  (
    { points, size = 'md', outlined = false }: B2WPointsBadgeProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const className = twCx(
      'tw-flex tw-gap-2 tw-bg-white tw-items-center tw-border tw-rounded-full',
      outlined ? 'tw-font-medium' : 'tw-font-normal',
      size === 'sm' && 'tw-text-sm tw-px-2',
      size === 'md' && 'tw-text-md tw-px-3',
      size === '2xl' && 'tw-text-2xl tw-px-4'
    );

    return (
      <div ref={ref} className={className}>
        <IconCoinsPile.Outline
          size={sizeToIconSize[size]}
          className="tw-text-success-600"
        />
        {points}
      </div>
    );
  }
);

export default B2WPointsBadge;
