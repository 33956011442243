import { forwardRef } from 'react';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import {
  Button,
  ButtonOwnProps,
  ButtonProps,
  ButtonSize,
  buttonSizeToIconSize
} from './Button';
import { IconSize } from '../Icon';
import { twCx } from '../twMerge';

const sizeToPaddingClassName: Record<ButtonSize, string> = {
  xs: 'tw-py-2 tw-px-2',
  sm: 'tw-py-[calc(theme(space.2)_*_1.0625)] tw-px-[calc(theme(space.2)_*_1.0625)]',
  md: 'tw-py-2.5 tw-px-2.5',
  lg: 'tw-py-[calc(theme(space.3)_*_1.0845)] tw-px-[calc(theme(space.3)_*_1.0845)]'
};

export type IconButtonOwnProps = Pick<
  ButtonOwnProps,
  'colorScheme' | 'isLoading' | 'size' | 'variant'
> & {
  /** The icon to be used in the button. Use callback to get correctly adjusted icon size to button size  */
  icon: JSX.Element | ((iconSize: IconSize) => JSX.Element);
  /** A label that describes the button. */
  'aria-label': string;
};

export type IconButtonProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropsWithRef<C, IconButtonOwnProps>;

type IconButtonComponent = <C extends React.ElementType = 'button'>(
  props: IconButtonProps<C>
) => JSX.Element;

export const IconButton: IconButtonComponent = forwardRef(
  <T extends React.ElementType = 'button'>(
    props: PolymorphicComponentProps<T, IconButtonOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const { icon, 'aria-label': ariaLabel, size = 'md', ...otherProps } = props;

    const className = twCx(
      'tw-p-0',
      sizeToPaddingClassName[size],
      otherProps.className
    );

    return (
      <Button
        {...(otherProps as ButtonProps)}
        ref={ref}
        size={size}
        className={className}
        aria-label={ariaLabel}
      >
        {typeof icon === 'function' ? icon(buttonSizeToIconSize[size]) : icon}
      </Button>
    );
  }
) as IconButtonComponent;
