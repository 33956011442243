import { twCx } from '../twMerge';
import { IconCircleSpinner, IconProps } from '../Icon';

export type SpinnerProps = IconProps;

export const Spinner = (props: SpinnerProps) => {
  const { className: classNameProp, ...iconProps } = props;

  const className = twCx('tw-animate-spin', classNameProp);

  return <IconCircleSpinner.Solid {...iconProps} className={className} />;
};
