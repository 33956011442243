import { createIcon } from '../createIcon';

export const IconTimetable = {
  Outline: createIcon({
    viewBox: '0 0 70 70',
    fill: 'currentColor',
    pathOrChildren: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12V3C16 1.34315 14.6569 0 13 0C11.3431 0 10 1.34315 10 3V12H3C1.34315 12 0 13.3431 0 15V67C0 68.6569 1.34315 70 3 70H67C68.6569 70 70 68.6569 70 67V34V28V15C70 13.3431 68.6569 12 67 12H61V3C61 1.34315 59.6569 0 58 0C56.3431 0 55 1.34315 55 3V12H16ZM64 28V18H6V28H64ZM6 34V64H64V34H6ZM14 43C14 41.3431 15.3431 40 17 40H37C38.6569 40 40 41.3431 40 43C40 44.6569 38.6569 46 37 46H17C15.3431 46 14 44.6569 14 43ZM17 51C15.3431 51 14 52.3431 14 54C14 55.6569 15.3431 57 17 57H54C55.6569 57 57 55.6569 57 54C57 52.3431 55.6569 51 54 51H17Z"
      />
    )
  })
};
