import { publicEnv } from '@config';
import { useId } from 'react';
import DotsLoader from '../loaders/DotsLoader';
import { AFF_REF_PARAM_NAME } from './affiliate.param';

export enum AffPageTab {
  dashboard = 'DASHBOARD',
  faq = 'FAQ',
  widget = 'WIDGET',
  assets = 'ASSETS'
}

export const DEFAULT_AFF_PAGE_TAB = AffPageTab.dashboard;
export const AFF_PAGE_TAB_PARAM_NAME = 'tab';

export const AFF_DASHBOARD_URL = '/affiliate/d';

/**
 * Get href of a affiliate page tab
 *
 * @returns href of the tab that must be opened.
 */
export function affTabHref(tab?: AffPageTab | null) {
  return tab
    ? `${AFF_DASHBOARD_URL}?${AFF_PAGE_TAB_PARAM_NAME}=${tab}`
    : AFF_DASHBOARD_URL;
}

export type IAffMenuItem = {
  href: string;
  background: string;
  icon: string;
  text: string;
  tabId?: AffPageTab;
  isExternal?: boolean;
};

export const affMenuItems: IAffMenuItem[] = [
  {
    background: '/images/affiliate/menu-arrow-guideline_sprite.png',
    icon: '/images/affiliate/icon-guideline.png',
    href: affTabHref(AffPageTab.dashboard),
    tabId: AffPageTab.dashboard,
    text: 'Home'
  },
  {
    background: '/images/affiliate/menu-arrow-faq_sprite.png',
    icon: '/images/affiliate/icon-faq.png',
    href: affTabHref(AffPageTab.faq),
    tabId: AffPageTab.faq,
    text: 'FAQ'
  },
  {
    background: '/images/affiliate/menu-arrow-widget_sprite.png',
    icon: '/images/affiliate/icon-widget.png',
    href: affTabHref(AffPageTab.widget),
    tabId: AffPageTab.widget,
    text: 'Widget'
  },
  {
    background: '/images/affiliate/menu-arrow-assets_sprite.png',
    icon: '/images/affiliate/icon-assets.png',
    href: 'https://drive.google.com/drive/folders/1kYW4HNLrMFkjZpjTtTNw0sOV_0_baeJ_',
    isExternal: true,
    text: 'Assets'
  },
  {
    background: '/images/affiliate/menu-arrow-assets_sprite.png',
    icon: '/images/affiliate/icon-profile.png',
    href: '/dashboard',
    isExternal: false,
    text: 'Profile'
  }
];

export const AffPageLoadSkeleton = () => {
  const uniqueId = useId();

  return (
    <div className="my-5">
      <DotsLoader
        className="tw-mx-auto"
        uniqueKey={uniqueId}
        width={100}
        height={44}
        radius={10}
      />
    </div>
  );
};

export const getReferenceLink = (id: string) => {
  return window.location.origin + `?${AFF_REF_PARAM_NAME}=` + id;
};

export const metaImage =
  publicEnv.app.hostUrl + '/images/affiliate/pagemeta.png';
