import { useRef, useEffect, MutableRefObject } from 'react';
import { ListGroup, ListGroupItem, Button, Badge, Fade } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppLink from '@/components/AppLink';
import Router, { useRouter } from 'next/router';
import { useProfile } from '@/context/profile.context';
import Drawer from '@/components/Drawer';
import {
  accountLinks,
  ownerLinks
} from '@/components/dashboard/dashboard-routes';
import { useOnClickOutside } from '@/custom-hooks/useOnClickOutside';
import { companyName } from '@config';
import roleManager from '@/lib/profile/role.manager';
import useTranslation from 'next-translate/useTranslation';
import { useUserTempData } from '@/context/usertempdata.context';
import { useUser } from '@/context/user.context';
import ContentLoader from 'react-content-loader';
import YourAccSuspendedMsg from '@/components/YourAccSuspendedMsg';
import { profileService } from '@/lib/services/profile.service';
import SingleStar from '@/components/StarRating/SingleStar';
import AvatarLoader from '@/components/loaders/AvatarLoader';
import LongFormLoader from '@/components/loaders/LongFormLoader';
import {
  AffPageTab,
  affTabHref
} from '@/components/affiliate-dashboard/affiliate-page.utils';
import { getTotalFromObject } from '@/lib/totalFromObj';
import SignOutBtn from '@/components/SignOutBtn';

const MenuSection = ({
  header,
  children,
  removeBorder = false,
  removeIndent = false
}) => {
  return (
    <div className={`pb-2 ${removeBorder ? 'border-top-0' : 'border-top'}`}>
      <ListGroupItem
        className={`${removeIndent ? 'pt-0' : 'pt-2'} pb-2 px-1 border-0`}
      >
        <span className="text-secondary text-uppercase font-weight-500 h8">
          {header}
        </span>
      </ListGroupItem>
      {children}
    </div>
  );
};

const itemClassname = 'text-dark font-weight';

type MenuItemProps = {
  href: string;
  text: React.ReactNode;
  target?: string;
  disabled?: boolean;
};
const MenuItem = ({ href, text, target, disabled = false }: MenuItemProps) => {
  const router = useRouter();

  let link = (
    <AppLink href={href} target={target} className={itemClassname}>
      {text}
    </AppLink>
  );

  if (router.pathname === '/search' && href === '/search') {
    // force full refresh by using native anchor
    link = (
      <a href={href} className={itemClassname}>
        {text}
      </a>
    );
  }

  return (
    <ListGroupItem className="border-0 py-1 px-2" disabled={disabled}>
      {link}
    </ListGroupItem>
  );
};

type Props = {
  isDrawerOpened: boolean;
  closeDrawer: () => void;
  navbarRef: MutableRefObject<HTMLDivElement>;
};

const MenuDrawer = ({ isDrawerOpened, navbarRef, closeDrawer }: Props) => {
  const {
    user,
    isLoggedOut,
    isAlreadyLoggedIn: wasPreviouslyLoggedIn
  } = useUser();
  const { profile } = useProfile();
  const { unreadCounters } = useUserTempData();
  const contentRef = useRef<HTMLDivElement>();
  const { t } = useTranslation('common');

  useOnClickOutside([contentRef, navbarRef], closeDrawer, isDrawerOpened);

  useEffect(() => {
    Router.events.on('beforeHistoryChange', closeDrawer);
    return () => {
      Router.events.off('beforeHistoryChange', closeDrawer);
    };
  }, [closeDrawer]);

  useEffect(() => {
    const currentContentRef = contentRef.current;

    return () => {
      if (isDrawerOpened && currentContentRef) {
        const list = Array.from(currentContentRef.children[0].children).find(
          (c) => c.localName === 'ul'
        );

        if (list) {
          list.scrollTop = 0;
        }
      }
    };
  }, [isDrawerOpened]);

  const renderContent = () => {
    if (isLoggedOut) {
      return (
        <>
          <ListGroup flush className="overflow-y-auto">
            <MenuSection header="Rentals" removeBorder removeIndent>
              <MenuItem href="/search" text={t('navbar.rental')} />
              <MenuItem href="/signin" text={t('menu.add_new_vehicle')} />
            </MenuSection>
            <MenuSection header="FAQ">
              <MenuItem href="/insurance" text={t('menu.insurance')} />
              <MenuItem href="/howitworks" text={t('menu.how_it_works')} />
              <MenuItem href="/contact" text={t('menu.contact')} />
              <MenuItem
                href="/about"
                text={`${t('menu.about')} ${companyName}`}
              />
              <MenuItem href="/mobile-app" text="Mobile application" />
            </MenuSection>
          </ListGroup>
          <div className="d-flex pt-3 flex-column border-top">
            <AppLink href="/signin" passHref>
              <Button
                block
                className="bg-white text-dark border-0 font-weight-500"
              >
                Login
              </Button>
            </AppLink>
            <AppLink href="/signup" passHref className="mt-2">
              <Button
                block
                color="dark"
                className="d-flex align-items-center justify-content-center"
              >
                Sign up <FontAwesomeIcon icon="caret-right" className="ml-2" />
              </Button>
            </AppLink>
          </div>
        </>
      );
    }

    if (wasPreviouslyLoggedIn || user) {
      const isSuspended = profileService.isProfileSuspended(profile);

      let userCard = (
        <ContentLoader
          speed={2}
          viewBox="0 0 100 25"
          style={{ height: 45, width: 150, maxWidth: '100%' }}
        >
          <rect x="0" y="0" rx="5" ry="5" width="100" height="25" />
        </ContentLoader>
      );

      let avatar = (
        <AvatarLoader
          key="user-avatar-loader"
          width={45}
          height={45}
          style={{
            border: '2px solid #f1f1f1',
            borderRadius: '50%'
          }}
        />
      );

      const numOfUnreadMessages = getTotalFromObject(
        unreadCounters?.chat_unread_messages
      );
      const totalUnreadSupportMessages = getTotalFromObject(
        unreadCounters?.support_inbox_unread_messages
      );

      const isAdmin = roleManager.canAccessAdmin(profile);

      if (profile) {
        avatar = (
          <Fade in tag="span">
            <AppLink
              href={`/user/${profile.id}`}
              prefetch={false}
              title={t('menu.view_profile')}
            >
              <img
                src={profile.avatar}
                alt="avatar"
                width={45}
                height={45}
                className="rounded-circle"
              />
            </AppLink>
          </Fade>
        );

        userCard = (
          <>
            <div className="text-primary-light font-weight-500 d-flex">
              <span className="text-disappear">{profile.firstName}</span>
              {profile.avgRating > 0 && (
                <div className="d-flex align-items-center ml-2 pl-2 border-left">
                  <div>
                    <SingleStar size="1x" isSelected />
                  </div>
                  <div className="h7 mb-0 ml-1 text-secondary-dark font-weight-500">
                    {profile.avgRating?.toFixed(1)}
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex align-items-center mt-1">
              <div className="d-flex align-items-center">
                <SignOutBtn
                  size="sm"
                  colorScheme="dark"
                  variant="link"
                  className="tw-font-medium"
                />
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="pb-3 d-flex justify-content-between">
            <div className="w-100 mw-0 mr-2">{userCard}</div>
            <div>{avatar}</div>
          </div>
          {isSuspended && (
            <div className="py-3 border-top">
              <YourAccSuspendedMsg title="Your account is suspended" />
            </div>
          )}
          <ListGroup flush className="overflow-y-auto border-top">
            {isAdmin && (
              <MenuSection header="Admin" removeBorder>
                <MenuItem href="/_admin" text="Admin panel" />
              </MenuSection>
            )}
            <MenuSection header="Rentals" removeBorder={!isAdmin}>
              <MenuItem href="/search" text={t('navbar.rental')} />
              {ownerLinks.map((l) => (
                <MenuItem key={l.href} href={l.href} text={l.text} />
              ))}
            </MenuSection>
            <MenuSection header="Account">
              <MenuItem
                href={user ? `/user/${user.uid}` : '#'}
                text={t('menu.view_profile')}
              />
              <MenuItem
                href="/inbox"
                text={
                  <div className="d-inline-flex align-items-center">
                    {t('menu.inbox')}
                    {numOfUnreadMessages > 0 && (
                      <Badge color="dark ml-2">{numOfUnreadMessages}</Badge>
                    )}
                  </div>
                }
              />
              {accountLinks.map((l) => (
                <MenuItem key={l.i18nKey} href={l.href} text={l.text} />
              ))}

              <MenuItem
                href="/dashboard/b2wpoints"
                text={
                  <div className="d-flex align-items-center">
                    <div className="mr-2">B2W points</div>
                    <div>
                      {profile ? (
                        <div>
                          {profile.b2wPoints.earnedCurrent}
                          <FontAwesomeIcon icon="coins" className="ml-1" />
                        </div>
                      ) : (
                        <ContentLoader
                          speed={2}
                          viewBox="0 0 50 20"
                          height={20}
                          uniqueKey="mobile-menu-points"
                          style={{ maxWidth: '100%' }}
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="50"
                            height="20"
                          />
                        </ContentLoader>
                      )}
                    </div>
                  </div>
                }
              />

              {profile?.affiliateId ? (
                <MenuItem
                  href={affTabHref(AffPageTab.dashboard)}
                  text="Affiliate dashboard"
                />
              ) : (
                <MenuItem href="/affiliate" text="Become an affiliate" />
              )}
            </MenuSection>
            <MenuSection header="Help">
              <MenuItem
                href="/support"
                text={
                  <div className="d-inline-flex align-items-center">
                    Help & Support
                    {totalUnreadSupportMessages > 0 && (
                      <Badge
                        color="dark ml-2"
                        style={{ verticalAlign: 'middle' }}
                      >
                        {totalUnreadSupportMessages}
                      </Badge>
                    )}
                  </div>
                }
              />
              <MenuItem href="/howitworks" text={t('menu.how_it_works')} />
              <MenuItem href="/insurance" text={t('menu.insurance')} />
              <MenuItem href="/contact" text={t('menu.contact')} />
              <MenuItem
                href="/about"
                text={`${t('menu.about')} ${companyName}`}
              />
              <MenuItem href="/mobile-app" text="Mobile application" />
              <MenuItem
                href="https://www.book4wheel.com"
                text="Leasing"
                target="_blank"
              />
            </MenuSection>
          </ListGroup>
        </>
      );
    }

    return (
      <LongFormLoader
        uniqueKey="drawer-menu-loader"
        preserveAspectRatio="none"
        style={{ height: 500, width: '100%' }}
      />
    );
  };

  return (
    <Drawer
      ref={contentRef}
      isDrawerOpened={isDrawerOpened}
      bgOverlay
      size="80%"
    >
      <div className="d-flex flex-column h-100 px-3 pt-3 pb-2">
        {renderContent()}
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
