
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Head from 'next/head';
import Header from '@/components/landing-city/Header';
import Description from '@/components/home/Description';
import Seo from '@/components/layout/Seo';
import { theme } from '@/styles/sc-theme';
import { Container } from 'reactstrap';
import PopularDestination from '@/components/home/PopularDestination';
import HomeSection from '@/components/home/HomeSection';
import VisitOurBlog from '@/components/home/VisitOurBlog';
import PartnersBlock from '@/components/home/PartnersBlock';
import AwardsBlock from '@/components/home/AwardsBlock';
import DestinationsBlock from '@/components/home/DestinationsBlock';
import AppLink from '@/components/AppLink';
import LandingBlog from '@/components/home/LandingBlog';
import { projectService } from '@/lib/services/project.service';
import { PopularDestinationModel } from '@b2w/shared/types';
const OrangeSeperator = () => {
    return (<>
      <style jsx>
        {`
          .seperator-orange {
            height: 2px;
            width: 100%;
            background-color: ${theme.palette.orange.main};
          }
        `}
      </style>
      <div className="seperator-orange"/>
    </>);
};
const OrangeSeperatorWithContainer = () => {
    return (<Container>
      <OrangeSeperator />
    </Container>);
};
const mobileAppLinks = [
    {
        href: '/mobile-app?platform=android',
        imgSrc: '/images/google-play-badge.png',
        alt: 'Get it on Google Play',
        width: 135,
        height: 52
    },
    {
        href: '/mobile-app?platform=ios',
        imgSrc: '/images/app-store-badge.png',
        alt: 'Get it on App Store',
        width: 110,
        height: 37
    }
];
type Props = {
    popularDestination: PopularDestinationModel | null;
};
const Home = ({ popularDestination }: Props) => {
    return (<>
      <Seo title="Motorbike and car sharing service. Find motorbike near you or rent out your motorbike"/>
      <Head>
        <meta name="google-site-verification" content="J2XajsXD3uST8ajlDtB3h1_Tc9fqRK0e9B58a7EJeww"/>
        <link rel="preload" as="image" href="/images/landing/7-people motorbike.svg"/>
        <link rel="preload" as="image" href="/images/landing/6-map pins.svg"/>
        <link rel="preload" as="image" href="/images/landing/5-people beach.svg"/>
        <link rel="preload" as="image" href="/images/landing/4-beach.svg"/>
        <link rel="preload" as="image" href="/images/landing/3-right palm tree.svg"/>
        <link rel="preload" as="image" href="/images/landing/2-left palm tree.svg"/>
        <link rel="preload" as="image" href="/images/landing/1-background.svg"/>
      </Head>
      <Header title="Marketplace for motorbike and car sharing"/>
      <div className="pin-bg">
        <style jsx>
          {`
            @media screen and (min-width: 992px) {
              .pin-bg {
                background: ${`url('/images/pintrail.svg') calc(50% - 95px) -60%/560px
                  no-repeat`};
              }
            }
          `}
        </style>
        <HomeSection title="What do we have to offer" titleExtraClassName="bg-white py-2">
          <Description />
        </HomeSection>
      </div>
      <OrangeSeperatorWithContainer />
      <HomeSection>
        <LandingBlog />
      </HomeSection>
      <OrangeSeperatorWithContainer />
      <HomeSection>
        <AwardsBlock />
      </HomeSection>
      <OrangeSeperatorWithContainer />
      <HomeSection>
        <PartnersBlock />
      </HomeSection>
      {!!popularDestination && (<>
          <OrangeSeperatorWithContainer />
          <HomeSection title={popularDestination.sectionTitle}>
            <PopularDestination data={popularDestination}/>
          </HomeSection>
        </>)}
      <OrangeSeperatorWithContainer />
      <HomeSection title="Visit our blog">
        <VisitOurBlog />
      </HomeSection>
      <OrangeSeperatorWithContainer />
      <HomeSection title="Rent a motorbike in following destinations">
        <div className="mb-5">
          <DestinationsBlock />
        </div>
      </HomeSection>
      <OrangeSeperatorWithContainer />
      <HomeSection title="Install Book2Wheel mobile application">
        <div className="d-flex justify-content-center align-items-center">
          {mobileAppLinks.map((link) => (<AppLink key={link.imgSrc} href={link.href} className="mx-2">
              <img alt={link.alt} src={link.imgSrc} width={link.width} height={link.height}/>
            </AppLink>))}
        </div>
      </HomeSection>
    </>);
};
async function getStaticProps() {
    let popularDestination: PopularDestinationModel | null;
    try {
        popularDestination = await projectService.getPopularDestination();
    }
    catch (err) {
        console.log('[POPULAR DESTINATION ERROR]', err.message);
        popularDestination = null;
    }
    return {
        props: {
            popularDestination
        }
    };
}
export default Home;

    async function __Next_Translate__getStaticProps__1949527777e__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1949527777e__ as getStaticProps }
  