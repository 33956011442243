import React, { createContext, forwardRef, memo, useContext } from 'react';
import { ComponentPropsWithRef } from '../types';
import { twCx } from '../twMerge';
import { Popup, PopupTriggerProps } from '../Popup';
import { NativeSelect, NativeSelectProps } from '../NativeSelect';
import { SelectableList } from '../SelectableList';
import {
  UseInitSelectProps,
  UseSelectOptionProps,
  useInitSelect,
  useSelectOption,
  SelectLogicCtxValue,
  SelectOptionWithIndex,
  SelectGroupedOptionWithIndex,
  useSelectTrigger,
  UseSelectTriggerProps,
  SelectLogicCtx,
  useSelectLogicCtx,
  useSelectPopupBody,
  UseSelectPopupBodyProps,
  useSelectListbox,
  UseSelectListboxProps,
  UseInitSelectPublicProps
} from './select.init';

export type SelectHtmlProps<TValue, TMultiple> = {
  /**
   * Custom renderer for option
   *
   * @example
   * renderOption={(props, option) => (
   *   <Select.Option {...props} className="extend-classes-here">
   *     <span>Pick {option.value}</span>
   *     {props.isSelected && <span>Already selected</span>}
   *   </Select.Option>
   * )}
   */
  renderOption?: (
    optionProps: SelectOptionProps<TValue>,
    option: SelectOptionWithIndex<TValue>
  ) => React.ReactElement;
  /**
   * Custom renderer for group label
   *
   * @example
   * renderGroup={(props, optionGroup) => (
   *   <Select.OptionGroup
   *     {...props}
   *     className="extend-classes-here"
   *     data-label={optionGroup.label}
   *   />
   * )}
   */
  renderGroup?: (
    optionGroupProps: SelectOptionGroupProps,
    optionGroup: SelectGroupedOptionWithIndex<TValue>
  ) => React.ReactElement;
  /**
   * Custom renderer for select trigger
   *
   * @example
   * renderTrigger={(ctx) => (
   *   <Select.Trigger data-is-open={ctx.isOpen}>
   *     {ctx.selected ? <div>Selected</div> : 'Select an option'}
   *   </Select.Trigger>
   * )}
   */
  renderTrigger?: (
    ctx: Pick<
      SelectLogicCtxValue<TValue, TMultiple>,
      'selected' | 'isOpen' | 'placeholder'
    >
  ) => React.ReactElement;
  /**
   * Custom renderer for options listbox
   *
   * @example
   * renderListbox={() => (
   *   <Select.Listbox className="overrides" />
   * )}
   */
  renderListbox?: () => React.ReactElement;
  /**
   * Custom renderer for popup in which listbox is rendered.
   * Remember to pass `renderedListbox` as children
   *
   * @example
   * renderPopupBody={(renderedListbox) => (
   *   <Select.PopupBody className="overrides">
   *     {renderedListbox}
   *   </Select.PopupBody>
   * )}
   */
  renderPopupBody?: (renderedListbox: React.JSX.Element) => React.ReactElement;
  /**
   * Custom renderer for element which shows when there are no options in the list
   */
  renderNoOptions?: () => React.ReactElement;
  /**
   * Prop name on trigger element for passing ref to position the popup.
   *
   * Use only when creating custom trigger component instead of using `Select.Trigger`, otherwise do not touch!
   *
   * @default 'ref'
   */
  popupReferenceRefPropName?: PopupTriggerProps['refPropName'];
};

export const SelectHtmlCtx = createContext<SelectHtmlProps<any, any>>(
  null as any
);
export const useSelectHtmlCtx = <TValue, TMultiple>() =>
  useContext<SelectHtmlProps<TValue, TMultiple>>(SelectHtmlCtx);

type SelectRootProps<TValue, TMultiple> = UseInitSelectProps<
  TValue,
  TMultiple
> &
  SelectHtmlProps<TValue, TMultiple>;

type SelectRootComponent = <TValue, TMultiple = false>(
  props: SelectRootProps<TValue, TMultiple>
) => React.JSX.Element;

export const SelectRoot = (<TValue, TMultiple extends boolean = false>(
  props: SelectRootProps<TValue, TMultiple>
) => {
  const {
    renderOption,
    renderGroup,
    renderTrigger,
    renderListbox,
    renderPopupBody,
    renderNoOptions,
    popupReferenceRefPropName,
    ...initProps
  } = props;

  const htmlCtx: SelectHtmlProps<TValue, TMultiple> = {
    renderGroup,
    renderListbox,
    renderOption,
    renderPopupBody,
    renderTrigger,
    renderNoOptions,
    popupReferenceRefPropName
  };

  const logicCtx = useInitSelect<TValue, TMultiple>(initProps);

  return (
    <SelectHtmlCtx.Provider value={htmlCtx}>
      <SelectLogicCtx.Provider value={logicCtx}>
        <SelectPopup />
      </SelectLogicCtx.Provider>
    </SelectHtmlCtx.Provider>
  );
}) as SelectRootComponent;

// ===============================================================

export type SelectProps<TValue, TMultiple> = UseInitSelectPublicProps<
  TValue,
  TMultiple
> &
  SelectHtmlProps<TValue, TMultiple>;

type ClassicSelectComponent = <TValue, TMultiple = false>(
  props: SelectProps<TValue, TMultiple>
) => React.JSX.Element;

const ClassicSelect = (<TValue, TMultiple extends boolean = false>(
  props: SelectProps<TValue, TMultiple>
) => {
  return <SelectRoot {...props} />;
}) as ClassicSelectComponent;

const SelectPopup = () => {
  const {
    renderListbox,
    renderPopupBody,
    renderTrigger,
    popupReferenceRefPropName
  } = useSelectHtmlCtx();
  const {
    isOpen,
    popupMatchWidth,
    popupAnimationsMs,
    popupPlacement,
    selected,
    placeholder,
    popupStrategy
  } = useSelectLogicCtx();

  const renderedTrigger =
    typeof renderTrigger === 'function' ? (
      renderTrigger({ isOpen, selected, placeholder })
    ) : (
      <SelectTrigger />
    );

  const renderedListbox =
    typeof renderListbox === 'function' ? renderListbox() : <SelectListbox />;

  const renderedPopupBody =
    typeof renderPopupBody === 'function' ? (
      renderPopupBody(renderedListbox)
    ) : (
      <SelectPopupBody>{renderedListbox}</SelectPopupBody>
    );

  return (
    <Popup
      isOpen={isOpen}
      matchWidth={popupMatchWidth}
      placement={popupPlacement}
      animationsMs={popupAnimationsMs}
      strategy={popupStrategy}
    >
      <Popup.Trigger refPropName={popupReferenceRefPropName}>
        {renderedTrigger}
      </Popup.Trigger>
      <Popup.Positioner>{renderedPopupBody}</Popup.Positioner>
    </Popup>
  );
};

export type SelectPopupBodyProps = UseSelectPopupBodyProps;

export const SelectPopupBody = forwardRef(
  (props: SelectPopupBodyProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { popupBodyProps } = useSelectPopupBody(props, ref);

    return (
      <Popup.Body
        {...popupBodyProps}
        className={twCx(
          'tw-rounded-md tw-bg-white tw-border tw-shadow-md',
          popupBodyProps.className
        )}
      >
        {popupBodyProps.children}
      </Popup.Body>
    );
  }
);

export type SelectTriggerProps = UseSelectTriggerProps;

const SelectTrigger = forwardRef(
  (props: SelectTriggerProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { triggerProps, hiddenInputProps, isSelected, isOpen } =
      useSelectTrigger(props, ref);

    return (
      <NativeSelect
        {...triggerProps}
        as="button"
        type="button"
        classNameSelect={twCx(
          'tw-text-left',
          !isSelected && 'tw-text-gray-500',
          (triggerProps as NativeSelectProps<'button'>).classNameSelect
        )}
        classNameIcon={twCx(
          (triggerProps as NativeSelectProps<'button'>).classNameIcon,
          'tw-transition-transform',
          isOpen && 'tw-rotate-180'
        )}
        UNSAFE_skipFormControlContext={props.UNSAFE_skipFormControlContext}
      >
        {triggerProps.children}
        <input
          {...hiddenInputProps}
          className="tw-absolute tw-bottom-0 tw-left-0 tw-opacity-0 tw-pointer-events-none tw-w-full"
        />
      </NativeSelect>
    );
  }
);

export type SelectListboxProps = Omit<UseSelectListboxProps, 'children'>;

type SelectListboxComponent = (
  props: SelectListboxProps & {
    ref?: React.ForwardedRef<HTMLUListElement>;
  }
) => React.JSX.Element;

const SelectListbox = forwardRef(
  <TValue, TMultiple extends boolean = false>(
    props: SelectListboxProps,
    ref: React.ForwardedRef<HTMLUListElement>
  ) => {
    const { renderOption, renderGroup, renderNoOptions } = useSelectHtmlCtx<
      TValue,
      TMultiple
    >();

    const {
      listboxProps,
      isOptionActive,
      isOptionSelected,
      optionsManager,
      handleOptionHover,
      handleOptionClick,
      flattenedFilteredListItems,
      isNoOptions
    } = useSelectListbox<TValue, TMultiple>(props, ref);

    const renderGroupOrOption = (
      option:
        | SelectOptionWithIndex<TValue>
        | SelectGroupedOptionWithIndex<TValue>
    ) => {
      if ('groupIndex' in option) {
        if (option.groupOptionsWithIndex.length === 0) return null;

        const requiredGroupProps: SelectOptionGroupProps = {
          children: option.label
        };

        return (
          <React.Fragment key={'option-group' + option.groupIndex}>
            {renderGroup?.(requiredGroupProps, option) ?? (
              <SelectOptionGroup {...requiredGroupProps} />
            )}
          </React.Fragment>
        );
      }

      const requiredProps: SelectOptionProps<TValue> = {
        index: option.index,
        value: option.value,
        label: option.label,
        disabled: option.disabled,
        isActive: isOptionActive(option),
        isSelected: isOptionSelected(option),
        optionsManager: optionsManager,
        handleOptionHover: handleOptionHover,
        handleOptionClick: handleOptionClick
      };

      return (
        <React.Fragment key={'option' + option.index}>
          {renderOption?.(requiredProps, option) ?? (
            <SelectOption {...requiredProps} />
          )}
        </React.Fragment>
      );
    };

    const renderOptions = () => {
      return flattenedFilteredListItems.map((option) =>
        renderGroupOrOption(option)
      );
    };

    if (isNoOptions) return renderNoOptions?.() ?? <SelectNoOptions />;

    return (
      <SelectableList
        {...listboxProps}
        className={twCx('tw-max-h-[300px]', listboxProps.className)}
      >
        {renderOptions()}
      </SelectableList>
    );
  }
) as SelectListboxComponent;

export type SelectOptionProps<TValue> = UseSelectOptionProps<TValue> &
  Pick<ComponentPropsWithRef<'li'>, 'ref'>;

type SelectOptionComponent = <TValue>(
  props: SelectOptionProps<TValue> & {
    ref?: React.ForwardedRef<HTMLLIElement>;
  }
) => React.JSX.Element;

const SelectOption = memo(
  forwardRef(
    <TValue,>(
      props: SelectOptionProps<TValue>,
      ref: React.ForwardedRef<HTMLLIElement>
    ) => {
      const { optionProps, isActive, isSelected, isDisabled } = useSelectOption(
        props,
        ref
      );

      return (
        <SelectableList.Item
          {...optionProps}
          isDisabled={isDisabled}
          isSelected={isSelected}
          isHovered={isActive}
          className={twCx(
            'peer-[.option-group]:tw-pl-6',
            'tw-text-left',
            isSelected && 'tw-font-medium',
            optionProps.className
          )}
        />
      );
    }
  )
) as SelectOptionComponent;

export type SelectOptionGroupProps = ComponentPropsWithRef<'li'>;

const SelectOptionGroup = memo(
  forwardRef<HTMLLIElement, SelectOptionGroupProps>((props, ref) => {
    return (
      <li
        {...props}
        ref={ref}
        className={twCx(
          'tw-peer option-group [&:not(:first-of-type)]:tw-pt-2 tw-text-black tw-font-medium tw-leading-8',
          props.className
        )}
      >
        {props.children}
      </li>
    );
  })
);

export type SelectNoOptionsProps = ComponentPropsWithRef<'div'>;

const SelectNoOptions = forwardRef<HTMLDivElement, SelectNoOptionsProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={twCx('tw-py-4 tw-px-4 tw-text-center', props.className)}
      >
        {props.children ?? 'No options found'}
      </div>
    );
  }
);

/** Classic Select component. */
export const Select = Object.assign(ClassicSelect, {
  Trigger: SelectTrigger,
  PopupBody: SelectPopupBody,
  Listbox: SelectListbox,
  Option: SelectOption,
  OptionGroup: SelectOptionGroup,
  NoOptions: SelectNoOptions
});
