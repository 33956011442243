import { forwardRef } from 'react';
import { twCx } from '../twMerge';
import { ComponentPropsWithRef } from '../types';
import {
  UseInputFormControlOwnProps,
  useInputFormControl
} from '../FormControl';

export type TextareaResizeBehavior =
  | 'vertical'
  | 'horizontal'
  | 'both'
  | 'none';
export type TextareaSize = 'md' | 'lg';

const sizeToClassName: Record<TextareaSize, string> = {
  md: 'tw-py-2 tw-px-3 tw-text-base',
  lg: 'tw-py-3 tw-px-4 tw-text-lg'
};

const resizeBehaviorToClassName: Record<TextareaResizeBehavior, string> = {
  none: 'tw-resize-none',
  both: 'tw-resize',
  vertical: 'tw-resize-y',
  horizontal: 'tw-resize-x'
};

export type TextareaOwnProps = UseInputFormControlOwnProps & {
  /**
   * @default 'md'
   */
  size?: TextareaSize;
  /**
   * How textarea can be resized
   *
   * @default 'both'
   */
  resizeBehavior?: TextareaResizeBehavior;
  /**
   * Whether to display input as inline element.
   *
   * @default false
   */
  isInline?: boolean;
};

export type TextareaProps = ComponentPropsWithRef<'textarea', TextareaOwnProps>;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      size = 'md',
      resizeBehavior = 'both',
      isInline = false,
      ...htmlProps
    } = useInputFormControl<typeof Textarea>(props);

    return (
      <textarea
        {...htmlProps}
        ref={ref}
        className={twCx(
          'tw-bg-white tw-rounded-xl tw-border tw-border-gray-200 hover:tw-border-gray-300 focus:tw-border-main-500 tw-outline-none tw-ring-main-500 focus:tw-ring-1',
          'tw-min-h-12 tw-min-w-32',
          'aria-[invalid=true]:tw-border-error-300 aria-[invalid=true]:tw-ring-error-300',
          'aria-[invalid=true]:hover:tw-border-error-400 aria-[invalid=true]:hover:tw-ring-error-400',
          'aria-[invalid=true]:focus:tw-border-error-500 aria-[invalid=true]:focus:tw-ring-error-500',
          'disabled:tw-opacity-40 disabled:tw-cursor-not-allowed',
          !isInline && 'tw-block',
          sizeToClassName[size],
          resizeBehaviorToClassName[resizeBehavior],
          htmlProps.className
        )}
      />
    );
  }
);
