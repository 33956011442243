/**
 * Format (mask) a string against the supplied pattern (mask).
 * 
 * @param {any} value - The value to be masked.
 * @param {string} mask - The mask to use.
 * @param maskPatterns - A dictionary of mask characters and their associated
patterns.
 * @returns The masked value.

  @example
  maskString('+3800767007070', '+YXX? YX XXX XX XX', { X: /0-9/, Y: /[1-9]/ })
  // returns +380 76 700 70 70
 */
export const maskString = (
  value: any,
  mask: string,
  maskPatterns: Record<string, RegExp | ((char: string) => boolean)>
) => {
  value = value || '';
  mask = mask || '';
  maskPatterns = maskPatterns || {};

  let maskedValue = '';
  // array representation of string under test
  const valueParts = value.split('');
  // array representation of the mask
  const maskParts = mask.split('');

  // as long as there are still characters left in
  // the original string, one must try to mask them
  while (valueParts.length > 0) {
    // take the first character and remove
    // it from the original string
    let unmaskedChar = valueParts.shift();

    // as long as the character has not been masked
    // one must try to find a mask
    while (unmaskedChar !== null) {
      // take the first mask character from
      // the mask string
      const maskChar = maskParts.shift();
      const maskCharIsOptional = maskParts[0] === '?';

      // remove '?' from the mask parts
      if (maskCharIsOptional) {
        maskParts.shift();
      }

      // make sure the masking character exists
      // otherwise this means that the original string
      // exceeds the masking pattern length
      if (maskChar !== undefined) {
        // try to find a pattern for the particular
        // mask character
        const maskPattern = maskPatterns[maskChar.toUpperCase()];

        // if there is no pattern configured for
        // this particular mask character, assume
        // the mask character is a placeholder / formatting
        // value that must be added to the string
        if (maskPattern !== undefined) {
          let check = false;

          // mask pattern can be either a function
          if (typeof maskPattern === 'function') {
            check = maskPattern(unmaskedChar);
          }
          // or a regex string
          else if (maskPattern instanceof RegExp) {
            check = maskPattern.test(unmaskedChar);
          }

          // if character has passed the mask check,
          // it can bee added to the final masked value
          if (check) {
            maskedValue += unmaskedChar;
            unmaskedChar = null;
          }
          // the character did not pass the mask check,
          // however, the mask was optional, we can skip it
          else if (!check && maskCharIsOptional) {
            //
          }
          // otherwise one must put the pattern back into
          // the array so the next character can try to
          // pass the mask check
          else {
            maskParts.unshift(maskChar);
            unmaskedChar = null;
          }
        }
        // mask character is a placeholder / formatting value
        // and must be added to the masked string
        else {
          maskedValue += maskChar;

          if (unmaskedChar === maskChar) {
            unmaskedChar = null;
          }
        }
      }
      // no masking character could be found,
      // the original string is probably longer
      // then the mask pattern and therefore
      // the leftovers can be cut off
      else {
        // reset current character to continue the loop
        unmaskedChar = null;
      }
    }
  }

  return maskedValue;
};
