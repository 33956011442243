import {
  NavLink,
  Nav,
  Button as ReactstrapButton,
  Badge,
  NavItem
} from 'reactstrap';
import { Fade } from '@b2w/react-ui/transitions2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProfile } from '@/context/profile.context';
import useTranslation from 'next-translate/useTranslation';
import AppLink from '@/components/AppLink';
import { useUserTempData } from '@/context/usertempdata.context';
import { useUser } from '@/context/user.context';
import LinkToPrivate from '@/components/LinkToPrivate';
import { useRouter } from 'next/router';
import ChangeCurrencyBtn from '@/components/currency-changer/ChangeCurrencyBtn';
import { getTotalFromObject } from '@/lib/totalFromObj';
import BellDropdown from '@/components/bell/BellDropdown';
import { IconChevronDown } from '@b2w/react-ui/core2';
import DesktopProfilePopupMenu from './DesktopProfilePopupMenu';

const DesktopLink = ({ href, text, target = '_self' }) => {
  const router = useRouter();

  if (router.pathname === '/search' && href === '/search') {
    // force full refresh by using native anchor
    return (
      <NavLink href="/search" className="text-primary-light font-weight-bold">
        {text}
      </NavLink>
    );
  }

  return (
    <AppLink href={href} passHref legacyBehavior>
      <NavLink target={target} className="text-primary-light font-weight-bold">
        {text}
      </NavLink>
    </AppLink>
  );
};

const DesktopNavs = () => {
  const { t } = useTranslation('common');
  const { user, isLoggedOut, isAlreadyLoggedIn } = useUser();
  const { profile } = useProfile();
  const { unreadCounters } = useUserTempData();

  const renderMarkup = () => {
    if (isLoggedOut) {
      return (
        <Fade in className="tw-flex tw-items-center">
          <ChangeCurrencyBtn />
          <LinkToPrivate href="/dashboard/vehicles/add" passHref legacyBehavior>
            <NavLink className="text-primary-light font-weight-bold">
              {t('navbar.rentout')}
            </NavLink>
          </LinkToPrivate>
          <DesktopLink href="/signin" text={t('navbar.login')} />
          <DesktopLink href="/signup" text={t('navbar.register')} />
        </Fade>
      );
    }

    const totalUnreadSupportMessages = getTotalFromObject(
      unreadCounters?.support_inbox_unread_messages
    );
    const totalUnreadChatMessages = getTotalFromObject(
      unreadCounters?.chat_unread_messages
    );

    if (isAlreadyLoggedIn || user) {
      let avatar = (
        <span
          style={{
            display: 'inline-block',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            border: '1px solid #f1f1f1'
          }}
        >
          &nbsp;
        </span>
      );
      if (profile) {
        avatar = (
          <Fade in>
            <img
              src={profile.avatar}
              alt="user"
              width={30}
              height={30}
              className="rounded-circle"
            />
          </Fade>
        );
      }

      return (
        <Fade in className="tw-flex tw-items-center">
          <div className="d-flex align-items-center">
            <ChangeCurrencyBtn />
            <DesktopLink
              href="/dashboard/vehicles/add"
              text={t('navbar.rentout')}
            />
          </div>

          <div className="d-none d-lg-flex align-items-center justify-content-center">
            <BellDropdown />
          </div>

          <AppLink
            href="/inbox"
            passHref
            className="d-flex align-items-center link-unstyled"
          >
            <ReactstrapButton
              color="link"
              className="d-flex align-items-center py-2 px-2 text-primary-light"
              title="Inbox messages"
            >
              <FontAwesomeIcon
                icon={['fas', 'comments']}
                fixedWidth
                size="lg"
              />
              {totalUnreadChatMessages > 0 && (
                <Badge color="primary-light">{totalUnreadChatMessages}</Badge>
              )}
            </ReactstrapButton>
          </AppLink>

          <DesktopProfilePopupMenu
            colorScheme="neutral"
            variant="outline"
            aria-label="Profile menu"
            rightIcon={() => (
              <>
                {totalUnreadSupportMessages > 0 && (
                  <Badge color="dark" className="tw-mr-1">
                    {totalUnreadSupportMessages}
                  </Badge>
                )}
                <IconChevronDown.Solid size="xs" />
              </>
            )}
            className="tw-px-2"
          >
            {avatar}
          </DesktopProfilePopupMenu>
        </Fade>
      );
    }

    return null;
  };

  return (
    <div className="d-none d-lg-flex justify-content-between align-items-center flex-fill ml-3">
      <Nav>
        <NavItem>
          <DesktopLink href="/search" text={t('navbar.rental')} />
        </NavItem>
        <NavItem>
          <DesktopLink href="/contact" text={t('navbar.contact')} />
        </NavItem>
        <NavItem>
          <DesktopLink
            href="https://www.book4wheel.com"
            text="Leasing"
            target="_blank"
          />
        </NavItem>
      </Nav>
      {renderMarkup()}
    </div>
  );
};

export default DesktopNavs;
