import { memo, useEffect } from 'react';
import { Alert, AlertOwnProps } from '../Alert';
import { IconButton } from '../Button';
import { IconTimes } from '../Icon';

export type ToastPassableProps = {
  /**
   * The delay before the toast hides (in milliseconds).
   * If set to `null` or `0`, toast will never auto-dismiss.
   *
   * @default 5000 ( = 5000ms )
   */
  hideAfterMs?: number | null;
  /**
   * Whether to show close icon. Always renders at the end of action list.
   *
   * @default false
   */
  isClosable?: boolean;
};

export type UnderlyingToastComponentProps = AlertOwnProps;

export type ToastItemProps = UnderlyingToastComponentProps &
  ToastPassableProps & {
    /**
     * Toast id
     */
    id: string;
    /**
     * Callback which removes toast
     */
    removeToast: () => void;
  };

const ToastItem = (props: ToastItemProps) => {
  const {
    hideAfterMs = 5000,
    removeToast,
    variant = 'solid',
    status = 'info',
    isClosable = false,
    actions: actionsProp,
    ...underlyingProps
  } = props;

  useEffect(() => {
    if (!hideAfterMs || hideAfterMs < 0) return;

    const timer = setTimeout(() => {
      removeToast();
    }, hideAfterMs);

    return () => clearTimeout(timer);
  }, [removeToast, hideAfterMs]);

  let actions = actionsProp;

  if (isClosable) {
    const closeAction = (
      <IconButton
        aria-label="Remove"
        colorScheme="inherit"
        size="xs"
        onClick={removeToast}
        icon={(s) => <IconTimes.Solid size={s} />}
      />
    );

    actions = actions ? actions.concat(closeAction) : [closeAction];
  }

  return (
    <Alert
      {...underlyingProps}
      variant={variant}
      status={status}
      actions={actions}
    />
  );
};

const ToastMemo = memo(
  ToastItem,
  (prevProps: ToastItemProps, nextProps: ToastItemProps) => {
    // do not rerender toast unless one of the props updated
    return (
      prevProps.hideAfterMs === nextProps.hideAfterMs &&
      // alert props below
      prevProps.id === nextProps.id &&
      prevProps.title === nextProps.title &&
      prevProps.children === nextProps.children &&
      prevProps.size === nextProps.size &&
      prevProps.status === nextProps.status &&
      prevProps.truncate === nextProps.truncate &&
      prevProps.variant === nextProps.variant &&
      prevProps.icon === nextProps.icon &&
      prevProps.actions === nextProps.actions
    );
  }
);

export { ToastMemo as ToastItem };
