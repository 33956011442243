import { MenuItem } from '@/lib/menu-definition.types';
import {
  IconMagnifyingGlass,
  IconLogout,
  IconUserAvatar,
  IconMopedAdd,
  IconClipboardList,
  IconMessageBubbles,
  IconMoped
} from '@b2w/react-ui/core2';
import { PrivateProfileDTO } from '@b2w/shared/types';

export type MobileNavMenuItemCtxData = {
  profile: PrivateProfileDTO;
  unreadInboxMessages: number;
};

export const mobileNavMenuItems: MenuItem<MobileNavMenuItemCtxData>[] = [
  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      return !profile
        ? {
            type: 'link',
            iconContent: () => <IconMagnifyingGlass.Outline />,
            href: '/search',
            text: 'Search'
          }
        : null;
    }
  },
  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      return !profile
        ? {
            type: 'link',
            iconContent: () => <IconLogout.Outline />,
            href: '/signin',
            text: 'Login'
          }
        : null;
    }
  },
  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      return !profile
        ? {
            type: 'link',
            iconContent: () => <IconUserAvatar.Outline />,
            href: '/signup',
            text: 'Signup'
          }
        : null;
    }
  },

  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      return profile
        ? {
            type: 'link',
            iconContent: () => <IconMagnifyingGlass.Outline size="lg" />,
            href: '/search',
            text: 'Search'
          }
        : null;
    }
  },
  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      if (!profile) return null;

      return profile.totalVehicles > 0
        ? {
            type: 'link',
            iconContent: () => <IconMoped.Outline size="lg" />,
            href: '/dashboard/vehicles',
            text: 'Vehicles'
          }
        : {
            type: 'link',
            iconContent: () => <IconMopedAdd.Outline size="lg" />,
            href: '/dashboard/vehicles/add',
            text: 'List vehicle'
          };
    }
  },
  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      return profile
        ? {
            type: 'link',
            iconContent: () => <IconClipboardList.Outline size="lg" />,
            href: '/dashboard/rentals',
            text: 'Rentals'
          }
        : null;
    }
  },
  {
    type: 'conditional',
    visibleByData: ({ profile, unreadInboxMessages }) => {
      return profile
        ? {
            type: 'link',
            iconContent: () => <IconMessageBubbles.Outline size="lg" />,
            href: '/inbox',
            text: (
              <div>
                Inbox
                {unreadInboxMessages > 0 && (
                  <span className="tw-ml-1 tw-font-bold">
                    {unreadInboxMessages > 99
                      ? '(99+)'
                      : `(${unreadInboxMessages})`}
                  </span>
                )}
              </div>
            )
          }
        : null;
    }
  },
  {
    type: 'conditional',
    visibleByData: ({ profile }) => {
      return profile
        ? {
            type: 'link',
            iconContent: () => <IconUserAvatar.Outline size="lg" />,
            href: '/dashboard',
            text: 'Profile'
          }
        : null;
    }
  }
];
