import { useMediaQuery } from '@/custom-hooks/useMediaQuery';
import { throttle } from '@b2w/shared/utility';
import AppLink from '@/components/AppLink';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { twCx } from '@b2w/react-ui/core2';
import { bottomSafeSpace } from './safeSpace';
import { useUserTempData } from '@/context/usertempdata.context';
import { useProfile } from '@/context/profile.context';
import {
  MobileNavMenuItemCtxData,
  mobileNavMenuItems
} from './MobileNavMenuItems';
import { MenuItem } from '@/lib/menu-definition.types';
import { getTotalFromObject } from '@/lib/totalFromObj';

const useMobileNavMenu = () => {
  const isMobileScreen = useMediaQuery('(max-width: 768px)');
  const prevOffsetY = useRef<number | undefined>();

  const { isProfileLoading } = useProfile();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!isProfileLoading) {
      setShowMenu(true);
    }
  }, [isProfileLoading]);

  useEffect(() => {
    if (!isMobileScreen) return;

    bottomSafeSpace.add('48px');

    let timeout: any;

    const onScroll = throttle(() => {
      clearTimeout(timeout);

      const yOffset = window.pageYOffset;
      const prevYOffset = prevOffsetY.current;

      if (prevYOffset !== undefined && yOffset !== undefined) {
        if (prevYOffset < yOffset) {
          // scroll down
          setShowMenu(false);

          timeout = setTimeout(() => {
            setShowMenu(true);
          }, 600);
        } else {
          setShowMenu(true);
        }
      }

      prevOffsetY.current = yOffset;
    }, 50);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      bottomSafeSpace.remove();
    };
  }, [isMobileScreen, isProfileLoading]);

  return { showMenu };
};

type MenuListProps = {
  children?: React.ReactNode;
};

const MenuList = ({ children }: MenuListProps) => {
  return <ul className="tw-flex">{children}</ul>;
};

type MenuListItemProps = {
  children?: React.ReactNode;
  /** @default false */
  isHoverable?: boolean;
};

const MenuListItem = ({ children, isHoverable = false }: MenuListItemProps) => {
  return (
    <li className={twCx('tw-flex-1', isHoverable && 'hover:tw-bg-gray-100')}>
      {children}
    </li>
  );
};

const MobileNavMenu = () => {
  const router = useRouter();
  const { showMenu } = useMobileNavMenu();
  const { profile } = useProfile();
  const { unreadCounters } = useUserTempData();

  const unreadInboxMessages = getTotalFromObject(
    unreadCounters?.chat_unread_messages
  );

  const renderMenuListItem = (item: MenuItem<MobileNavMenuItemCtxData>) => {
    switch (item.type) {
      case 'link': {
        const isActive = router.pathname === item.href;

        return (
          <MenuListItem>
            <AppLink
              key={item.href}
              href={item.href}
              className={twCx('link-unstyled tw-flex tw-p-1 tw-text-nowrap')}
            >
              <div
                className={twCx(
                  'tw-flex tw-flex-col tw-items-center tw-gap-1 tw-text-xs tw-flex-1 tw-p-1',
                  'tw-text-gray-600',
                  isActive &&
                    'tw-rounded-lg tw-bg-gray-100 tw-text-dark-900 tw-font-medium'
                )}
              >
                {item.iconContent({ profile, unreadInboxMessages })}
                {item.text}
              </div>
            </AppLink>
          </MenuListItem>
        );
      }
      case 'conditional': {
        const conditionalLink = item.visibleByData({
          profile,
          unreadInboxMessages
        });

        return conditionalLink ? renderMenuListItem(conditionalLink) : null;
      }

      default:
        return null;
    }
  };

  return (
    <div
      className={twCx(
        'tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-docked',
        'tw-transition-transform tw-ease-in tw-duration-200',
        'tw-bg-white tw-border-t',
        showMenu ? 'tw-translate-y-0' : 'tw-translate-y-full',
        !showMenu && 'tw-hidden',
        'tw-block lg:tw-hidden',
        'tw-shadow-top-for-sticky'
      )}
    >
      <MenuList>
        {mobileNavMenuItems.map((item, idx) => {
          return (
            <Fragment key={item.type + idx}>
              {renderMenuListItem(item)}
            </Fragment>
          );
        })}
      </MenuList>
    </div>
  );
};

export default MobileNavMenu;
