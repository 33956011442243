import React, { useImperativeHandle, useMemo, useState } from 'react';
import type { DateManager, Dayjs } from '@b2w/date-manager';
import { twCx } from '../twMerge';
import { IconButton } from '../Button';
import { IconChevronLeft, IconChevronRight } from '../Icon';
import { numberRange } from '@b2w/shared/utility';

export type CalendarController = {
  scrollToSelectedDateMonth: (date?: Dayjs) => any;
};

export type DateRange = {
  start?: Dayjs;
  end?: Dayjs;
  mode?: 'start' | 'end';
};

export type CalendarProps = {
  selectedDate?: Dayjs;
  onSelectDate?: (selected: Dayjs) => any;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabledDates?: Dayjs[];
  disabledWeekdays?: number[];
  markedDates?: Dayjs[];
  range?: DateRange;
  dateManager: DateManager;
  startWeekFromDayIdx?: number;
  timeZone?: string;
  controller?: React.Ref<CalendarController>;
};

export const Calendar = (props: CalendarProps) => {
  const {
    startWeekFromDayIdx = 1,
    dateManager,
    minDate,
    disabledDates,
    disabledWeekdays,
    markedDates,
    range,
    maxDate,
    selectedDate,
    onSelectDate,
    timeZone,
    controller
  } = props;

  const timeZoneWithFallback = timeZone || dateManager.userTimeZone();

  const [visibleMonth, setVisibleMonth] = useState(
    () =>
      selectedDate ||
      range?.start ||
      range?.end ||
      dateManager.d().tz(timeZoneWithFallback)
  );
  const [hoveredDay, setHoveredDayInternal] = useState<Dayjs | null>(null);

  const daysOfWeek = useMemo(
    () =>
      numberRange(startWeekFromDayIdx, 7).concat(
        numberRange(7, 7 + startWeekFromDayIdx)
      ),
    [startWeekFromDayIdx]
  );

  const formattedDaysOfWeek = daysOfWeek.map((idx) =>
    visibleMonth.set('day', idx).format('ddd')
  );

  const formattedVisibleMonth = visibleMonth.format('MMMM YYYY');

  const daysInMonth = dateManager
    .range(visibleMonth.startOf('month'), visibleMonth.endOf('month'), 'day', 1)
    .map((date) => date.tz(timeZoneWithFallback));

  const firstDayColumnOffset =
    1 + ((visibleMonth.startOf('month').day() + (7 - startWeekFromDayIdx)) % 7);

  const isPreviousDisabled =
    minDate && visibleMonth.subtract(1, 'month').isBefore(minDate, 'month');
  const isNextDisabled =
    maxDate && visibleMonth.add(1, 'month').isAfter(minDate, 'month');

  const rangeHasStart =
    !!range && (range.start || (hoveredDay && range.mode === 'start'));

  const rangeHasEnd =
    !!range && (range.end || (hoveredDay && range.mode === 'end'));

  const setHoveredDay = (day: Dayjs | null) => {
    if (day && isDayEnabled(day)) {
      setHoveredDayInternal(day);
    } else {
      setHoveredDayInternal(null);
    }
  };

  const isDayEnabled = (date: Dayjs) => {
    return (
      !disabledWeekdays?.includes(date.day()) &&
      !disabledDates?.some((disabledDate) =>
        date.isSame(disabledDate, 'date')
      ) &&
      (!minDate || date.isSameOrAfter(minDate, 'date')) &&
      (!maxDate || date.isSameOrBefore(maxDate, 'date'))
    );
  };

  const isDayMarked = (date: Dayjs) => {
    return markedDates?.some((markedDate) => date.isSame(markedDate, 'date'));
  };

  const isDaySelected = (date: Dayjs) => {
    return !(!selectedDate || range) && date.isSame(selectedDate, 'date');
  };

  const selectDate = (date: Dayjs) => {
    if (isDayEnabled(date)) {
      onSelectDate?.(date);
    }
  };

  const goToDirection = (
    direction: 1 | -1,
    unit: 'month' | 'year' = 'month'
  ) => {
    setVisibleMonth((c) => c.add(direction, unit));
  };

  useImperativeHandle(controller, () => ({
    scrollToSelectedDateMonth(date) {
      setVisibleMonth(
        selectedDate || date || dateManager.d().tz(timeZoneWithFallback)
      );
    }
  }));

  const isSingleInRange = (date: Dayjs) => {
    if (!range) return false;

    return range.start && !rangeHasEnd
      ? date.isSame(range.start, 'date')
      : !(rangeHasStart || !range.end) && date.isSame(range.end, 'date');
  };

  const isDayInOneDayRange = (date: Dayjs) => {
    if (!range) return false;

    if (range.start && range.end) {
      return date.isSame(range.start, 'date') && date.isSame(range.end, 'date');
    }

    if (range.start && rangeHasEnd) {
      return (
        date.isSame(range.start, 'date') && date.isSame(hoveredDay, 'date')
      );
    }

    return (
      !(!rangeHasStart || !range.end) &&
      date.isSame(hoveredDay, 'date') &&
      date.isSame(range.end, 'date')
    );
  };

  const isStartOfRange = (date: Dayjs) => {
    if (!range) return false;

    // if (range.start && !range.end) {
    //   return date.isSame(hoveredDay, 'date');
    // }

    return range.start
      ? date.isSame(range.start, 'date')
      : !(!rangeHasStart || !range.end) && date.isSame(hoveredDay, 'date');
  };

  const isEndOfRange = (date: Dayjs) => {
    if (!range) return false;

    return range.end
      ? date.isSame(range.end, 'date')
      : !(!range.start || !rangeHasEnd) && date.isSame(hoveredDay, 'date');
  };

  const isInRange = (date: Dayjs) => {
    return (
      !!(range && rangeHasStart && rangeHasEnd) &&
      date.isAfter(range.start || hoveredDay) &&
      date.isBefore(range.end || hoveredDay)
    );
  };

  const dayClasses = (date: Dayjs) => {
    const cls: string[] = [];

    const isToday = date.isSame(
      dateManager.d().tz(timeZoneWithFallback),
      'date'
    );

    if (isDayInOneDayRange(date) || isDaySelected(date)) {
      cls.push('d-selected');
    } else {
      if (isSingleInRange(date)) {
        'end' === range?.mode
          ? cls.push('d-start-range-single')
          : cls.push('d-selected');
      } else {
        if (isStartOfRange(date)) {
          range?.start && range?.end
            ? cls.push(
                'd-start-range',
                'tw-bg-gradient-to-l tw-from-0% tw-via-50% tw-to-50% tw-from-main-100 tw-via-main-100 tw-to-white'
              )
            : cls.push(
                'd-start-range-incomplete',
                'tw-bg-gradient-to-l tw-from-0% tw-via-50% tw-to-50% tw-from-main-100 tw-via-main-100 tw-to-white'
              );
        } else if (isEndOfRange(date)) {
          range?.end
            ? cls.push(
                'd-end-range',
                'tw-bg-gradient-to-r tw-from-0% tw-via-50% tw-to-50% tw-from-main-100 tw-via-main-100 tw-to-white'
              )
            : cls.push(
                'd-end-range-incomplete',
                'tw-bg-gradient-to-r tw-from-0% tw-via-50% tw-to-50% tw-from-main-100 tw-via-main-100 tw-to-white'
              );
        } else {
          isInRange(date) &&
            cls.push('d-within-range', 'tw-bg-main-100 -tw-mx-px');
        }
      }
    }

    if (isDayEnabled(date)) {
      if (isDayMarked(date)) cls.push('d-marked');

      if (onSelectDate) {
        cls.push('d-enabled');
        cls.push('tw-cursor-pointer');
      }

      if (
        cls.some((e) =>
          [
            'd-selected',
            'd-start-range',
            'd-end-range',
            'd-start-range-incomplete'
          ].includes(e)
        )
      ) {
        cls.push('tw-text-white');
      }

      if (isToday) cls.push('tw-font-bold');
    } else {
      cls.push('tw-text-gray-100 tw-cursor-default');
    }

    return twCx(cls);
  };

  return (
    <div role="grid">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <IconButton
          disabled={isPreviousDisabled}
          aria-label="Previous month"
          icon={(s) => <IconChevronLeft.Solid size={s} />}
          size="sm"
          colorScheme="inherit"
          className="disabled:tw-opacity-25 tw-rounded-full"
          onClick={() => goToDirection(-1)}
        />
        <div className="tw-flex-grow tw-font-semibold tw-capitalize tw-text-center tw-text-lg">
          {formattedVisibleMonth}
        </div>
        <IconButton
          disabled={isNextDisabled}
          aria-label="Next month"
          icon={(s) => <IconChevronRight.Solid size={s} />}
          size="sm"
          colorScheme="inherit"
          className="disabled:tw-opacity-25 tw-rounded-full"
          onClick={() => goToDirection(1)}
        />
      </div>

      <div className="tw-grid tw-grid-cols-7 tw-mb-1">
        {formattedDaysOfWeek.map((label) => (
          <div
            key={label}
            title={label}
            className="tw-cursor-help tw-text-gray-400 tw-font-semibold tw-text-xs tw-text-center tw-mx-1 tw-mt-2"
          >
            {label}
          </div>
        ))}
      </div>

      <div
        className="tw-grid tw-grid-cols-7 tw-gap-y-2"
        style={
          {
            '--start-col': firstDayColumnOffset
          } as React.CSSProperties
        }
      >
        {daysInMonth.map((date, idx) => (
          <div
            key={`date-${idx}`}
            className={twCx(
              idx === 0 && 'tw-col-start-[--start-col]',
              'tw-relative tw-text-base tw-font-medium tw-h-[40px] sm:tw-min-w-[40px] tw-group',
              dayClasses(date)
            )}
            onClick={() => selectDate(date)}
            onMouseOver={() => setHoveredDay(date)}
            onMouseLeave={() => setHoveredDay(null)}
          >
            <span
              className={twCx(
                'tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-z-first',
                'tw-flex tw-items-center tw-justify-center',
                'tw-h-[40px] tw-w-[40px]',
                'tw-rounded-full',
                'group-[.d-selected]:tw-bg-main-500 ',
                'group-[.d-start-range-incomplete]:tw-bg-main-500 ',
                'group-[.d-end-range-incomplete]:tw-bg-white',
                'group-[.d-start-range]:tw-bg-main-500 ',
                'group-[.d-end-range]:tw-bg-main-500 ',
                'group-[.d-start-range-single]:tw-outline group-hover:group-[.d-enabled]:tw-outline tw-outline-2 tw-outline-offset-1 tw-outline-main-500',
                `group-[.d-marked]:before:tw-content-[''] group-[.d-marked]:before:tw-absolute group-[.d-marked]:before:tw-bottom-1 group-[.d-marked]:before:tw-left-1/2 group-[.d-marked]:before:-tw-translate-x-1/2 group-[.d-marked]:before:tw-w-[7px] group-[.d-marked]:before:tw-h-[7px] group-[.d-marked]:before:tw-rounded-full group-[.d-marked]:before:tw-bg-warning-500`
              )}
            >
              {date.format('D')}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
