import { createIcon } from '../createIcon';

export const IconPen = {
  Solid: createIcon({
    viewBox: '0 0 512 512',
    fill: 'currentColor',
    pathOrChildren:
      'M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z'
  }),
  Outline: createIcon({
    viewBox: '0 0 70 70',
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    pathOrChildren:
      'M62.0413 2.56606L67.4335 7.9719C70.8555 11.3933 70.8555 16.936 67.4335 20.3574L60.8092 26.9813L57.716 30.0743L25.7849 61.9892C24.3615 63.4128 22.5958 64.4528 20.666 65.0275L4.20088 69.8722C3.05119 70.201 1.8057 69.8857 0.957122 69.0375C0.108545 68.1886 -0.20625 66.9433 0.135919 65.7804L4.98102 49.3164C5.55586 47.3867 6.59606 45.6213 8.01947 44.1979L39.9233 12.2829L43.0164 9.18996L49.6408 2.56606H49.6545C53.0762 -0.855354 58.6193 -0.855354 62.0413 2.56606ZM53.5949 25.7102L44.2877 16.4037L44.1666 16.5248L44.1657 16.5257L12.2628 48.4398L12.262 48.4406C11.5604 49.1422 11.0305 50.0298 10.7336 51.0215L10.7313 51.0294L7.29463 62.7075L18.9536 59.277L18.9618 59.2746C19.9536 58.9777 20.8409 58.4479 21.542 57.7468L21.5433 57.7455L53.4735 25.8315L53.4745 25.8306L53.5949 25.7102ZM48.5305 12.1613L52.126 8.56607H52.1396L53.897 6.80888C54.9749 5.73101 56.7183 5.73022 57.7975 6.80752L57.799 6.80907L63.1855 12.2092L63.1912 12.2149C64.2696 13.2931 64.2696 15.0362 63.1912 16.1144L63.191 16.1147L57.8376 21.4677L48.5305 12.1613Z'
  })
};

export const IconPlus = {
  Solid: createIcon({
    viewBox: '0 0 71 71',
    fill: 'currentColor',
    pathOrChildren:
      'M31.1613 66.2381C31.1613 68.4473 32.9522 70.2381 35.1613 70.2381C37.3705 70.2381 39.1613 68.4472 39.1613 66.238V38.8096H66.238C68.4472 38.8096 70.2381 37.0187 70.2381 34.8096C70.2381 32.6004 68.4473 30.8096 66.2381 30.8096H39.1613V3.99995C39.1613 1.79081 37.3705 -2.62556e-05 35.1613 2.89219e-10C32.9522 2.62561e-05 31.1613 1.7909 31.1613 4.00004V30.8096H4.00005C1.79091 30.8096 2.62561e-05 32.6004 2.8831e-10 34.8096C-2.62556e-05 37.0187 1.79081 38.8096 3.99995 38.8096H31.1613V66.2381Z'
  })
};

export const IconEllipsisHorizontal = {
  Solid: createIcon({
    viewBox: '0 0 70 15',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path d="M0.0214844 7C0.0214844 3.13401 3.15549 0 7.02148 0C10.8875 0 14.0215 3.13401 14.0215 7C14.0215 10.866 10.8875 14 7.02148 14C3.15549 14 0.0214844 10.866 0.0214844 7Z" />
        <path d="M28.0215 7C28.0215 3.13401 31.1555 0 35.0215 0C38.8875 0 42.0215 3.13401 42.0215 7C42.0215 10.866 38.8875 14 35.0215 14C31.1555 14 28.0215 10.866 28.0215 7Z" />
        <path d="M56.0215 7C56.0215 3.13401 59.1555 0 63.0215 0C66.8875 0 70.0215 3.13401 70.0215 7C70.0215 10.866 66.8875 14 63.0215 14C59.1555 14 56.0215 10.866 56.0215 7Z" />
      </>
    )
  })
};

export const IconEllipsisVertical = {
  Solid: createIcon({
    viewBox: '0 0 15 70',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path d="M14.0215 7C14.0215 10.866 10.8875 14 7.02148 14C3.15549 14 0.0214844 10.866 0.0214844 7C0.0214844 3.13401 3.15549 0 7.02148 0C10.8875 0 14.0215 3.13401 14.0215 7Z" />
        <path d="M14.0215 35C14.0215 38.866 10.8875 42 7.02148 42C3.15549 42 0.0214844 38.866 0.0214844 35C0.0214844 31.134 3.15549 28 7.02148 28C10.8875 28 14.0215 31.134 14.0215 35Z" />
        <path d="M14.0215 63C14.0215 66.866 10.8875 70 7.02148 70C3.15549 70 0.0214844 66.866 0.0214844 63C0.0214844 59.134 3.15549 56 7.02148 56C10.8875 56 14.0215 59.134 14.0215 63Z" />
      </>
    )
  })
};
