import { createIcon } from '../createIcon';

export const IconTimes = {
  Solid: createIcon({
    viewBox: '5 5 14 14',
    strokeWidth: 1.5,
    fill: 'none',
    stroke: 'currentColor',
    pathOrChildren: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    )
  })
};
