import {
  createContext,
  forwardRef,
  isValidElement,
  memo,
  useCallback,
  useContext,
  useId
} from 'react';
import { Collapse, CollapseProps } from '@b2w/react-ui/transitions2';
import { useControllableState } from '@b2w/shared/react-hooks';
import { IconChevronDown } from '../Icon';
import {
  ComponentPropsWithRef,
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import { twCx } from '../twMerge';
import { callAllHandlers } from '@b2w/shared/utility';

type AccordionCtxValue = Pick<
  CollapseProps,
  'applyFadeOnCollapse' | 'mountUnmout'
> & {
  onSummaryClick: (itemId: string, summaryId: string) => any;
  isItemOpened: (id: string) => any;
};

const AccordionCtx = createContext<AccordionCtxValue>(null!);
const useAccordionCtx = () => useContext(AccordionCtx);

export type AccordionMode = 'single' | 'multiple';
export type AccordionValue<TMode extends AccordionMode> = TMode extends 'single'
  ? string
  : string[];

export type AccordionOwnProps<TMode extends AccordionMode> = Pick<
  CollapseProps,
  'applyFadeOnCollapse' | 'mountUnmout'
> & {
  /** Id(s) of default opened `Accordion.Item`(s) in uncontrolled mode */
  defaultOpened?: AccordionValue<TMode>;
  /** Opened id(s) in controlled mode */
  opened?: AccordionValue<TMode>;
  /** Callback which fires when opened id(s) changes */
  onOpenedChange?: (opened: AccordionValue<TMode>) => any;
  /**
   * Single = one item can be expanded at a time
   *
   * Multiple = multiple items can be expanded at a time
   *
   * @default 'single'
   * */
  mode?: TMode;
};

export type AccordionProps<TMode extends AccordionMode> = ComponentPropsWithRef<
  'div',
  AccordionOwnProps<TMode>
>;

type AccordionRootComponent = <TMode extends AccordionMode = 'single'>(
  props: AccordionProps<TMode>
) => React.JSX.Element;

const AccordionRoot = forwardRef(
  <TMode extends AccordionMode>(
    props: AccordionProps<TMode>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const {
      mode = 'single',
      opened: openedProp,
      defaultOpened: defaultOpenedProp = mode === 'single' ? '' : [],
      onOpenedChange: onOpenedChangeProp,
      children,
      applyFadeOnCollapse,
      mountUnmout,
      ...htmlProps
    } = props;

    const [opened, setOpened] = useControllableState<AccordionValue<TMode>>({
      value: openedProp,
      onChange: onOpenedChangeProp,
      defaultValue: defaultOpenedProp as AccordionValue<TMode>
    });

    const onSummaryClick = useCallback(
      (itemId: string) => {
        setOpened((state) => {
          if (mode === 'single') {
            return (state === itemId ? '' : itemId) as AccordionValue<TMode>;
          }

          const arr = (
            Array.isArray(state) ? state : [state]
          ) as AccordionValue<'multiple'>;

          return (
            arr.includes(itemId)
              ? arr.filter((item) => item !== itemId)
              : arr.concat(itemId)
          ) as AccordionValue<TMode>;
        });
      },
      [mode, setOpened]
    );

    const isItemOpened = (id: string) => {
      return mode === 'single'
        ? (opened as AccordionValue<'single'>) === id
        : (opened as AccordionValue<'multiple'>)?.includes(id);
    };

    const ctx: AccordionCtxValue = {
      onSummaryClick,
      isItemOpened,
      applyFadeOnCollapse,
      mountUnmout
    };

    return (
      <AccordionCtx.Provider value={ctx}>
        <div {...htmlProps} ref={ref}>
          {children}
        </div>
      </AccordionCtx.Provider>
    );
  }
) as AccordionRootComponent;

export type AccordionItemOwnProps = {
  children?:
    | React.ReactNode
    | ((detailsProps: AccordionDetailsOwnProps) => React.JSX.Element);
  summary?:
    | React.ReactNode
    | ((summaryProps: AccordionSummaryOwnProps) => React.JSX.Element);
};

export type AccordionItemProps = ComponentPropsWithRef<
  'div',
  AccordionItemOwnProps
>;

const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
  (props, ref) => {
    const { id, children, summary, ...htmlProps } = props;
    const { isItemOpened, onSummaryClick, applyFadeOnCollapse, mountUnmout } =
      useAccordionCtx();

    const uuid = useId();
    const itemId = id ?? uuid;
    const detailsId = `${itemId}-details`;
    const summaryId = `${itemId}-summary`;

    const isOpened = isItemOpened(itemId);

    const summaryProps: AccordionSummaryOwnProps = {
      id: summaryId,
      detailsId,
      isOpened,
      itemId,
      onSummaryClick
    };

    const renderedSummary =
      typeof summary === 'function' ? (
        summary(summaryProps)
      ) : (
        <AccordionSummary {...summaryProps}>{summary}</AccordionSummary>
      );

    const detailsProps: AccordionDetailsOwnProps = {
      id: detailsId,
      summaryId
    };

    const renderedDetails =
      typeof children === 'function' ? (
        children(detailsProps)
      ) : (
        <AccordionDetails {...detailsProps}>{children}</AccordionDetails>
      );

    return (
      <div
        {...htmlProps}
        ref={ref}
        id={itemId}
        className={twCx('tw-border-b', htmlProps.className)}
      >
        {renderedSummary}
        <Collapse
          in={isOpened}
          applyFadeOnCollapse={applyFadeOnCollapse}
          mountUnmout={mountUnmout}
        >
          {renderedDetails}
        </Collapse>
      </div>
    );
  }
);

export type AccordionSummaryOwnProps = Pick<
  AccordionCtxValue,
  'onSummaryClick'
> & {
  id: string;
  itemId: string;
  detailsId: string;
  isOpened: boolean;
  icon?: React.JSX.Element | 'none';
};

export type AccordionSummaryProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropsWithRef<C, AccordionSummaryOwnProps>;

type AccordionSummaryComponent = <C extends React.ElementType = 'button'>(
  props: AccordionSummaryProps<C>
) => JSX.Element;

const AccordionSummary = memo(
  forwardRef(
    <T extends React.ElementType = 'button'>(
      props: PolymorphicComponentProps<T, AccordionSummaryComponent>,
      ref: PolymorphicRef<T>
    ) => {
      const {
        as: Component = 'button',
        onSummaryClick,
        id,
        itemId,
        detailsId,
        children,
        isOpened,
        icon: iconProp,
        ...htmlProps
      } = props;

      const onClick = () => {
        onSummaryClick(itemId, id);
      };

      const renderIcon = () => {
        if (iconProp === 'none') {
          return null;
        }

        if (iconProp && isValidElement(iconProp)) {
          return iconProp;
        }

        return (
          <IconChevronDown.Solid
            size="sm"
            className={twCx(
              isOpened && 'tw-rotate-180',
              'tw-transition-transform'
            )}
          />
        );
      };

      return (
        <Component
          {...htmlProps}
          ref={ref}
          type={Component === 'button' ? 'button' : htmlProps.type}
          id={id}
          aria-controls={detailsId}
          aria-expanded={isOpened}
          onClick={callAllHandlers(htmlProps.onClick, onClick as any)}
          className={twCx(
            'tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4 tw-font-medium',
            typeof Component === 'string' &&
              '[&:not(:disabled)]:hover:tw-underline disabled:tw-cursor-not-allowed disabled:tw-opacity-75',
            htmlProps.className
          )}
        >
          {children}
          {renderIcon()}
        </Component>
      );
    }
  )
) as AccordionSummaryComponent;

export type AccordionDetailsOwnProps = {
  id: string;
  summaryId: string;
};

export type AccordionDetailsProps = ComponentPropsWithRef<
  'div',
  AccordionDetailsOwnProps
>;

const AccordionDetails = forwardRef<HTMLDivElement, AccordionDetailsProps>(
  (props, ref) => {
    const { id, summaryId, children, ...htmlProps } = props;

    return (
      <div
        {...htmlProps}
        ref={ref}
        id={id}
        role="region"
        aria-labelledby={summaryId}
        className={twCx('tw-pb-4', htmlProps.className)}
      >
        {children}
      </div>
    );
  }
);

export const Accordion = Object.assign(AccordionRoot, {
  Item: AccordionItem,
  Summary: AccordionSummary,
  Details: AccordionDetails
});
