import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

export const templates = {
  dateAndTime: 'D MMM YYYY, HH:mm',
  date: 'D MMM YYYY',
  dateWithoutYear: 'D MMM',
  month: 'MMMM YYYY',
  dateAndTimeDots: 'DD.MM.YYYY, HH:mm',
  dateDots: 'DD.MM.YYYY',
  hh_mm: 'HH:mm',
  date_hh_mm: 'DD.MM HH:mm',
  day_and_time: 'MMM D, HH:mm',
  lastSeen: 'DD.MM.YYYY HH:mm',
  dateSlashesAndTime: 'DD/MM/YY HH:mm',
  dateDashesAndTime: 'DD-MM-YYYY HH:mm',
  dateDotsAndTime: 'DD.MM.YYYY HH:mm',
  dateDashes: 'DD-MM-YYYY',
  dateSlashes: 'DD/MM/YYYY',
  payout: 'DD-MM-YYYY HH:mm UTC Z',
  daySlashMonthTime: 'DD/MM HH:mm',
  analytics: 'YYYY-MM-DD'
};

export function formatDate(
  date: dayjs.ConfigType,
  templateKey: keyof typeof templates
) {
  return dayjs(date).format(templates[templateKey]);
}

export function isSameDate(d1: dayjs.ConfigType, d2: dayjs.ConfigType) {
  return dayjs(d1).isSame(d2, 'date');
}

export function getRelativeTime(date: dayjs.ConfigType, lng: string) {
  return dayjs(date).locale(lng).fromNow();
}

export function isDayBefore(d1: Date, d2: Date) {
  return dayjs(d1).isBefore(dayjs(d2));
}

export function isPastDay(d: Date) {
  return isDayBefore(d, new Date());
}

/**
 * @returns string in ISO format: 2020-08-24T01:00
 */
export function toISOLocal(
  date: dayjs.ConfigType,
  hour: number | string,
  mins?: number | string
) {
  let result = dayjs(date).set('hour', parseInt(hour as string, 10));

  if (mins) {
    result = result.set('minute', parseInt(mins as string, 10));
  }

  return result.format('YYYY-MM-DDTHH:mm');
}

export function parseISOLocal(isoLocal: string) {
  return dayjs(isoLocal, 'YYYY-MM-DDTHH:mm', true);
}

/**
 * Get date range of current week
 *
 * @param today date treated as today
 *
 * @returns date range from MON to SUN of `today` week
 */
export function getWeekDates(_today: dayjs.ConfigType): Date[] {
  const today = dayjs(_today);
  const week = [];

  for (let i = 1; i <= 7; i++) {
    // dayjs starts week from Sunday but we want from Monday
    const day = today.subtract(1, 'd').day(i).toDate();
    week.push(day);
  }

  return week;
}

export function getInclusiveMonthRange(
  start: dayjs.ConfigType,
  end: dayjs.ConfigType
) {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  const dates: { date: string; formatted: string }[] = [
    {
      date: startDate.format('YYYY-MM'),
      formatted: startDate.format('MMMM YYYY')
    }
  ];

  let currentMonth = dayjs(startDate);

  while (currentMonth.isBefore(endDate, 'month')) {
    currentMonth = currentMonth.add(1, 'month');
    dates.push({
      date: currentMonth.format('YYYY-MM'),
      formatted: currentMonth.format('MMMM YYYY')
    });
  }

  return dates;
}

export function humanizeTime(ms: number, withSuffix = false) {
  return dayjs.duration(ms, 'milliseconds').humanize(withSuffix);
}
