import { useProfile } from '@/context/profile.context';
import ContentLoader from 'react-content-loader';
import AvatarLoader from './loaders/AvatarLoader';
import AppLink from './AppLink';
import { twCx } from '@b2w/react-ui/core2';

type ViewMyProfileHeaderProps = {
  /** @default 55 */
  avatarSizePx?: number;
  /** @default false */
  underlineLinkOnHover?: boolean;
  className?: string;
};

const ViewMyProfileHeader = (props: ViewMyProfileHeaderProps) => {
  const { avatarSizePx = 55, className, underlineLinkOnHover = false } = props;
  const { profile } = useProfile();

  const renderedContent = (
    <div className={twCx('tw-flex tw-gap-2 tw-items-center', className)}>
      {profile?.avatar ? (
        <img
          src={profile?.avatar}
          alt={profile?.firstName ?? ''}
          width={avatarSizePx}
          height={avatarSizePx}
          className="tw-aspect-square tw-rounded-full"
        />
      ) : (
        <AvatarLoader
          uniqueKey={`avatar-${avatarSizePx}-profileheader`}
          width={avatarSizePx}
          height={avatarSizePx}
          className="tw-border tw-border-gray-100 tw-rounded-full"
        />
      )}
      <div className="tw-min-w-0">
        {profile?.firstName ? (
          <h1 className="tw-truncate">{profile?.firstName}</h1>
        ) : (
          <ContentLoader
            uniqueKey="firstname-profileheader"
            speed={2}
            viewBox="0 0 150 25"
            style={{ height: 19, width: 'auto', maxWidth: '100%' }}
          >
            <rect x="0" y="0" rx="5" ry="5" width="150" height="25" />
          </ContentLoader>
        )}
        <div className="tw-text-gray-400 tw-text-xs tw-mt-0.5">
          View public profile
        </div>
      </div>
    </div>
  );

  return profile ? (
    <AppLink
      href={`/user/${profile.id}`}
      prefetch={false}
      className={twCx(
        underlineLinkOnHover ? 'hover:tw-underline' : 'hover:tw-no-underline',
        'tw-min-w-0'
      )}
    >
      {renderedContent}
    </AppLink>
  ) : (
    renderedContent
  );
};

export default ViewMyProfileHeader;
