import { createIcon } from '../createIcon';

export const IconCheck = {
  Solid: createIcon({
    viewBox: '0 0 70 52',
    fill: 'currentColor',
    pathOrChildren:
      'M67.5665 0.940751C66.312 -0.313119 64.2796 -0.313816 63.025 0.940054L25.7769 41.377L6.61399 21.5992C5.70234 20.6889 3.94734 20.967 2.69277 22.2209L1.17892 23.7354C-0.0756432 24.9893 -0.353042 26.7443 0.557216 27.6553C0.703582 27.9333 23.1716 51.086 23.4058 51.3195C24.659 52.5741 26.6927 52.5741 27.9459 51.3202L69.0818 6.99613C70.335 5.74226 70.335 3.70916 69.0804 2.45529L67.5665 0.940751Z'
  })
};

export const IconCheckCircle = {
  Outline: createIcon({
    viewBox: '0 0 70 70',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path d="M47.8422 2.43111C49.7867 3.19845 50.3852 5.57168 49.2339 7.31649C48.2462 8.81334 46.3029 9.31189 44.6187 8.69586C41.6191 7.59871 38.3795 7 35 7C19.536 7 7 19.536 7 35C7 50.464 19.536 63 35 63C50.464 63 63 50.464 63 35C63 33.9181 63.8494 33 64.9313 33H67.9428C69.0657 33 70 33.8771 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35C0 15.67 15.67 0 35 0C39.5337 0 43.8661 0.862024 47.8422 2.43111Z" />
        <path d="M59.198 12.6586C60.0769 11.7802 61.5008 11.7806 62.3797 12.6591L63.4402 13.7201C64.3191 14.5985 64.3191 16.0228 63.4412 16.9012L32.4327 47.9083C31.5548 48.7868 30.1299 48.7868 29.252 47.9078C29.2218 47.8777 28.7355 47.4073 27.9615 46.6586C24.5329 43.3421 15.459 34.5647 15.3753 34.4058C14.7376 33.7676 14.932 32.5381 15.8109 31.6597L16.8714 30.5986C17.7503 29.7202 18.9798 29.5254 19.6185 30.1631L30.9132 40.9425L59.198 12.6586Z" />
      </>
    )
  })
};

export const IconInfo = {
  Outline: createIcon({
    viewBox: '0 0 70 70',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path d="M35 63C19.565 63 7 50.435 7 35C7 19.565 19.565 7 35 7C50.435 7 63 19.565 63 35C63 50.435 50.435 63 35 63ZM35 0C30.4037 0 25.8525 0.905303 21.6061 2.66422C17.3597 4.42313 13.5013 7.00122 10.2513 10.2513C3.6875 16.8151 0 25.7174 0 35C0 44.2827 3.6875 53.185 10.2513 59.7489C13.5013 62.999 17.3597 65.5767 21.6061 67.3358C25.8525 69.0946 30.4037 70 35 70C44.2827 70 53.185 66.3124 59.7489 59.7489C66.3124 53.185 70 44.2827 70 35C70 30.4037 69.0946 25.8525 67.3358 21.6061C65.5767 17.3597 62.999 13.5013 59.7489 10.2513C56.4988 7.00122 52.6404 4.42313 48.3938 2.66422C44.1476 0.905303 39.5962 0 35 0ZM31.5 50.5C31.5 51.6046 32.3954 52.5 33.5 52.5H36.5C37.6046 52.5 38.5 51.6046 38.5 50.5V33.5C38.5 32.3954 37.6046 31.5 36.5 31.5H33.5C32.3954 31.5 31.5 32.3954 31.5 33.5V50.5Z" />
        <path d="M36.5 27.2513H33.5C32.3954 27.2513 31.5 26.3558 31.5 25.2513V21.2513C31.5 20.1467 32.3954 19.2513 33.5 19.2513H36.5C37.6046 19.2513 38.5 20.1467 38.5 21.2513V25.2513C38.5 26.3558 37.6046 27.2513 36.5 27.2513Z" />
      </>
    )
  })
};

export const IconWarning = {
  Outline: createIcon({
    viewBox: '0 -5.25 70 70',
    fill: 'currentColor',
    pathOrChildren:
      'M34.156 13.1908C34.5411 12.5254 35.5018 12.5254 35.887 13.1908L58.1119 51.59C58.4977 52.2566 58.0167 53.0909 57.2464 53.0909H12.7966C12.0263 53.0909 11.5453 52.2566 11.9311 51.59L34.156 13.1908ZM35.8869 0.494826C35.5017 -0.170459 34.5412 -0.170458 34.1561 0.494827L0.890503 57.9535C0.504538 58.6202 0.985599 59.4545 1.75593 59.4545H68.287C69.0574 59.4545 69.5384 58.6202 69.1525 57.9535L35.8869 0.494826ZM38.2033 44.5455C38.2033 43.9932 37.7556 43.5455 37.2033 43.5455H32.8397C32.2874 43.5455 31.8397 43.9932 31.8397 44.5455V48.9091C31.8397 49.4614 32.2874 49.9091 32.8397 49.9091H37.2033C37.7556 49.9091 38.2033 49.4614 38.2033 48.9091V44.5455ZM38.2033 25.4545C38.2033 24.9023 37.7556 24.4545 37.2033 24.4545H32.8397C32.2874 24.4545 31.8397 24.9023 31.8397 25.4545V36.1818C31.8397 36.7341 32.2874 37.1818 32.8397 37.1818H37.2033C37.7556 37.1818 38.2033 36.7341 38.2033 36.1818V25.4545Z'
  })
};
