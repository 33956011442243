import { IconButton, IconButtonProps } from '../Button';
import { IconChevronLeft, IconChevronRight, IconTimes } from '../Icon';
import { twCx } from '../twMerge';

type GalleryIconButtonsProps = Omit<
  IconButtonProps<'button'>,
  'className' | 'colorScheme' | 'size'
>;

const GalleryIconButton = (props: GalleryIconButtonsProps) => (
  <IconButton
    {...props}
    colorScheme="neutral"
    size="sm"
    className="tw-rounded-full"
  />
);

type ArrowProps = Pick<
  GalleryIconButtonsProps,
  'aria-label' | 'icon' | 'onClick' | 'tabIndex'
> & {
  className?: string;
  isResponsive: boolean;
};

const Arrow = ({ className, isResponsive, ...btnProps }: ArrowProps) => {
  return (
    <div
      className={twCx(
        'tw-absolute tw-cursor-pointer tw-p-3',
        isResponsive
          ? 'tw-transition-all tw-duration-150 tw-delay-300 lg:tw-opacity-0 group-hover:lg:tw-opacity-100 group-hover:lg:tw-translate-x-0 group-hover:lg:tw-delay-0'
          : '',
        className
      )}
    >
      <GalleryIconButton {...btnProps} />
    </div>
  );
};

export const LeftArrowButton = (
  props: Pick<ArrowProps, 'onClick' | 'isResponsive' | 'tabIndex'>
) => (
  <Arrow
    {...props}
    className={twCx(
      'tw-left-0',
      props.isResponsive && 'lg:-tw-translate-x-full'
    )}
    icon={(s) => <IconChevronLeft.Solid size={s} />}
    aria-label="Slide to previous"
  />
);

export const RightArrowButton = (
  props: Pick<ArrowProps, 'onClick' | 'isResponsive' | 'tabIndex'>
) => (
  <Arrow
    {...props}
    className={twCx(
      'tw-right-0',
      props.isResponsive && 'lg:tw-translate-x-full'
    )}
    icon={(s) => <IconChevronRight.Solid size={s} />}
    aria-label="Slide to next"
  />
);

export const CloseButton = (
  props: Pick<GalleryIconButtonsProps, 'onClick'>
) => (
  <div className="tw-absolute tw-cursor-pointer tw-p-3 tw-right-0 tw-top-0">
    <GalleryIconButton
      {...props}
      aria-label="Close gallery fullscreen"
      icon={(s) => <IconTimes.Solid size={s} />}
    />
  </div>
);
