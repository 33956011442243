import React, { ComponentProps } from 'react';
import Link, { LinkProps } from 'next/link';
import RouterSsr, { NextRouter } from 'next/router';
import { useRouter } from 'next/compat/router';
import { AFF_REF_PARAM_NAME } from './affiliate-dashboard/affiliate.param';
import { CURRENCY_PARAM_NAME } from './currency-changer/currency-changer.utils';

export type AppLinkProps = ComponentProps<typeof Link> & {
  children?: React.ReactNode;
};

/**
 * Wrapper around next/link which retains predefined query params during
 * navigation.
 *
 * It has the same API as next/link.
 *
 * @example
 * current url = '/?someParam=xyz'
 * AppLink href = '/signin?tab=signup'
 * url after navigation = '/signin?someParam=xyz&tab=signup'
 */
const AppLink = (props: AppLinkProps) => {
  const router = useRouter();
  const href = resolveAppHref(
    props.href,
    router?.query ?? RouterSsr?.query ?? {}
  );

  return <Link {...props} href={href} />;
};

export default AppLink;

// ===========

const PARAMS_TO_PRESERVE = [
  AFF_REF_PARAM_NAME,
  CURRENCY_PARAM_NAME,
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_ad',
  'utm_term',
  'matchtype',
  'device',
  'campaign_id',
  'adset_id',
  'ad_id',
  'bookingCoupon',
  'erCare'
];
const RETAIN_AT: 'start' | 'end' = 'start';

export function resolveAppHref(
  hrefProp: LinkProps['href'],
  query: NextRouter['query']
): LinkProps['href'] {
  if (typeof hrefProp === 'string') {
    // prevent duplicates
    const paramsToPreservedFixed = PARAMS_TO_PRESERVE.filter(
      (p) => !hrefProp.includes(`${p}=`)
    );

    const retainedPieces: string[] = Object.keys(query).reduce((acc, key) => {
      if (paramsToPreservedFixed.includes(key)) {
        return acc.concat(key + '=' + query[key]);
      }

      return acc;
    }, []);

    if (!retainedPieces.length) {
      return hrefProp;
    }

    const retainedQueryStr = retainedPieces.join('&');

    if (hrefProp.includes('?')) {
      const byQm = hrefProp.split('?');
      const byHash = byQm[1] ? byQm[1].split('#') : [''];

      const queryStr =
        RETAIN_AT === 'start'
          ? `?${retainedQueryStr}${byHash[0] ? `&${byHash[0]}` : ''}`
          : `?${byHash[0] ? `${byHash[0]}&` : ''}${retainedQueryStr}`;

      const hashStr = byHash[1] ? `#${byHash[1]}` : '';

      return byQm[0] + queryStr + hashStr;
    } else {
      const byHash = hrefProp.split('#');
      return (
        byHash[0] + `?${retainedQueryStr}` + (byHash[1] ? `#${byHash[1]}` : '')
      );
    }
  }

  if (typeof hrefProp === 'object') {
    let finalQuery = hrefProp.query;

    // prevent duplicates
    const paramsToPreservedFixed = PARAMS_TO_PRESERVE.filter((p) =>
      typeof hrefProp.query === 'string'
        ? !hrefProp.query.includes(`${p}=`)
        : !Object.keys(hrefProp.query).includes(p)
    );

    const queryToKeep = Object.keys(query).reduce((acc, key) => {
      if (paramsToPreservedFixed.includes(key)) {
        return {
          ...acc,
          [key]: query[key]
        };
      }

      return acc;
    }, {});

    if (!hrefProp.query) {
      finalQuery = queryToKeep;

      return {
        ...hrefProp,
        query: queryToKeep
      };
    }

    if (typeof hrefProp.query === 'string') {
      const retainedPieces: string[] = Object.keys(queryToKeep).reduce(
        (acc, key) => acc.concat(key + '=' + queryToKeep[key]),
        []
      );

      if (hrefProp.query) {
        if (RETAIN_AT === 'start') {
          retainedPieces.push(hrefProp.query);
        } else {
          retainedPieces.unshift(hrefProp.query);
        }
      }

      finalQuery = retainedPieces.join('&');
    }

    if (typeof hrefProp.query === 'object') {
      finalQuery =
        RETAIN_AT === 'start'
          ? {
              ...queryToKeep,
              ...hrefProp.query
            }
          : {
              ...hrefProp.query,
              ...queryToKeep
            };
    }

    return {
      ...hrefProp,
      query: finalQuery
    };
  }

  return hrefProp;
}
