import { forwardRef, useId } from 'react';
import {
  UseInputFormControlOwnProps,
  FormLabel,
  useInputFormControl
} from '../FormControl';
import { useCheckboxGroupCtx } from './checkbox-group.init';
import { useFormControlCtx } from '../FormControl/form-control.init';
import { twCx } from '../twMerge';

const checkboxBgClassName = twCx(
  'tw-w-5 tw-h-5 tw-rounded-md',
  'before:tw-absolute before:tw-top-0 before:tw-left-0 before:tw-w-full before:tw-h-full',
  'before:tw-rounded-md before:tw-border',
  'before:tw-transition before:tw-duration-75',
  'active:before:tw-bg-gray-50'
);

const checkboxCheckmarkClassName = twCx(
  `after:tw-absolute after:tw-block after:tw-content-[''] after:-tw-translate-x-1/2 after:-tw-translate-y-[55%] after:tw-top-1/2 after:tw-left-1/2`,
  'after:tw-w-1.5 after:tw-h-3',
  'after:tw-border-white after:tw-border-b-2 after:tw-border-r-2 after:tw-rotate-[40deg]',
  'after:tw-transition after:tw-duration-75',
  'after:tw-opacity-0 after:tw-scale-50',
  'checked:after:tw-opacity-100 checked:after:tw-scale-100'
);
const checkboxIndeterminateLineClassName = twCx(
  `after:tw-absolute after:tw-block after:tw-content-[''] after:-tw-translate-x-1/2 after:-tw-translate-y-1/2 after:tw-top-1/2 after:tw-left-1/2`,
  'after:tw-w-2.5 after:tw-h-0',
  'after:tw-border-white after:tw-border-b-2',
  'after:tw-transition after:tw-duration-75',
  'after:tw-opacity-0 after:tw-scale-50',
  'active:after:tw-border-gray-50',
  'checked:after:tw-opacity-100 checked:after:tw-scale-100'
);

const checkboxColorsClassName: Record<CheckboxColorScheme, string> = {
  main: twCx(
    // border
    'before:tw-border-gray-200',
    'hover:before:tw-border-gray-300',
    'checked:before:tw-border-main-500',
    'checked:hover:before:tw-border-main-600',
    'active:checked:before:tw-border-main-700',
    // bg
    'checked:before:tw-bg-main-500',
    'checked:hover:before:tw-bg-main-600',
    'active:checked:before:tw-bg-main-700',
    // outline
    'tw-outline-main-500'
  ),
  dark: twCx(
    // border
    'before:tw-border-gray-200',
    'hover:before:tw-border-gray-300',
    'checked:before:tw-border-dark-500',
    'checked:hover:before:tw-border-dark-600',
    'active:checked:before:tw-border-dark-700',
    // bg
    'checked:before:tw-bg-dark-500',
    'checked:hover:before:tw-bg-dark-600',
    'active:checked:before:tw-bg-dark-700',
    // outline
    'tw-outline-dark-500'
  ),
  success: twCx(
    // border
    'before:tw-border-gray-200',
    'hover:before:tw-border-gray-300',
    'checked:before:tw-border-success-500',
    'checked:hover:before:tw-border-success-600',
    'active:checked:before:tw-border-success-700',
    // bg
    'checked:before:tw-bg-success-500',
    'checked:hover:before:tw-bg-success-600',
    'active:checked:before:tw-bg-success-700',
    // outline
    'tw-outline-success-500'
  )
};

const switchBgClassName = twCx(
  'tw-w-8 tw-h-5 tw-rounded-full',
  'before:tw-absolute before:tw-top-0 before:tw-left-0 before:tw-w-full before:tw-h-full',
  'before:tw-rounded-full before:tw-border',
  'before:tw-transition before:tw-duration-75',
  'active:before:tw-bg-gray-50'
);

const switchCheckmarkClasName = twCx(
  `after:tw-absolute after:tw-block after:tw-content-[''] after:-tw-translate-y-1/2 after:tw-top-1/2 after:tw-left-0`,
  'after:tw-w-3 after:tw-h-3 after:tw-bg-gray-300 after:tw-rounded-full',
  'after:tw-mx-1',
  'after:tw-transition after:tw-duration-75',
  'after:tw-translate-x-0',
  'checked:after:tw-translate-x-full checked:after:tw-bg-white'
);

const switchIndeterminateLineClassName = twCx(
  `after:tw-absolute after:tw-block after:tw-content-[''] after:-tw-translate-y-1/2 after:tw-top-1/2 after:tw-left-0`,
  'after:tw-w-3 after:tw-h-3 after:tw-bg-gray-300 after:tw-rounded-full',
  'after:tw-mx-1',
  'after:tw-transition after:tw-duration-75',
  'after:tw-translate-x-0',
  'checked:after:tw-translate-x-1/2 checked:after:tw-bg-white'
);

const switchColorsClassName: Record<CheckboxColorScheme, string> = {
  main: twCx(
    // border
    'before:tw-border-gray-200',
    'hover:before:tw-border-gray-300',
    'checked:before:tw-border-main-500',
    'checked:hover:before:tw-border-main-600',
    'active:checked:before:tw-border-main-700',
    // bg
    'checked:before:tw-bg-main-500',
    'checked:hover:before:tw-bg-main-600',
    'active:checked:before:tw-bg-main-700',
    // outline
    'tw-outline-main-500'
  ),
  dark: twCx(
    // border
    'before:tw-border-gray-200',
    'hover:before:tw-border-gray-300',
    'checked:before:tw-border-dark-500',
    'checked:hover:before:tw-border-dark-600',
    'active:checked:before:tw-border-dark-700',
    // bg
    'checked:before:tw-bg-dark-500',
    'checked:hover:before:tw-bg-dark-600',
    'active:checked:before:tw-bg-dark-700',
    // outline
    'tw-outline-dark-500'
  ),
  success: twCx(
    // border
    'before:tw-border-gray-200',
    'hover:before:tw-border-gray-300',
    'checked:before:tw-border-success-500',
    'checked:hover:before:tw-border-success-600',
    'active:checked:before:tw-border-success-700',
    // bg
    'checked:before:tw-bg-success-500',
    'checked:hover:before:tw-bg-success-600',
    'active:checked:before:tw-bg-success-700',
    // outline
    'tw-outline-success-500'
  )
};

const invalidBgClassName = twCx(
  // border
  'aria-[invalid=true]:before:tw-border-error-300',
  'aria-[invalid=true]:hover:before:tw-border-error-400',
  'aria-[invalid=true]:checked:before:tw-border-error-500',
  'aria-[invalid=true]:checked:hover:before:tw-border-error-600',
  'aria-[invalid=true]:active:checked:before:tw-border-error-700',
  // bg
  'aria-[invalid=true]:checked:before:tw-bg-error-500',
  'aria-[invalid=true]:checked:hover:before:tw-bg-error-600',
  'aria-[invalid=true]:active:checked:before:tw-bg-error-700',
  // outline
  'aria-[invalid=true]:tw-outline-error-500'
);

export type CheckboxColorScheme = 'main' | 'dark' | 'success';

export interface CheckboxProps
  extends Omit<
      React.ComponentPropsWithRef<'input'>,
      'type' | 'value' | keyof UseInputFormControlOwnProps
    >,
    UseInputFormControlOwnProps {
  /** Value checkbox represents */
  value?: string | number;
  /** Checkbox Label */
  label?: React.ReactNode;
  /** Whether to show indeterminate checkbox icon when checked */
  isIndeterminate?: boolean;
  /**
   * Checkbox can be either checkbox or switch
   */
  asSwitch?: boolean;
  showRequiredIndicator?: boolean;
  /**
   * @default 'main'
   */
  colorScheme?: CheckboxColorScheme;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      isIndeterminate = false,
      asSwitch = false,
      showRequiredIndicator,
      className: classNameProp,
      colorScheme = 'main',
      ...inputHtmlProps
    } = props;

    const groupCtx = useCheckboxGroupCtx();
    const formControlCtx = useFormControlCtx();

    const isWithinCheckboxGroup = !!groupCtx;
    const isWithinFormControl = !!formControlCtx;

    const { label, ...inputProps } = useInputFormControl<typeof Checkbox>(
      groupCtx ? groupCtx.getCheckboxProps(inputHtmlProps) : inputHtmlProps
    );

    const uuid = useId();
    const id =
      inputHtmlProps.id ?? (isWithinFormControl && !isWithinCheckboxGroup)
        ? inputProps.id
        : uuid;

    let typeClassName: string;
    if (asSwitch) {
      typeClassName = isIndeterminate
        ? twCx(
            switchBgClassName,
            switchIndeterminateLineClassName,
            switchColorsClassName[colorScheme]
          )
        : twCx(
            switchBgClassName,
            switchCheckmarkClasName,
            switchColorsClassName[colorScheme]
          );
    } else {
      typeClassName = isIndeterminate
        ? twCx(
            checkboxBgClassName,
            checkboxIndeterminateLineClassName,
            checkboxColorsClassName[colorScheme]
          )
        : twCx(
            checkboxBgClassName,
            checkboxCheckmarkClassName,
            checkboxColorsClassName[colorScheme]
          );
    }

    return (
      <div className={twCx('tw-inline-flex tw-items-center', classNameProp)}>
        <input
          {...inputProps}
          id={id}
          type="checkbox"
          ref={ref}
          className={twCx(
            'tw-appearance-none tw-cursor-pointer tw-relative tw-peer tw-flex-shrink-0',
            'focus-visible:tw-outline tw-outline-2 tw-outline-offset-2',
            'disabled:tw-cursor-not-allowed disabled:tw-opacity-75',
            invalidBgClassName,
            typeClassName
          )}
        />
        {!!label && (
          <FormLabel
            htmlFor={id}
            className="tw-pl-2 peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-75 tw-mb-0 tw-font-normal tw-text-base"
            requiredIndicator={showRequiredIndicator}
          >
            {label}
          </FormLabel>
        )}
      </div>
    );
  }
);
