
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import dynamic from 'next/dynamic';
import Layout from '@/components/layout';
import Alert from '@/components/Alert';
import { UserProvider } from '@/context/user.context';
import { AppProps } from 'next/app';
import { NextPageContext, NextComponentType } from 'next';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { AlertProvider } from '@/context/alert.context';
import { UserTempDataProvider } from '@/context/usertempdata.context';
import { ProfileSyncProvider } from '@/context/profileSync.context';
import { CurrencySettingProvider } from '@/components/currency-changer/currency-setting.context';
import { ContextInjector } from '@/components/ContextInjector';
import RedirectScript from '@/components/RedirectScript';
import Seo from '@/components/layout/Seo';
import WebFonts from '@/components/WebFonts';
import TagManager from '@/components/analytics/TagManager';
import CookieAlert from '@/components/CookieAlert';
fontAwesomeConfig.autoAddCss = false;
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/lib/icon-library';
import '@/styles/main.scss';
import '@/styles/tw.css';
const NProgress = dynamic(() => {
    return import('@/components/NProgress');
}, { ssr: false });
const PromoBanner = dynamic(() => {
    return import('@/components/PromoBanner');
}, { ssr: false });
const SuspensionModal = dynamic(() => {
    return import('@/components/SuspensionModal');
}, { ssr: false });
const UploadDriverLicenseNoticeModal = dynamic(() => {
    return import('@/components/UploadDriverLicenseNoticeModal');
}, { ssr: false });
const FbChatWidget = dynamic(() => {
    return import('@/components/FbChatWidget');
}, { ssr: false });
export type CustomCompProps = NextComponentType<NextPageContext, any, any> & {
    showFooter?: boolean;
    showNavbar?: boolean;
    showMobileNavs?: boolean;
    fullHeight?: boolean;
    showPromoBanner?: boolean;
    minFullHeight?: boolean;
    className?: string;
    bottomElement?: React.ReactNode;
};
function GlobalContext({ children }) {
    return (<AlertProvider>
      <Alert />
      <UserProvider>
        <UserTempDataProvider>
          <ProfileSyncProvider>
            <CurrencySettingProvider>{children}</CurrencySettingProvider>
          </ProfileSyncProvider>
        </UserTempDataProvider>
      </UserProvider>
    </AlertProvider>);
}
type MyApp = AppProps & {
    Component: CustomCompProps;
    err: any;
};
function MyApp({ Component, pageProps, router, err }: MyApp) {
    return (<>
      <RedirectScript path={router.asPath}/>
      <FbChatWidget path={router.pathname}/>
      <Seo />
      <TagManager />
      <CookieAlert />
      <WebFonts />
      <NProgress />
      <GlobalContext>
        <SuspensionModal />
        <UploadDriverLicenseNoticeModal />
        <Layout showFooter={Component.showFooter} showNavbar={Component.showNavbar} showMobileNavs={Component.showMobileNavs} fullHeight={Component.fullHeight} minFullHeight={Component.minFullHeight} className={Component.className} bottomElement={Component.bottomElement}>
          <PromoBanner />
          <ContextInjector>
            <Component {...pageProps} err={err}/>
          </ContextInjector>
        </Layout>
      </GlobalContext>
    </>);
}
const __Next_Translate__Page__19495277653__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19495277653__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  