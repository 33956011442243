import { DriverLicenseCategory, VehicleFeature } from '@b2w/shared/types';

export const VEHICLE_CATEGORIES = ['car', 'motorcycle', 'bike'];
export const CAR_TYPES = ['utilitarian', 'van', 'suv', 'sport car'];
export const MOTO_TYPES = [
  'scooter',
  'enduro',
  'chooper',
  'sport motorbike',
  'dirt_bike',
  'atv',
  'cafe_racer',
  'naked_bike',
  'three_wheeler'
];
export const BIKE_TYPES = ['city', 'mountain bike', 'cargo_bike'];
export const CAR_TRANSMISSION = ['manual', 'automatic'];
export const MOTO_TRANSMISSION = CAR_TRANSMISSION.concat('semi-automatic');
export const INSURANCE_OPTIONS = ['thirdParty', 'comprehensive'];
export enum ACCESSORY_CHARGE_TYPE {
  free = 'Free',
  fixedPrice = 'Fixed price',
  perDay = 'Per day'
}
export const ACCESSORY_CHARGE_TYPES = [
  ACCESSORY_CHARGE_TYPE.free,
  ACCESSORY_CHARGE_TYPE.fixedPrice,
  ACCESSORY_CHARGE_TYPE.perDay
];
export const BIKE_GEARS = [1, 3, 18, 21, 24, 27];
export const EXTRA_KMS = [0, 50, 100, 150, 200, 250, 300, 500];
export const INCLUDED_KM_UNLIMITED_OPTION = -1;
export const INCLUDED_KM_OPTIONS = [
  INCLUDED_KM_UNLIMITED_OPTION,
  150,
  200,
  250,
  300,
  500
];
export const DEFAULT_INCLUDED_KM_OPTION = INCLUDED_KM_UNLIMITED_OPTION;
export const SEATS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 50];
export const FUEL_TYPES = [
  'petrol',
  'diesel',
  'hybrid',
  'plugin hybrid',
  'electric'
];

export const VEHICLE_CATEGORY_TYPE_OPTIONS = [
  {
    name: 'car',
    label: 'Car',
    items: [
      {
        type: 'utilitarian',
        label: 'Sedan',
        img: '/images/utilitarian.png'
      },
      { type: 'van', label: 'Van', img: '/images/van.png' },
      { type: 'suv', label: 'SUV', img: '/images/suv.png' },
      { type: 'sport car', label: 'Sport car', img: '/images/sport-car.png' }
    ]
  },
  {
    name: 'motorcycle',
    label: 'Motorcycle',
    items: [
      { type: 'scooter', label: 'Scooter', img: '/images/scooter.png' },
      { type: 'enduro', label: 'Enduro', img: '/images/enduro.png' },
      { type: 'chooper', label: 'Chopper', img: '/images/chooper.png' },
      {
        type: 'sport motorbike',
        label: 'Sport motorbike',
        img: '/images/sport-motorbike.png'
      },
      { type: 'dirt_bike', label: 'Dirt bike', img: '/images/enduro.png' },
      { type: 'atv', label: 'ATV', img: '/images/suv.png' },
      {
        type: 'cafe_racer',
        label: 'Cafe racer',
        img: '/images/sport-motorbike.png'
      },
      {
        type: 'naked_bike',
        label: 'Naked bike',
        img: '/images/sport-motorbike.png'
      },
      {
        type: 'three_wheeler',
        label: '3-wheeler',
        img: '/images/scooter.png'
      }
    ]
  },
  {
    name: 'bike',
    label: 'Bicycle',
    items: [
      { type: 'city', label: 'City bike', img: '/images/city.png' },
      {
        type: 'mountain bike',
        label: 'Mountain bike',
        img: '/images/mountainbike.png'
      },
      {
        type: 'cargo_bike',
        label: 'Cargo bike',
        img: '/images/city.png'
      }
    ]
  }
];

export function mapVehicleTypeToLabel(vehicleType: string) {
  vehicleType = vehicleType.toLowerCase();

  const idx = VEHICLE_CATEGORY_TYPE_OPTIONS.findIndex((opt) =>
    opt.items.some((item) => item.type === vehicleType)
  );

  const labelMatch = VEHICLE_CATEGORY_TYPE_OPTIONS[idx]?.items.find(
    (item) => item.type === vehicleType
  )?.label;

  return labelMatch ?? vehicleType;
}

export function mapVehicleCategoryToLabel(vehicleCategory: string) {
  vehicleCategory = vehicleCategory.toLowerCase();

  const optionMatch = VEHICLE_CATEGORY_TYPE_OPTIONS.find(
    (opt) => opt.name === vehicleCategory
  );

  return optionMatch?.label ?? vehicleCategory;
}

export const ALL_VEHICLE_TYPES = VEHICLE_CATEGORY_TYPE_OPTIONS.reduce(
  (acc, cat) => acc.concat(cat.items.map((i) => i.type)),
  [] as string[]
);

export const DEFAULT_VEHICLE_BRANDS = {
  motorcycle: [
    'Aprilia',
    'BMW',
    'Buell',
    'Can-Am',
    'Ducati',
    'Harley Davidson',
    'Honda',
    'Kawasaki',
    'KTM',
    'Kymco',
    'Moto Guzzi',
    'Suzuki',
    'Triumph',
    'Yamaha',
    'BSA',
    'Janus',
    'Ural',
    'Indian',
    'Russi',
    'Motorstar'
  ],
  car: [
    'Asia Star',
    'Audi',
    'Baic',
    'BMW',
    'Byd',
    'Chery',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Dongfeng',
    'Faw',
    'Ford',
    'Foton',
    'Gaz',
    'Geely',
    'Great Wall',
    'Haima',
    'Honda',
    'Hyundai',
    'Isuzu',
    'Jac',
    'Jaguar',
    'Jeep',
    'Jinbei',
    'Jmc',
    'Joylong',
    'Kia',
    'King Long',
    'Land rover',
    'Lexus',
    'Lifan',
    'Lotus',
    'Mahindra',
    'Mazda',
    'Mercedes Benz',
    'Mg',
    'Mini',
    'Mitsubishi',
    'Morgan',
    'Nissan',
    'Peugeot',
    'Range rover',
    'Ssangyong',
    'Subaru',
    'Suzuki',
    'Tata',
    'Toyota',
    'Volkswagen',
    'Volvo'
  ],
  bike: []
};

export const DEFAULT_ACCESSORIES = {
  car: [
    'Delivery (airport)',
    'Return (airport)',
    'Delivery (hotel)',
    'Return (hotel)',
    'Dedicated parking space',
    'Child seat',
    'Extra seats',
    'GPS',
    'Driver',
    'Buy insurance',
    'Roof box',
    'Tow bar',
    'Phone holder'
  ],
  motorcycle: [
    'Helmet',
    '2nd Helmet',
    'Delivery (airport)',
    'Return (airport)',
    'Delivery (hotel)',
    'Return (hotel)',
    'Dedicated parking space',
    'Driver',
    'GPS',
    'Buy insurance',
    'Gloves',
    'Jacket',
    'Extra storage',
    'Passenger equipment',
    'Child seat',
    'Phone holder'
  ],
  bike: [
    'Back light',
    'Front light',
    'Helmet',
    '2nd Helmet',
    'Delivery (airport)',
    'Return (airport)',
    'Delivery (hotel)',
    'Return (hotel)',
    'GPS',
    'Gloves',
    'Jacket',
    'Extra lock',
    'Extra storage',
    'Passenger equipment',
    'Phone holder'
  ]
};

const InsuranceDisplayNameMap: Record<string, string> = {
  thirdParty: 'Third party',
  comprehensive: 'Comprehensive'
};

export const mapInsuranceDisplayNames = (insurance: string[]) => {
  return insurance.map((ins) => {
    return InsuranceDisplayNameMap[ins];
  });
};

export const PREPARATION_TIME_HOUR_OPTIONS = [0, 2, 4, 6, 8, 10, 24, 48];

export const MIN_WEEK_DISCOUNT = 0;
export const MAX_WEEK_DISCOUNT = 30;

export const MIN_MONTH_DISCOUNT = 0;
export const MAX_MONTH_DISCOUNT = 50;

export const chatPhoneNumberExposedWarning = (mask = '***') =>
  `We do not encourage phone number sharing in Book2Wheel chat. Your last message was partially replaced with ${mask}. If you think this was a misunderstanding, please ignore this message.`;

export const driverIdCategoryToLabelMap = {
  [DriverLicenseCategory.AM]: 'AM (mopeds)',
  [DriverLicenseCategory.A1]: 'A1 (small motorcycles/scooters)',
  [DriverLicenseCategory.A]: 'A (motorcycles)',
  [DriverLicenseCategory.B1]: 'B1 (quadricycles/tricycles)',
  [DriverLicenseCategory.B]: 'B (passenger cars)',
  [DriverLicenseCategory.C1]: 'C1 (trucks)',
  [DriverLicenseCategory.C]: 'C (heavy trucks)',
  [DriverLicenseCategory.D1]: 'D1 (minibuses/small minivans)',
  [DriverLicenseCategory.D]: 'D (buses/big minivans/9 or more seats)'
};

export const DRIVER_ID_CATEGORIES = Object.keys(driverIdCategoryToLabelMap).map(
  (value) => ({
    value,
    label: (driverIdCategoryToLabelMap as any)[value] as DriverLicenseCategory
  })
);

export const MIN_DAILY_PRICE_DEFAULT = 15;

export type CurrencyItem = {
  code: string;
  displayName: string;
  minDailyPriceForRent: number;
  iconSrc: string;
  symbol: string;
  isSuggested?: boolean;
  roundToInteger?: boolean;
};

export const IN_APP_CURRENCIES: CurrencyItem[] = [
  {
    code: 'PHP',
    displayName: 'Philippine Peso',
    minDailyPriceForRent: 150,
    iconSrc: 'https://flagcdn.com/ph.svg',
    symbol: '₱',
    isSuggested: true,
    roundToInteger: true
  },
  {
    code: 'EUR',
    displayName: 'Euro',
    minDailyPriceForRent: 2,
    iconSrc: 'https://flagcdn.com/eu.svg',
    symbol: '€',
    isSuggested: true
  },
  {
    code: 'USD',
    displayName: 'U.S. Dollar',
    minDailyPriceForRent: 2,
    iconSrc: 'https://flagcdn.com/us.svg',
    symbol: '$',
    isSuggested: true
  },
  {
    code: 'DKK',
    displayName: 'Danish Krone',
    minDailyPriceForRent: 20,
    iconSrc: 'https://flagcdn.com/dk.svg',
    symbol: 'DKK',
    roundToInteger: true
  },
  {
    code: 'SEK',
    displayName: 'Swedish Krone',
    minDailyPriceForRent: 30,
    iconSrc: 'https://flagcdn.com/se.svg',
    symbol: 'SEK',
    roundToInteger: true
  },
  {
    code: 'NOK',
    displayName: 'Norwegian Krone',
    minDailyPriceForRent: 30,
    iconSrc: 'https://flagcdn.com/no.svg',
    symbol: 'NOK',
    roundToInteger: true
  },
  {
    code: 'PKR',
    displayName: 'Pakistani Rupee',
    minDailyPriceForRent: 450,
    iconSrc: 'https://flagcdn.com/pk.svg',
    symbol: 'PKR',
    roundToInteger: true
  },
  {
    code: 'IDR',
    displayName: 'Indonesian Rupee',
    minDailyPriceForRent: 36000,
    iconSrc: 'https://flagcdn.com/id.svg',
    symbol: 'IDR',
    roundToInteger: true
  },
  {
    code: 'INR',
    displayName: 'Indian Rupee',
    minDailyPriceForRent: 200,
    iconSrc: 'https://flagcdn.com/in.svg',
    symbol: '₹',
    roundToInteger: true
  },
  {
    code: 'VND',
    displayName: 'Vietnamese Dong',
    minDailyPriceForRent: 60000,
    iconSrc: 'https://flagcdn.com/vn.svg',
    symbol: '₫',
    roundToInteger: true
  },
  {
    code: 'CNY',
    displayName: 'Chinese Renminbi',
    minDailyPriceForRent: 25,
    iconSrc: 'https://flagcdn.com/cn.svg',
    symbol: 'CN¥',
    roundToInteger: true
  },
  {
    code: 'THB',
    displayName: 'Thai Baht',
    minDailyPriceForRent: 100,
    iconSrc: 'https://flagcdn.com/th.svg',
    symbol: 'THB',
    roundToInteger: true
  },
  {
    code: 'GBP',
    displayName: 'British Pound',
    minDailyPriceForRent: 2,
    iconSrc: 'https://flagcdn.com/gb.svg',
    symbol: '£'
  },
  {
    code: 'COP',
    displayName: 'Colombian Peso',
    minDailyPriceForRent: 9150,
    iconSrc: 'https://flagcdn.com/co.svg',
    symbol: 'COP',
    roundToInteger: true
  }
];

export const IN_APP_CURRENCY_CODES = IN_APP_CURRENCIES.map((c) => c.code);
export const NO_CURRENCY_PLACEHOLDER: CurrencyItem = IN_APP_CURRENCIES[0];

export const VEHICLE_FEATURES: VehicleFeature[] = [
  'air_conditioning',
  'bluetooth',
  'cruise_control',
  'heated_seats',
  'parking_sensor',
  'winter_tires'
];

export const VEHICLE_CAR_FEATURES: VehicleFeature[] = VEHICLE_FEATURES;
export const VEHICLE_MOTO_FEATURES: VehicleFeature[] = [
  'cruise_control',
  'winter_tires'
];
