import { useSetAlert } from '@/context/alert.context';
import { useProfile } from '@/context/profile.context';
import { useSetUser, useUser } from '@/context/user.context';
import { authService } from '@/lib/services/auth.service';
import { presenceService } from '@/lib/services/presence.service';
import { PrivateProfileDTO } from '@b2w/shared/types';
import { useState } from 'react';

export const useSignOut = () => {
  const setAlert = useSetAlert();
  const setAuthUser = useSetUser();
  const { mutateProfile } = useProfile();
  const { user } = useUser();
  const [isSigningOut, setIsSigningOut] = useState(false);

  const signOut = async () => {
    if (!user) return;

    try {
      setIsSigningOut(true);

      const connectionId = presenceService.getCurrentConnectionId();
      await authService.signOut(user.uid, connectionId);

      setAuthUser();
      mutateProfile<PrivateProfileDTO>(null, false);
      setAlert('Signed out', 'warning');
    } catch (err) {
      setAlert(err.message, 'danger');
    } finally {
      setIsSigningOut(false);
    }
  };

  return {
    isSigningOut,
    signOut
  };
};
