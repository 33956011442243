import { forwardRef } from 'react';
import { twCx } from '../twMerge';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import { Spinner } from '../Spinner';
import { useButtonGroupCtx } from './button-group.ctx';
import { IconSize } from '../Icon';

export type ButtonColorScheme =
  | 'main'
  | 'dark'
  | 'error'
  | 'warning'
  | 'neutral'
  | 'inherit'
  | 'unstyled';
export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

const colorSchemes: ButtonColorScheme[] = [
  'main',
  'dark',
  'error',
  'warning',
  'neutral',
  'inherit',
  'unstyled'
];
const variants: ButtonVariant[] = ['solid', 'outline', 'ghost', 'link'];
const sizes: ButtonSize[] = ['xs', 'sm', 'md', 'lg'];

export const buttonDefinition = {
  colorSchemes,
  variants,
  sizes
};

const solidBase = 'tw-border tw-border-transparent';

const solidColorScheme: Record<ButtonColorScheme, string> = {
  main: twCx(
    solidBase,
    'tw-text-white tw-outline-main-500 tw-bg-main-500 hover:tw-bg-main-600 active:[&:not(:disabled)]:tw-bg-main-700'
  ),
  dark: twCx(
    solidBase,
    'tw-text-white tw-outline-dark-500 tw-bg-dark-500 hover:tw-bg-dark-600 active:[&:not(:disabled)]:tw-bg-dark-700'
  ),
  warning: twCx(
    solidBase,
    'tw-text-white tw-outline-warning-500 tw-bg-warning-500 hover:tw-bg-warning-600 active:[&:not(:disabled)]:tw-bg-warning-700'
  ),
  error: twCx(
    solidBase,
    'tw-text-white tw-outline-error-500 tw-bg-error-500 hover:tw-bg-error-600 active:[&:not(:disabled)]:tw-bg-error-700'
  ),
  neutral: twCx(
    solidBase,
    'tw-text-gray-700 tw-outline-gray-500 tw-bg-gray-100 hover:tw-bg-gray-200 active:[&:not(:disabled)]:tw-bg-gray-300'
  ),
  inherit: twCx(
    solidBase,
    'tw-text-inherit tw-outline-current tw-bg-inherit hover:tw-bg-dark-200/10 active:[&:not(:disabled)]:tw-bg-dark-400/10'
  ),
  unstyled: ''
};

const outlineBase = 'tw-border';

const outlineColorScheme: Record<ButtonColorScheme, string> = {
  main: twCx(
    outlineBase,
    'tw-text-main-600 hover:tw-text-main-700 tw-border-main-400 hover:tw-border-main-500 tw-outline-main-500 hover:tw-bg-main-50 active:[&:not(:disabled)]:tw-bg-main-100'
  ),
  dark: twCx(
    outlineBase,
    'tw-text-dark-600 hover:tw-text-dark-700 tw-border-dark-400 hover:tw-border-dark-500 tw-outline-dark-500 hover:tw-bg-dark-50 active:[&:not(:disabled)]:tw-bg-dark-100'
  ),
  warning: twCx(
    outlineBase,
    'tw-text-warning-600 hover:tw-text-warning-700 tw-border-warning-400 hover:tw-border-warning-500 tw-outline-warning-500 hover:tw-bg-warning-50 active:[&:not(:disabled)]:tw-bg-warning-100'
  ),
  error: twCx(
    outlineBase,
    'tw-text-error-600 hover:tw-text-error-700 tw-border-error-400 hover:tw-border-error-500 tw-outline-error-500 hover:tw-bg-error-50 active:[&:not(:disabled)]:tw-bg-error-100'
  ),
  neutral: twCx(
    outlineBase,
    'tw-text-gray-600 hover:tw-text-gray-700 tw-border-gray-300 hover:tw-border-gray-400 tw-outline-gray-500 hover:tw-bg-gray-50 active:[&:not(:disabled)]:tw-bg-gray-100'
  ),
  inherit: twCx(
    outlineBase,
    'tw-text-inherit tw-border-inherit tw-outline-current hover:tw-bg-dark-200/10 active:[&:not(:disabled)]:tw-bg-dark-400/10'
  ),
  unstyled: ''
};

const ghostBase = 'tw-border tw-border-transparent';

const ghostColorScheme: Record<ButtonColorScheme, string> = {
  main: twCx(
    ghostBase,
    'tw-text-main-600 hover:tw-text-main-700 tw-outline-main-500 hover:tw-bg-main-100 active:[&:not(:disabled)]:tw-bg-main-200'
  ),
  dark: twCx(
    ghostBase,
    'tw-text-dark-600 hover:tw-text-dark-700 tw-outline-dark-500 hover:tw-bg-dark-100 active:[&:not(:disabled)]:tw-bg-dark-200'
  ),
  warning: twCx(
    ghostBase,
    'tw-text-warning-600 hover:tw-text-warning-700 tw-outline-warning-500 hover:tw-bg-warning-100 active:[&:not(:disabled)]:tw-bg-warning-200'
  ),
  error: twCx(
    ghostBase,
    'tw-text-error-600 hover:tw-text-error-700 tw-outline-error-500 hover:tw-bg-error-100 active:[&:not(:disabled)]:tw-bg-error-200'
  ),
  neutral: twCx(
    ghostBase,
    'tw-text-gray-600 hover:tw-text-gray-700 tw-outline-gray-500 hover:tw-bg-gray-100 active:[&:not(:disabled)]:tw-bg-gray-200'
  ),
  inherit: twCx(
    ghostBase,
    'tw-text-inherit tw-outline-current hover:tw-bg-dark-200/10 active:[&:not(:disabled)]:tw-bg-dark-400/10'
  ),
  unstyled: ''
};

const linkBase =
  'tw-border-transparent tw-bg-transparent tw-outline-current tw-leading-normal';

const linkColorScheme: Record<ButtonColorScheme, string> = {
  main: twCx(
    linkBase,
    'tw-text-main-500 active:[&:not(:disabled)]:tw-text-main-700'
  ),
  dark: twCx(
    linkBase,
    'tw-text-dark-500 active:[&:not(:disabled)]:tw-text-dark-700'
  ),
  warning: twCx(
    linkBase,
    'tw-text-warning-500 active:[&:not(:disabled)]:tw-text-warning-700'
  ),
  error: twCx(
    linkBase,
    'tw-text-error-500 active:[&:not(:disabled)]:tw-text-error-700'
  ),
  neutral: twCx(
    linkBase,
    'tw-text-gray-500 active:[&:not(:disabled)]:tw-text-gray-700'
  ),
  inherit: twCx(
    linkBase,
    'tw-text-inherit active:[&:not(:disabled)]:tw-text-dark-700'
  ),
  unstyled: ''
};

const variantToClassName: Record<ButtonVariant, typeof solidColorScheme> = {
  solid: solidColorScheme,
  outline: outlineColorScheme,
  ghost: ghostColorScheme,
  link: linkColorScheme
};

const sizeToFontsizeClassName: Record<ButtonSize, string> = {
  xs: 'tw-text-xs/normal',
  sm: 'tw-text-sm/normal',
  md: 'tw-text-base/normal',
  lg: 'tw-text-xl/normal'
};
const sizeToPaddingClassName: Record<ButtonSize, string> = {
  xs: 'tw-py-1.5 tw-px-3',
  sm: 'tw-py-1.5 tw-px-3.5',
  md: 'tw-py-2 tw-px-4',
  lg: 'tw-py-2.5 tw-px-5'
};

export const buttonSizeToIconSize: Record<ButtonSize, IconSize> = {
  xs: 'sm',
  sm: 'md',
  md: 'lg',
  lg: 'xl'
};

export type ButtonOwnProps = {
  /** @default 'solid' */
  variant?: ButtonVariant;
  /**
   * - `inherit` will inherit colors from parent.
   * Can be used as a generic button which adjusts to parent.
   * - `unstyled` does not provide any colors.
   *
   * @default 'main'
   */
  colorScheme?: ButtonColorScheme;
  /** @default 'md' */
  size?: ButtonSize;
  block?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  /** Use callback to get correctly adjusted icon size to button size  */
  leftIcon?: JSX.Element | ((iconSize: IconSize) => JSX.Element);
  /** Use callback to get correctly adjusted icon size to button size  */
  rightIcon?: JSX.Element | ((iconSize: IconSize) => JSX.Element);
};

export type ButtonProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropsWithRef<C, ButtonOwnProps>;

type ButtonComponent = <C extends React.ElementType = 'button'>(
  props: ButtonProps<C>
) => JSX.Element;

export const Button: ButtonComponent = forwardRef(
  <T extends React.ElementType = 'button'>(
    props: PolymorphicComponentProps<T, ButtonOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const btnGroupCtx = useButtonGroupCtx();

    const {
      as: Component = 'button',
      variant = btnGroupCtx?.variant ?? 'solid',
      colorScheme = btnGroupCtx?.colorScheme ?? 'main',
      size = btnGroupCtx?.size ?? 'md',
      block = false,
      isLoading = false,
      loadingText = '',
      type: typeProp,
      disabled: disabledProp,
      leftIcon,
      rightIcon,
      children,
      ...otherProps
    } = props;

    const disabled = isLoading || (disabledProp ?? !!btnGroupCtx?.disabled);
    const type: PolymorphicComponentProps<'button'>['type'] =
      !typeProp && Component === 'button' ? 'button' : typeProp;

    const mustUnmountIcons = isLoading && loadingText;
    const applyTransparentToIcons = isLoading && !loadingText;

    const className = twCx(
      'tw-select-none tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-xl tw-max-w-full disabled:tw-cursor-not-allowed disabled:tw-opacity-75 tw-transition-colors tw-ease-in-out tw-duration-100 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2',
      variantToClassName[variant][colorScheme],
      sizeToFontsizeClassName[size],
      variant !== 'link' && sizeToPaddingClassName[size],
      block && 'tw-w-full',
      otherProps.className
    );

    return (
      <Component
        {...otherProps}
        ref={ref}
        type={type}
        disabled={disabled}
        className={className}
      >
        {leftIcon && !mustUnmountIcons && (
          <span
            className={twCx(
              'tw-flex tw-mr-2',
              applyTransparentToIcons && 'tw-text-transparent'
            )}
          >
            {typeof leftIcon === 'function'
              ? leftIcon(buttonSizeToIconSize[size])
              : leftIcon}
          </span>
        )}
        {isLoading ? (
          <>
            <span
              className={twCx(
                'tw-flex',
                loadingText
                  ? 'tw-mr-2'
                  : 'tw-absolute -tw-translate-x-1/2 -tw-translate-y-1/2 tw-top-1/2 tw-left-1/2'
              )}
            >
              <Spinner size={buttonSizeToIconSize[size]} />
            </span>
            {loadingText || (
              <span className="tw-text-transparent tw-flex">{children}</span>
            )}
          </>
        ) : (
          children
        )}
        {rightIcon && !mustUnmountIcons && (
          <span
            className={twCx(
              'tw-flex tw-ml-2',
              applyTransparentToIcons && 'tw-text-transparent'
            )}
          >
            {typeof rightIcon === 'function'
              ? rightIcon(buttonSizeToIconSize[size])
              : rightIcon}
          </span>
        )}
      </Component>
    );
  }
) as ButtonComponent;
