import { theme } from '@/styles/sc-theme';
import AppLink from '@/components/AppLink';
import React from 'react';
import { Button } from 'reactstrap';
import LinkToPrivate from '../LinkToPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DescItem = ({
  title,
  text,
  ballToLeftOnDesktop = true,
  className = '',
  titleClassname = '',
  href = ''
}) => {
  const markup = (
    <>
      <h3 className={`h5 text-primary ${titleClassname}`}>{title}</h3>
      <p className="text-secondary-dark">{text}</p>
    </>
  );

  return (
    <div className={className}>
      <style jsx>
        {`
          .offer-title::before {
            margin-right: 5px;
            display: inline-block;
          }
          .offer-title::after {
            margin-left: 5px;
            display: none;
          }
          .offer-title::before,
          .offer-title::after {
            margin-bottom: 2px;
            content: '';
            width: 10px;
            height: 10px;
            background: ${theme.palette.primary.main};
            border-radius: 50%;
          }

          @media screen and (min-width: 992px) {
            .offer-title::before {
              display: ${ballToLeftOnDesktop ? 'inline-block' : 'none'};
            }
            .offer-title::after {
              display: ${ballToLeftOnDesktop ? 'none' : 'inline-block'};
            }
          }
        `}
      </style>
      {href ? <AppLink href={href}>{markup}</AppLink> : markup}
    </div>
  );
};

const Description = () => {
  return (
    <div>
      <ul className="mb-4 mb-sm-5">
        <style jsx>
          {`
            @media screen and (min-width: 992px) {
              li.pr-half {
                padding-right: 50%;
              }
              li.pl-half {
                padding-left: 50%;
              }
            }
          `}
        </style>
        <li className="mb-4 pr-half">
          <DescItem
            title="Variety of vehicles"
            text="Whether you are looking for a fully automatic scooter, an adventure-touring motorbike, or a spacious family car, you are in the right place!"
            className="pr-lg-4"
          />
        </li>
        <li className="mb-4 text-lg-right pl-half">
          <DescItem
            title="Easy. Flexible. Safe."
            text="With our hassle-free and secure platform, renting and sharing your vehicle at any time is as easy as can be."
            ballToLeftOnDesktop={false}
            className="pl-lg-4"
          />
        </li>

        <li className="mb-4 pr-half">
          <DescItem
            title="Affordable auto insurance"
            text={
              <>
                Drive with a peace of mind by choosing one of our reliable
                rental insurance options{' '}
                <FontAwesomeIcon
                  icon="external-link-alt"
                  size="xs"
                  fixedWidth
                />
              </>
            }
            className="pr-lg-4"
            href="/insurance"
          />
        </li>
      </ul>

      <div className="d-flex flex-column flex-sm-row align-items-sm-end justify-content-sm-between justify-content-lg-around">
        <LinkToPrivate href="/dashboard/vehicles/add" passHref legacyBehavior>
          <Button
            color="primary"
            className="bg-white btn-desc text-primary shadow-sm rounded-50 py-1 btn-lg-lg mb-2 mb-sm-0 order-1 order-sm-0 mw-100"
          >
            Become a host
          </Button>
        </LinkToPrivate>
        <div className="text-center order-0 order-sm-1 mb-3 mb-sm-0 mx-sm-4">
          <img
            src="/images/landing/single-pin.svg"
            alt="pin"
            className="tw-mx-auto"
            style={{ width: 45, height: 'auto' }}
          />
        </div>
        <AppLink href="/search" passHref legacyBehavior>
          <Button
            color="primary"
            className="shadow-sm btn-desc rounded-50 py-1 btn-lg-lg order-2 mw-100"
          >
            Get a vehicle
          </Button>
        </AppLink>
      </div>
    </div>
  );
};

export default Description;
