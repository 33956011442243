import { createIcon } from '../createIcon';

export const IconViber = {
  Outline: createIcon({
    viewBox: '0 0 21 22',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path d="M18.1017 2.14584C17.5671 1.64139 15.4064 0.0375802 10.5937 0.0157911C10.5937 0.0157911 4.91831 -0.334155 2.15164 2.26073C0.611554 3.83582 0.0697789 6.14051 0.012631 8.99785C-0.0445169 11.8552 -0.118455 17.21 4.92961 18.662H4.93446L4.93123 20.8775C4.93123 20.8775 4.89894 21.7745 5.47655 21.9574C6.17524 22.1793 6.58529 21.4975 7.25234 20.7627C7.61847 20.3592 8.12409 19.7666 8.50507 19.3137C11.9575 19.6108 14.6128 18.9317 14.9144 18.8313C15.6114 18.6002 19.5559 18.0833 20.1975 12.7291C20.8597 7.21016 19.8772 3.71928 18.1017 2.14584ZM18.6868 12.3332C18.1453 16.804 14.946 17.0849 14.3561 17.2784C14.1052 17.3609 11.7732 17.9542 8.84053 17.7584C8.84053 17.7584 6.65535 20.454 5.9728 21.1549C5.86626 21.2645 5.74098 21.3087 5.65736 21.2869C5.53984 21.2575 5.50755 21.1152 5.50884 20.9073C5.51078 20.6101 5.52757 17.2256 5.52757 17.2256C5.52757 17.2256 5.52498 17.2256 5.52757 17.2256C1.25729 16.0133 1.50623 11.4557 1.55466 9.06916C1.60309 6.6826 2.04154 4.72752 3.344 3.41258C5.68416 1.24522 10.5049 1.56909 10.5049 1.56909C14.576 1.58725 16.5268 2.84078 16.9791 3.26072C18.4811 4.57566 19.2463 7.7222 18.6868 12.3319V12.3332Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6704 7.13634C10.6784 6.98428 10.8081 6.86745 10.9602 6.87538C11.5294 6.90506 12.0029 7.07727 12.3401 7.42581C12.6761 7.77303 12.8408 8.25809 12.8697 8.84063C12.8772 8.99271 12.76 9.12211 12.6079 9.12966C12.4557 9.1372 12.3263 9.02003 12.3187 8.86795C12.294 8.36928 12.1571 8.0298 11.9436 7.80915C11.7314 7.58984 11.4076 7.45086 10.9315 7.42603C10.7794 7.4181 10.6625 7.2884 10.6704 7.13634Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2858 5.6472C10.2967 5.49439 10.4264 5.37958 10.5755 5.39077C11.7124 5.47603 12.6274 5.87408 13.2939 6.61967L13.294 6.61977C13.9533 7.35802 14.2666 8.2764 14.2439 9.3536C14.2406 9.50678 14.1168 9.62826 13.9674 9.62495C13.8179 9.62163 13.6993 9.49477 13.7026 9.34159C13.7226 8.39163 13.45 7.61633 12.8946 6.99436C12.3408 6.37496 11.5667 6.02143 10.536 5.94412C10.3869 5.93294 10.2749 5.8 10.2858 5.6472Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.78998 4.23423C9.79103 4.08173 9.91364 3.95897 10.0638 3.96003C11.6421 3.97119 12.9761 4.50466 14.0472 5.56483C15.1273 6.63385 15.6614 8.08924 15.675 9.89679C15.6761 10.0493 15.5553 10.1739 15.4051 10.175C15.2549 10.1762 15.1322 10.0535 15.1311 9.901C15.1184 8.20722 14.6221 6.90504 13.6676 5.96027C12.7041 5.00664 11.5078 4.52251 10.06 4.51227C9.90985 4.51121 9.78894 4.38672 9.78998 4.23423Z"
        />
        <path d="M10.9937 12.5919C10.9937 12.5919 11.3748 12.6249 11.58 12.3657L11.98 11.8496C12.1729 11.5938 12.6386 11.4305 13.0946 11.691C13.4363 11.8909 13.768 12.1081 14.0886 12.342C14.3912 12.5701 15.0108 13.1004 15.0128 13.1004C15.308 13.3559 15.3761 13.7312 15.1752 14.1269C15.1752 14.1292 15.1735 14.1332 15.1735 14.1351C14.9522 14.5286 14.6712 14.8835 14.341 15.1864C14.3371 15.1883 14.3371 15.1903 14.3336 15.1923C14.0468 15.4381 13.7649 15.5778 13.4882 15.6114C13.4474 15.6187 13.406 15.6214 13.3647 15.6193C13.2427 15.6205 13.1212 15.6014 13.0052 15.5626L12.9962 15.5491C12.5698 15.4258 11.8578 15.1171 10.6722 14.4464C9.98605 14.0629 9.33285 13.6204 8.71957 13.1238C8.41217 12.875 8.11895 12.6084 7.84135 12.3251L7.81177 12.2948L7.78218 12.2645L7.7526 12.2341C7.74263 12.2242 7.73298 12.214 7.72301 12.2038C7.44681 11.9191 7.18676 11.6185 6.94416 11.3032C6.45993 10.6744 6.02845 10.0047 5.65433 9.30134C5.00025 8.08522 4.69926 7.35582 4.57899 6.91791L4.5658 6.90868C4.5281 6.78966 4.50953 6.66515 4.51081 6.54002C4.50856 6.49766 4.51104 6.45517 4.51821 6.41339C4.55273 6.13003 4.68918 5.84084 4.92757 5.54582C4.9295 5.5422 4.93143 5.5422 4.93336 5.53824C5.22866 5.19969 5.57473 4.91161 5.95854 4.68485C5.96047 4.68485 5.96433 4.68287 5.96658 4.68287C6.35246 4.47678 6.71842 4.54668 6.96731 4.84774C6.96924 4.84972 7.48537 5.48515 7.70693 5.79544C7.935 6.12445 8.14686 6.46495 8.34172 6.81569C8.59576 7.28294 8.43658 7.76141 8.18704 7.9586L7.68378 8.36881C7.42974 8.57919 7.46318 8.96994 7.46318 8.96994C7.46318 8.96994 8.20859 11.8628 10.9937 12.5919Z" />
      </>
    )
  }),
  OutlineColored: createIcon({
    viewBox: '0 0 21 22',
    pathOrChildren: (
      <>
        <path
          d="M18.1017 2.14584C17.5671 1.64139 15.4064 0.0375802 10.5937 0.0157911C10.5937 0.0157911 4.91831 -0.334155 2.15164 2.26073C0.611554 3.83582 0.0697789 6.14051 0.012631 8.99785C-0.0445169 11.8552 -0.118455 17.21 4.92961 18.662H4.93446L4.93123 20.8775C4.93123 20.8775 4.89894 21.7745 5.47655 21.9574C6.17524 22.1793 6.58529 21.4975 7.25234 20.7627C7.61847 20.3592 8.12409 19.7666 8.50507 19.3137C11.9575 19.6108 14.6128 18.9317 14.9144 18.8313C15.6114 18.6002 19.5559 18.0833 20.1975 12.7291C20.8597 7.21016 19.8772 3.71928 18.1017 2.14584ZM18.6868 12.3332C18.1453 16.804 14.946 17.0849 14.3561 17.2784C14.1052 17.3609 11.7732 17.9542 8.84053 17.7584C8.84053 17.7584 6.65535 20.454 5.9728 21.1549C5.86626 21.2645 5.74098 21.3087 5.65736 21.2869C5.53984 21.2575 5.50755 21.1152 5.50884 20.9073C5.51078 20.6101 5.52757 17.2256 5.52757 17.2256C5.52757 17.2256 5.52498 17.2256 5.52757 17.2256C1.25729 16.0133 1.50623 11.4557 1.55466 9.06916C1.60309 6.6826 2.04154 4.72752 3.344 3.41258C5.68416 1.24522 10.5049 1.56909 10.5049 1.56909C14.576 1.58725 16.5268 2.84078 16.9791 3.26072C18.4811 4.57566 19.2463 7.7222 18.6868 12.3319V12.3332Z"
          fill="#7360F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6704 7.13634C10.6784 6.98428 10.8081 6.86745 10.9602 6.87538C11.5294 6.90506 12.0029 7.07727 12.3401 7.42581C12.6761 7.77303 12.8408 8.25809 12.8697 8.84063C12.8772 8.99271 12.76 9.12211 12.6079 9.12966C12.4557 9.1372 12.3263 9.02003 12.3187 8.86795C12.294 8.36928 12.1571 8.0298 11.9436 7.80915C11.7314 7.58984 11.4076 7.45086 10.9315 7.42603C10.7794 7.4181 10.6625 7.2884 10.6704 7.13634Z"
          fill="#7360F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2858 5.6472C10.2967 5.49439 10.4264 5.37958 10.5755 5.39077C11.7124 5.47603 12.6274 5.87408 13.2939 6.61967L13.294 6.61977C13.9533 7.35802 14.2666 8.2764 14.2439 9.3536C14.2406 9.50678 14.1168 9.62826 13.9674 9.62495C13.8179 9.62163 13.6993 9.49477 13.7026 9.34159C13.7226 8.39163 13.45 7.61633 12.8946 6.99436C12.3408 6.37496 11.5667 6.02143 10.536 5.94412C10.3869 5.93294 10.2749 5.8 10.2858 5.6472Z"
          fill="#7360F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.78998 4.23423C9.79103 4.08173 9.91364 3.95897 10.0638 3.96003C11.6421 3.97119 12.9761 4.50466 14.0472 5.56483C15.1273 6.63385 15.6614 8.08924 15.675 9.89679C15.6761 10.0493 15.5553 10.1739 15.4051 10.175C15.2549 10.1762 15.1322 10.0535 15.1311 9.901C15.1184 8.20722 14.6221 6.90504 13.6676 5.96027C12.7041 5.00664 11.5078 4.52251 10.06 4.51227C9.90985 4.51121 9.78894 4.38672 9.78998 4.23423Z"
          fill="#7360F2"
        />
        <path
          d="M10.9937 12.5919C10.9937 12.5919 11.3748 12.6249 11.58 12.3657L11.98 11.8496C12.1729 11.5938 12.6386 11.4305 13.0946 11.691C13.4363 11.8909 13.768 12.1081 14.0886 12.342C14.3912 12.5701 15.0108 13.1004 15.0128 13.1004C15.308 13.3559 15.3761 13.7312 15.1752 14.1269C15.1752 14.1292 15.1735 14.1332 15.1735 14.1351C14.9522 14.5286 14.6712 14.8835 14.341 15.1864C14.3371 15.1883 14.3371 15.1903 14.3336 15.1923C14.0468 15.4381 13.7649 15.5778 13.4882 15.6114C13.4474 15.6187 13.406 15.6214 13.3647 15.6193C13.2427 15.6205 13.1212 15.6014 13.0052 15.5626L12.9962 15.5491C12.5698 15.4258 11.8578 15.1171 10.6722 14.4464C9.98605 14.0629 9.33285 13.6204 8.71957 13.1238C8.41217 12.875 8.11895 12.6084 7.84135 12.3251L7.81177 12.2948L7.78218 12.2645L7.7526 12.2341C7.74263 12.2242 7.73298 12.214 7.72301 12.2038C7.44681 11.9191 7.18676 11.6185 6.94416 11.3032C6.45993 10.6744 6.02845 10.0047 5.65433 9.30134C5.00025 8.08522 4.69926 7.35582 4.57899 6.91791L4.5658 6.90868C4.5281 6.78966 4.50953 6.66515 4.51081 6.54002C4.50856 6.49766 4.51104 6.45517 4.51821 6.41339C4.55273 6.13003 4.68918 5.84084 4.92757 5.54582C4.9295 5.5422 4.93143 5.5422 4.93336 5.53824C5.22866 5.19969 5.57473 4.91161 5.95854 4.68485C5.96047 4.68485 5.96433 4.68287 5.96658 4.68287C6.35246 4.47678 6.71842 4.54668 6.96731 4.84774C6.96924 4.84972 7.48537 5.48515 7.70693 5.79544C7.935 6.12445 8.14686 6.46495 8.34172 6.81569C8.59576 7.28294 8.43658 7.76141 8.18704 7.9586L7.68378 8.36881C7.42974 8.57919 7.46318 8.96994 7.46318 8.96994C7.46318 8.96994 8.20859 11.8628 10.9937 12.5919Z"
          fill="#7360F2"
        />
      </>
    )
  })
};
