import { createIcon } from '../createIcon';

export const IconClipboardList = {
  Outline: createIcon({
    pathOrChildren: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.8823 0C27.145 0 24.4961 2.93945 24.4961 7.64941C24.4961 9.33594 24.4917 10.9438 24.4863 12.2983C24.4775 14.4341 21.7422 16.1558 19.6064 16.1558H15.041C14.9434 16.1558 14.8477 16.1621 14.7534 16.1733C14.6362 16.187 14.5181 16.1982 14.3999 16.1982C13.2041 16.1982 12.2349 17.1675 12.2349 18.3633V20.4653C12.2349 22.3989 13.6465 22.3818 15.4951 22.3589C15.6997 22.3564 15.9097 22.354 16.1235 22.354H47.6411L48.0117 22.356L48.2695 22.3589C50.1182 22.3818 51.5298 22.3989 51.5298 20.4653V18.3633C51.5298 17.1675 50.5605 16.1982 49.3647 16.1982L49.3003 16.1973L49.2505 16.1953C49.1704 16.1909 49.0908 16.1826 49.0112 16.1733C48.917 16.1621 48.8213 16.1558 48.7236 16.1558H44.2944C42.106 16.1558 39.3447 14.3506 39.3701 12.1626C39.3853 10.8818 39.3965 9.35547 39.3965 7.67578C39.3965 3.13916 36.6201 0 31.8823 0ZM31.8823 5.37891C33.4346 5.37891 34.6885 6.62354 34.6885 8.16455C34.6885 9.7085 33.4346 10.9561 31.8823 10.9561C30.3301 10.9561 29.0762 9.7085 29.0762 8.16455C29.0762 6.62354 30.3301 5.37891 31.8823 5.37891Z"
        />
        <path d="M4.88867 7.27295C2.74121 7.27295 0 9.01367 0 11.1616V65.1113C0 67.2588 2.74121 70 4.88867 70H58.876C61.0234 70 63.7646 67.2588 63.7646 65.1113V11.1616C63.7646 9.01367 61.0234 7.27295 58.876 7.27295H48.3203C46.7466 7.27295 45.4707 8.54834 45.4707 10.1226C45.4707 11.6963 46.7466 12.9717 48.3203 12.9717H54.2212C56.3691 12.9717 58.1104 13.7129 58.1104 15.8608V60.0791C58.1104 62.2271 56.3691 63.9683 54.2212 63.9683H9.54883C7.40137 63.9683 5.66016 62.2271 5.66016 60.0791V15.8608C5.66016 13.7129 7.40137 12.9717 9.54883 12.9717H15.7534C17.3271 12.9717 18.603 11.6963 18.603 10.1226C18.603 8.54834 17.3271 7.27295 15.7534 7.27295H4.88867Z" />
        <path d="M21 35C21 34.4478 21.4478 34 22 34H51C51.0845 34 51.1665 34.0103 51.2451 34.0298C51.6787 34.1392 52 34.5322 52 35V37C52 37.5522 51.5522 38 51 38H22C21.7383 38 21.5 37.8994 21.3218 37.7349C21.124 37.5518 21 37.2905 21 37V35Z" />
        <path d="M22 48C21.4478 48 21 48.4478 21 49V51C21 51.5522 21.4478 52 22 52H51C51.5522 52 52 51.5522 52 51V49C52 48.8936 51.9834 48.791 51.9526 48.6948C51.9248 48.6084 51.8853 48.5269 51.8364 48.4521C51.6577 48.1797 51.3501 48 51 48H22Z" />
        <path d="M18 36C18 37.6567 16.6567 39 15 39C13.3433 39 12 37.6567 12 36C12 34.3433 13.3433 33 15 33C16.6567 33 18 34.3433 18 36Z" />
        <path d="M15 53C16.6567 53 18 51.6567 18 50C18 48.3433 16.6567 47 15 47C13.3433 47 12 48.3433 12 50C12 51.6567 13.3433 53 15 53Z" />
      </>
    ),
    viewBox: '0 0 64 70',
    fill: 'currentColor'
  })
};
