import { createIcon } from '../createIcon';

export const IconMoped = {
  Outline: createIcon({
    viewBox: '0 0 70 48',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6051 4.3201e-10C36.9483 -2.78033e-05 35.6051 1.3431 35.6051 2.99995C35.6051 4.6568 36.9482 5.99997 38.605 6L43.5515 6.00008C43.9864 6.00009 44.3714 6.28117 44.5039 6.69538L49.4198 22.0618C45.2135 23.7893 41.8092 27.1652 39.8859 31.7648H32V19.8622C32 17.047 29.738 14.7648 26.9477 14.7648H17.9058C8.01669 14.7648 0 22.8531 0 32.8305C0 35.5557 2.18964 37.7648 4.89068 37.7648H41C41.1545 37.7648 41.305 37.7473 41.4495 37.7141C42.9436 37.9949 44.4373 37.104 44.8772 35.6145C46.2484 30.9711 49.407 28.1038 53.1242 27.137C56.8653 26.164 60.5765 26.0216 65.1079 29.7044C66.3937 30.7494 68.2831 30.5541 69.3281 29.2684C70.3731 27.9826 70.1778 26.0932 68.8921 25.0482C64.3195 21.332 59.8394 20.2165 55.2721 20.6635L52.4298 11.779L53.2345 12.0584C55.401 12.8106 57.5761 10.9524 57.1714 8.69492L56.8833 7.08789C56.4786 4.83046 53.7934 3.84358 52.0231 5.30164L50.736 6.36174L50.705 6.38761L50.2186 4.86718C49.291 1.96769 46.5958 0.000133042 43.5516 8.18984e-05L38.6051 4.3201e-10ZM25.9372 31.6479H6.12002C6.70854 25.6039 11.7606 20.8817 17.9058 20.8817H25.9372V31.6479Z"
        />
        <path d="M12 9.76465C12 11.4215 13.3431 12.7646 15 12.7646H28C29.6569 12.7646 31 11.4215 31 9.76465C31 8.10779 29.6569 6.76465 28 6.76465H15C13.3431 6.76465 12 8.10779 12 9.76465Z" />
        <path d="M56.8212 47.8852C61.8515 47.8852 65.9295 43.8073 65.9295 38.7769C65.9295 33.7466 61.8515 29.6686 56.8212 29.6686C51.7908 29.6686 47.7129 33.7466 47.7129 38.7769C47.7129 43.8073 51.7908 47.8852 56.8212 47.8852Z" />
        <path d="M19.5605 47.8854C15.2129 47.8854 11.5771 44.839 10.6699 40.7648H28.4512C27.5439 44.839 23.9082 47.8854 19.5605 47.8854Z" />
      </>
    )
  })
};

export const IconMopedAdd = {
  Outline: createIcon({
    viewBox: '0 0 70 62',
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path d="M11.8462 8.63136C11.8462 7.73755 12.5694 7.01298 13.4615 7.01298H14.5385C15.4306 7.01298 16.1538 7.73755 16.1538 8.63136V11.3287C16.1538 11.6266 16.3949 11.8681 16.6923 11.8681H19.3846C20.2768 11.8681 21 12.5927 21 13.4865V14.5654C21 15.4592 20.2768 16.1838 19.3846 16.1838H16.6923C16.3949 16.1838 16.1538 16.4253 16.1538 16.7233V19.4206C16.1538 20.3144 15.4306 21.0389 14.5385 21.0389H13.4615C12.5694 21.0389 11.8462 20.3144 11.8462 19.4206V16.7233C11.8462 16.4253 11.6051 16.1838 11.3077 16.1838H8.61539C7.72323 16.1838 7 15.4592 7 14.5654V13.4865C7 12.5927 7.72323 11.8681 8.61539 11.8681H11.3077C11.6051 11.8681 11.8462 11.6266 11.8462 11.3287V8.63136Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2062 27.0501C17.5968 27.6964 15.8399 28.0519 14 28.0519C6.26801 28.0519 0 21.7723 0 14.026C0 6.27964 6.26801 0 14 0C21.732 0 28 6.27964 28 14.026C28 16.5807 27.3182 18.9759 26.1271 21.0389H29.0002C30.6571 21.0389 32.0002 22.3846 32.0002 24.0445C32.0002 25.7044 30.6571 27.0501 29.0002 27.0501H19.2062ZM24 14.026C24 19.5701 19.5159 24.0519 14 24.0519C8.48414 24.0519 4 19.5701 4 14.026C4 8.48178 8.48414 4 14 4C19.5159 4 24 8.48178 24 14.026Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6074 17.031C35.6084 15.3728 36.9513 14.0286 38.6068 14.0286L43.5571 14.0287C46.5977 14.0287 49.2887 15.9963 50.2147 18.8965L50.7033 20.4264L50.7269 20.4067L52.0216 19.3384C53.7918 17.8776 56.4783 18.8634 56.8825 21.1219L57.1723 22.7415C57.5764 25 55.3991 26.8594 53.2327 26.1057L52.4273 25.8256L55.27 34.7277C59.838 34.2794 64.3187 35.3967 68.8919 39.1203C70.1777 40.1672 70.3729 42.0602 69.3279 43.3483C68.2829 44.6365 66.3935 44.832 65.1077 43.7851C60.5763 40.0955 56.8651 40.2382 53.124 41.213C49.4068 42.1815 46.2482 45.0542 44.877 49.7062C44.4371 51.1984 42.9436 52.0909 41.4496 51.8098C41.3051 51.843 41.1546 51.8605 41 51.8605L4.89068 51.8605C2.18964 51.8605 0 49.6472 0 46.917C0 36.9211 8.01669 28.8179 17.9058 28.8179H26.9477C29.738 28.8179 32 31.1043 32 33.9247V45.8494H39.8857C41.8093 41.2405 45.2143 37.8581 49.4215 36.1275L44.5045 20.729C44.3722 20.3146 43.9877 20.0336 43.5534 20.0336L38.6031 20.0335C36.9476 20.0334 35.6063 18.6892 35.6074 17.031ZM25.9372 45.7323H6.12002C6.70854 39.6771 11.7606 34.9461 17.9058 34.9461H25.9372V45.7323Z"
        />
        <path d="M56.8212 61.9996C61.8515 61.9996 65.9295 57.9142 65.9295 52.8745C65.9295 47.8348 61.8515 43.7493 56.8212 43.7493C51.7908 43.7493 47.7129 47.8348 47.7129 52.8745C47.7129 57.9142 51.7908 61.9996 56.8212 61.9996Z" />
        <path d="M10.6699 54.8663C11.5771 58.948 15.2129 62.0001 19.5605 62.0001C23.9082 62.0001 27.5439 58.948 28.4512 54.8663H10.6699Z" />
      </>
    )
  })
};
