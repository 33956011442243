import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Fade } from 'reactstrap';
import { useToggler } from '@/custom-hooks/useToggler';
import DesktopNavs from './DesktopNavs';
import Hamburger from './Hamburger';
import AppLink from '@/components/AppLink';
import SCC from './Navbar.module.scss';
import { theme } from '@/styles/sc-theme';
import Head from 'next/head';
import MenuDrawer from './MenuDrawer';
import { useMediaQuery } from '@/custom-hooks/useMediaQuery';
import { throttle } from '@b2w/shared/utility';
import { twCx } from '@b2w/react-ui/core2';
import Router from 'next/router';
import { navbarSpace } from '../safeSpace';
import ChangeCurrencyBtn from '@/components/currency-changer/ChangeCurrencyBtn';
import BellIcon from '@/components/bell/BellIcon';

const noScrollBaseline_px = theme.navbarHeight * 1.1;

const useShowNavbar = (hideOnScrollMobile = true) => {
  const [showMenu, setShowMenu] = useState(true);
  const isMobileScreen = useMediaQuery('(max-width: 768px)');
  const prevOffsetY = useRef<number | undefined>();

  useEffect(() => {
    navbarSpace.set(showMenu ? '55px' : '0px');
  }, [showMenu]);

  useEffect(() => {
    if (!isMobileScreen || !hideOnScrollMobile) {
      setShowMenu(true);
      return;
    }

    const onRouteChange = () => {
      setShowMenu(true);
    };

    let timeout: any;

    const onScroll = throttle(() => {
      clearTimeout(timeout);

      const yOffset = window.pageYOffset;
      const prevYOffset = prevOffsetY.current;

      if (prevYOffset !== undefined && yOffset !== undefined) {
        if (yOffset >= noScrollBaseline_px && prevYOffset < yOffset) {
          // scroll down
          setShowMenu(false);
        } else {
          // scroll up
          setShowMenu(true);
        }
      }

      prevOffsetY.current = yOffset;
    }, 50);

    window.addEventListener('scroll', onScroll);
    Router.events.on('routeChangeComplete', onRouteChange);
    Router.events.on('beforeHistoryChange', onRouteChange);

    return () => {
      window.removeEventListener('scroll', onScroll);
      Router.events.off('routeChangeComplete', onRouteChange);
      Router.events.off('beforeHistoryChange', onRouteChange);
    };
  }, [isMobileScreen, hideOnScrollMobile]);

  return showMenu;
};

type NavbarProps = {
  hideOnScrollMobile?: boolean;
};

const Navbar = (props: NavbarProps) => {
  const [isDrawerOpened, toggleDrawer, setIsDrawerOpened] = useToggler(false);
  const navbarRef = useRef<HTMLDivElement>();

  const showNavbar = useShowNavbar(props.hideOnScrollMobile);

  const closeDrawer = useCallback(
    () => setIsDrawerOpened(false),
    [setIsDrawerOpened]
  );

  return (
    <div
      ref={navbarRef}
      className={twCx(SCC['wrapper'], !showNavbar && SCC.hide)}
      style={{ zIndex: theme.zIndex.navbar }}
    >
      <Head>
        <link rel="preload" as="image" href="/images/b2w-logo.png" />
      </Head>
      <Container>
        <div
          className={SCC['inner-wrapper']}
          style={{ height: theme.navbarHeight }}
        >
          <AppLink
            href="/"
            passHref
            onClick={closeDrawer}
            className="mt-auto mb-auto d-flex align-items-center"
            style={{ height: theme.navbarHeight - 25 }}
          >
            <img
              src="/images/b2w-logo.png"
              alt="logo"
              className="mh-100 mw-100"
            />
          </AppLink>
          <div className="d-lg-none d-flex align-items-center">
            <div className="mr-2 d-flex align-items-center">
              <Fade in>
                <ChangeCurrencyBtn />
              </Fade>
              <Fade in>
                <AppLink href="/bell-notifications">
                  <BellIcon />
                </AppLink>
              </Fade>
            </div>
            <Hamburger
              animate={true}
              isToggled={isDrawerOpened}
              onClick={toggleDrawer}
              color="primary-light"
            />
            <MenuDrawer
              navbarRef={navbarRef}
              isDrawerOpened={isDrawerOpened}
              closeDrawer={closeDrawer}
            />
          </div>
          <DesktopNavs />
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
