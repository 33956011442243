import { forwardRef, memo, useRef, useState } from 'react';
import { useControllableState } from '@b2w/shared/react-hooks';
import { mergeRefs } from '@b2w/shared/react-utils';
import { maskString } from '@b2w/shared/utility';
import { Input, InputProps } from '../Input';
import { ComponentPropsWithRef } from '../types';
import {
  CountryListItem,
  countryListPhoneMaskDictionary,
  useLoadCountryList
} from '../countryList';
import { Combobox, ComboboxProps } from '../Combobox';
import {
  SelectOption,
  SelectOptionProps,
  SelectValueEqualityFn
} from '../Select';
import { twCx } from '../twMerge';
import { Spinner } from '../Spinner';
import { IconChevronDown } from '../Icon';

export type PhoneInputOwnProps = Pick<
  ComboboxProps<string, false>,
  'popupStrategy'
> & {
  /**
   * Value in controlled mode.
   *
   * Should be provided in international format (plus sign and country code).
   *
   * @example
   * "+44123456789"
   */
  value?: string;
  /**
   * Initial `value` in uncontrolled mode.
   *
   * Should be provided in international format (plus sign and country code).
   *
   *  @example
   * "+86123456789"
   */
  defaultValue?: string;
  /**
   * `value` change handler in controlled mode.
   *
   * Changed value is provided in international format (plus sign and country code).
   *
   * @example
   * "+86123456789" (when dial code matched)
   * "9999999", "+77312" (when dial code unmatched or phone incompleted)
   */
  onChange?: (phoneString: string) => any;
};

export type PhoneInputProps = ComponentPropsWithRef<
  'input',
  Omit<InputProps, keyof PhoneInputOwnProps | 'as'> & PhoneInputOwnProps
>;

const ImgCountry = ({ isoCode }: { isoCode: string }) => {
  const codeLower = isoCode.toLowerCase();

  return (
    <img
      src={`https://flagcdn.com/w20/${codeLower}.png`}
      srcSet={`https://flagcdn.com/w40/${codeLower}.png 2x`}
      width="20"
      height="10"
      className="tw-shadow"
      alt={codeLower}
    />
  );
};

const maskDictionary = countryListPhoneMaskDictionary;

const preparePhoneMask = (mask: string) =>
  mask.replace(/\)/g, ') ').replace(/\(/g, ' (');

const optionsEqualityFn: SelectValueEqualityFn<CountryListItem> = (
  opt1,
  opt2
) => opt1.name === opt2?.name && opt1.phoneCode === opt2?.phoneCode;

const searchFilter = (
  option: SelectOption<CountryListItem>,
  searchInput: string
) => {
  const lowerInput = searchInput.toLowerCase();

  return (
    option.label.toLowerCase().includes(lowerInput) ||
    option.value.phoneCode.toLowerCase().includes(lowerInput)
  );
};

const PhoneInputOption = memo(
  forwardRef(
    (
      props: SelectOptionProps<CountryListItem>,
      ref: React.ForwardedRef<HTMLLIElement>
    ) => {
      return (
        <Combobox.Option
          ref={ref}
          {...props}
          className="tw-flex tw-items-center"
        >
          <ImgCountry isoCode={props.value.isoCode} />
          <div className="tw-ml-2">
            {props.value.name}
            <span
              className={twCx(
                !props.isSelected && 'tw-text-gray-500',
                'tw-text-sm tw-ml-1'
              )}
            >
              {props.value.phoneCode}
            </span>
          </div>
        </Combobox.Option>
      );
    }
  )
);

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (props, htmlRef) => {
    const {
      value: valueProp,
      defaultValue: defaultValueProp = '',
      onChange: onChangeProp,
      placeholder = '+1 (234) 567-89',
      popupStrategy,
      ...htmlProps
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const [input, setInput] = useControllableState<string>({
      value: valueProp,
      onChange: onChangeProp,
      defaultValue: defaultValueProp
    });

    const [selectedCountry, setSelectedCountry] =
      useState<CountryListItem | null>(null);

    const [
      { countryOptions, countryOptionsLongestPhoneCodesFirst },
      setCountryListState
    ] = useState<{
      countryOptions: SelectOption<CountryListItem>[];
      countryOptionsLongestPhoneCodesFirst: SelectOption<CountryListItem>[];
    }>({ countryOptions: [], countryOptionsLongestPhoneCodesFirst: [] });

    useLoadCountryList({
      phoneMaskReplacer: preparePhoneMask,
      onLoad: (countries) => {
        const options = countries.map<SelectOption<CountryListItem>>((opt) => ({
          label: opt.name,
          value: opt
        }));
        const optionsLongestPhoneCodesFirst = options
          .slice()
          .sort((a, b) => b.value.phoneCode.length - a.value.phoneCode.length);

        setCountryListState({
          countryOptions: options,
          countryOptionsLongestPhoneCodesFirst: optionsLongestPhoneCodesFirst
        });

        if (input) {
          const inputInitialWithPlus = '+' + input.replace(/[+]/g, '');

          setSelectedCountry(
            optionsLongestPhoneCodesFirst.find((c) =>
              inputInitialWithPlus.startsWith(c.value.phoneCode)
            )?.value ?? null
          );
        }
      }
    });

    const handleKeyDown = (ev: React.KeyboardEvent) => {
      const key = ev.key;

      // prohibit entering unwanted chars
      // allow only "+" and "digits"
      if (
        /\D/.test(key) &&
        !ev.metaKey &&
        !ev.ctrlKey &&
        'Backspace' !== key &&
        'Tab' !== key &&
        ('+' !== key || !ev.shiftKey)
      ) {
        return ev.preventDefault();
      }
    };

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value.replace(/[^0-9+]/g, '');
      const valueWithPlus = '+' + value.replace(/[+]/g, '');

      const countryMatch =
        countryOptionsLongestPhoneCodesFirst.find((c) =>
          valueWithPlus.startsWith(c.value.phoneCode)
        )?.value ?? null;

      if (countryMatch) {
        const maskLength = countryMatch.phoneMask.replace(
          /[^0-9#+]/g,
          ''
        ).length;

        setInput(
          valueWithPlus.length > maskLength
            ? valueWithPlus.slice(0, maskLength)
            : valueWithPlus
        );
      } else {
        setInput(value);
      }

      setSelectedCountry(countryMatch);
    };

    const handleSelectCountry = (country: CountryListItem | null) => {
      setSelectedCountry(country);

      if (country && !input.startsWith(country.phoneCode)) {
        setInput(country.phoneCode);
      }

      setTimeout(() => {
        inputRef.current?.focus();
      });
    };

    return (
      <Combobox
        options={countryOptions}
        valueEqualityFn={optionsEqualityFn}
        searchFilter={searchFilter}
        value={selectedCountry}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        onChange={handleSelectCountry}
        popupStrategy={popupStrategy}
        popupReferenceRefPropName="refWrapper"
        renderTrigger={(ctx) => (
          <Input
            {...htmlProps}
            as="input"
            type="tel"
            placeholder={placeholder}
            ref={mergeRefs(htmlRef, inputRef)}
            value={
              input && selectedCountry
                ? maskString(input, selectedCountry.phoneMask, maskDictionary)
                : input
            }
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className={twCx(
              'tw-py-0 tw-pl-0 tw-items-stretch tw-flex-nowrap',
              htmlProps.className
            )}
            classNameInput={twCx(
              'tw-py-2 tw-truncate tw-min-w-0',
              htmlProps.classNameInput
            )}
            leftAddon={
              <>
                <Combobox.Trigger
                  UNSAFE_skipFormControlContext
                  onClick={(ev) => ev.stopPropagation()}
                  variant="unstyled"
                  showIcon={false}
                  classNameSelect={twCx(
                    (!htmlProps.variant || htmlProps.variant === 'outline') &&
                      'tw-bg-gray-50 tw-rounded-l-xl',
                    'hover:tw-bg-gray-100 tw-px-3 focus-visible:tw-outline focus-visible:tw-outline-main-500 tw-outline-offset-0'
                  )}
                  className="tw-flex"
                >
                  <div className="tw-w-[20px] tw-text-center">
                    {ctx.selected ? (
                      <ImgCountry isoCode={ctx.selected.isoCode} />
                    ) : (
                      <IconChevronDown.Solid />
                    )}
                  </div>
                </Combobox.Trigger>
                {htmlProps.leftAddon}
              </>
            }
          />
        )}
        renderSearchInput={() => (
          <Combobox.SearchInput placeholder="Search..." />
        )}
        renderOption={(props) => <PhoneInputOption {...props} />}
        renderNoOptions={() => (
          <Combobox.NoOptions>
            {countryOptions.length > 0 ? (
              'No options found'
            ) : (
              <Spinner size="sm" />
            )}
          </Combobox.NoOptions>
        )}
      />
    );
  }
);
