import { DelayUnmount } from '@b2w/react-ui/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import {
  useInitModal,
  UseInitModalProps,
  ModalContext,
  useModalCtx,
  ModalSize
} from './modal.ctx';
import { Portal } from '../Portal';
import { forwardRef } from 'react';
import { FocusLock } from '../FocusLock';
import { IconButton } from '../Button';
import { IconTimes } from '../Icon';
import { AnimateModal, AnimationPreset } from './Animation';
import { modalManager } from './modal.manager';
import { twCx } from '../twMerge';

export interface ModalProps extends UseInitModalProps {
  children?: React.ReactNode;
  /** Classname of element which wraps `Body`, `Footer`, `Header` */
  classNameContent?: string;
}

interface OverrideContentProps {
  __overrideContentClassName?: string;
}
interface OverrideOverlayProps {
  __overrideOverlayClassName?: string;
}

export interface ModalRootBaseProps
  extends ModalProps,
    OverrideContentProps,
    OverrideOverlayProps {
  animationPreset: AnimationPreset;
}

/**
 * @internal
 */
export const ModalRootBase = (props: ModalRootBaseProps) => {
  const {
    children,
    animationPreset,
    __overrideContentClassName,
    __overrideOverlayClassName,
    classNameContent,
    ...modalProps
  } = props;

  const modalCtx = useInitModal(modalProps);
  const { isOpen, hasBackdrop } = modalCtx;

  return (
    <ModalContext.Provider value={modalCtx}>
      <DelayUnmount
        isMounted={isOpen}
        delay={animationPreset.animationDurationMs}
      >
        <Portal>
          <AnimateModal {...animationPreset} isOpen={isOpen}>
            <Overlay __overrideOverlayClassName={__overrideOverlayClassName}>
              {hasBackdrop && <Backdrop />}
              <Content
                __overrideContentClassName={__overrideContentClassName}
                className={classNameContent}
              >
                {children}
              </Content>
            </Overlay>
          </AnimateModal>
        </Portal>
      </DelayUnmount>
    </ModalContext.Provider>
  );
};

const animationPreset: AnimationPreset = {
  animationDurationMs: 300,
  transformWhenHidden: 'translateY(-10px)',
  transformWhenVisible: 'translateY(0px)'
};

const ModalRoot = (props: ModalProps) => (
  <ModalRootBase {...props} animationPreset={animationPreset} />
);

/**
 * @internal
 */
const FocusScope = ({ children }: { children: any }) => {
  const {
    lockBodyScroll,
    modalId,
    autoFocus,
    trapFocus,
    initialFocusRef,
    finalFocusRef,
    returnFocusOnClose
  } = useModalCtx();

  return (
    <FocusLock
      autoFocus={autoFocus}
      disabled={!trapFocus}
      initialFocusRef={initialFocusRef}
      finalFocusRef={finalFocusRef}
      restoreFocus={returnFocusOnClose}
      crossFrame
    >
      <RemoveScroll
        // only block scroll for first dialog
        enabled={modalManager.isFirstModal(modalId) && lockBodyScroll}
        forwardProps
      >
        {children}
      </RemoveScroll>
    </FocusLock>
  );
};

/**
 * @internal
 */
const Overlay = (
  props: { children: React.ReactNode } & OverrideOverlayProps
) => {
  const { children, __overrideOverlayClassName } = props;

  const { getOverlayProps, isCentered, isInsideScrollBehavior } = useModalCtx();

  return (
    <FocusScope>
      <div
        {...getOverlayProps({
          className:
            __overrideOverlayClassName ??
            twCx(
              'tw-z-modal tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-start',
              isCentered && 'tw-items-center',
              isInsideScrollBehavior
                ? 'tw-overflow-y-hidden'
                : 'tw-overflow-auto'
            )
        })}
      >
        {children}
      </div>
    </FocusScope>
  );
};

/**
 * @internal
 */
const Backdrop = () => {
  const { getBackdropProps } = useModalCtx();

  return (
    <div
      {...getBackdropProps({
        className: 'tw-z-below tw-fixed tw-inset-0 tw-bg-dark-900/60'
      })}
    />
  );
};

const sizeToContentClassName: Record<ModalSize, string> = {
  xs: 'tw-w-[20rem]',
  sm: 'tw-w-[24rem]',
  md: 'tw-w-[28rem]',
  lg: 'tw-w-[32rem]',
  xl: 'tw-w-[36rem]',
  xxl: 'tw-w-[64rem] ',
  full: 'tw-w-full tw-min-h-full'
};

/**
 * @internal
 */
const Content = (
  props: {
    children?: React.ReactNode;
    className?: string;
  } & OverrideContentProps
) => {
  const { children, __overrideContentClassName, className } = props;

  const { hasCloseButton, getContentProps, isInsideScrollBehavior, size } =
    useModalCtx();

  return (
    <div
      {...getContentProps({
        className:
          __overrideContentClassName ??
          twCx(
            'tw-flex tw-flex-col tw-relative tw-outline-none tw-bg-white tw-color-inherit tw-shadow-lg',
            sizeToContentClassName[size],
            size !== 'full' && 'tw-rounded-md tw-my-3 sm:tw-my-8 tw-mx-2',
            isInsideScrollBehavior &&
              'tw-min-h-[150px] tw-max-h-[calc(100%-1.5rem)] sm:tw-max-h-[calc(100%-4rem)]',
            className
          )
      })}
    >
      {hasCloseButton && <ModalCloseButton />}
      {children}
    </div>
  );
};

export type ModalContentPartProps = React.ComponentPropsWithRef<'div'>;

const ModalHeader = forwardRef<HTMLDivElement, ModalContentPartProps>(
  (props, ref) => {
    const { className: classNameProp, ...htmlProps } = props;
    const { getHeaderProps } = useModalCtx();

    const className = twCx(
      'tw-flex-grow-0 tw-flex-shrink-1 tw-px-5 tw-pt-5 tw-pb-2 tw-text-lg tw-font-medium',
      classNameProp
    );

    return <div {...getHeaderProps({ ...htmlProps, className }, ref)} />;
  }
);

const ModalBody = forwardRef<HTMLDivElement, ModalContentPartProps>(
  (props, ref) => {
    const { className: classNameProp, ...htmlProps } = props;
    const { getBodyProps, isInsideScrollBehavior } = useModalCtx();

    const className = twCx(
      'tw-px-5 tw-pt-2 tw-pb-5 tw-flex-1',
      isInsideScrollBehavior && 'tw-overflow-y-auto',
      classNameProp
    );

    return <div {...getBodyProps({ ...htmlProps, className }, ref)} />;
  }
);

const ModalFooter = forwardRef<HTMLDivElement, ModalContentPartProps>(
  (props, ref) => {
    const { className: classNameProp, ...htmlProps } = props;
    const { footerId } = useModalCtx();

    const className = twCx(
      'tw-flex tw-items-center tw-justify-end tw-p-5',
      classNameProp
    );

    return <div {...htmlProps} ref={ref} id={footerId} className={className} />;
  }
);

const ModalCloseButton = () => {
  const { close, closeBtnId } = useModalCtx();

  return (
    <IconButton
      id={closeBtnId}
      onClick={close}
      variant="solid"
      colorScheme="inherit"
      aria-label="Close modal"
      className="tw-absolute tw-top-3.5 tw-right-3.5"
      size="sm"
      icon={(s) => <IconTimes.Solid size={s} />}
    />
  );
};

export const Modal = Object.assign(ModalRoot, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter
});
