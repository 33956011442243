import { createIcon } from '../createIcon';

export const IconTriangleDown = {
  Solid: createIcon({
    viewBox: '2 2 20 20',
    pathOrChildren:
      'M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z'
  })
};

export const IconTriangleUp = {
  Solid: createIcon({
    viewBox: '2 2 20 20',
    pathOrChildren:
      'M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z'
  })
};

export const IconArrowRight = {
  Solid: createIcon({
    viewBox: '0 0 70 60',
    fill: 'currentColor',
    pathOrChildren:
      'M46.9761 1.08337C45.5461 -0.361134 43.2217 -0.361134 41.7918 1.08337C40.3794 2.51037 40.3699 4.81217 41.7633 6.25087L58.7383 26.3183L3.6586 26.3183C1.6245 26.3183 0 27.9802 0 30C0 32.0198 1.6245 33.6817 3.6586 33.6817L58.7383 33.6817L41.7633 53.7491C40.3699 55.1878 40.3794 57.4896 41.7918 58.9165C43.2217 60.3611 45.5461 60.3611 46.9761 58.9165L47.0051 58.8872L68.9642 32.5666C70.3449 31.1383 70.3449 28.8617 68.9642 27.4334L47.0051 1.11267L46.9761 1.08337Z'
  })
};

export const IconArrowLongRight = {
  Solid: createIcon({
    viewBox: '0 0 71 36',
    fill: 'currentColor',
    pathOrChildren:
      'M47.9217 1.08345C49.3516 -0.361149 51.676 -0.361149 53.1059 1.08345L53.1349 1.11274L69.0941 15.4334C70.4748 16.8617 70.4748 19.1383 69.0941 20.5666L53.1349 34.8873L53.1059 34.9165C51.676 36.3611 49.3516 36.3611 47.9217 34.9165C46.5093 33.4896 46.4998 31.1878 47.8932 29.7491L57.8682 21.6817H3.7885C1.75436 21.6817 0.129883 20.0198 0.129883 18C0.129883 15.9802 1.75436 14.3183 3.7885 14.3183H57.8682L47.8932 6.25088C46.4998 4.81217 46.5093 2.51039 47.9217 1.08345Z'
  })
};

export const IconArrowLeft = {
  Solid: createIcon({
    viewBox: '0 0 70 60',
    fill: 'currentColor',
    pathOrChildren:
      'M23.0239 58.9166C24.4539 60.3611 26.7783 60.3611 28.2082 58.9166C29.6206 57.4896 29.6301 55.1878 28.2367 53.7491L11.2617 33.6817H66.3414C68.3755 33.6817 70 32.0198 70 30C70 27.9802 68.3755 26.3183 66.3414 26.3183H11.2617L28.2367 6.25088C29.6301 4.81216 29.6206 2.51038 28.2082 1.08344C26.7783 -0.361152 24.4539 -0.361152 23.0239 1.08344L22.9949 1.11274L1.03577 27.4334C-0.34494 28.8617 -0.344933 31.1383 1.03578 32.5666L22.9949 58.8873L23.0239 58.9166Z'
  })
};

export const IconArrowLongLeft = {
  Solid: createIcon({
    viewBox: '0 0 71 36',
    fill: 'currentColor',
    pathOrChildren:
      'M22.3378 34.9165C20.9079 36.3611 18.5835 36.3611 17.1536 34.9165L17.1246 34.8873L1.16542 20.5666C-0.21529 19.1383 -0.215298 16.8617 1.16541 15.4334L17.1246 1.11274L17.1536 1.08345C18.5835 -0.361149 20.9079 -0.361149 22.3378 1.08345C23.7502 2.51039 23.7597 4.81216 22.3663 6.25088L12.3914 14.3183H66.471C68.5052 14.3183 70.1296 15.9802 70.1296 18C70.1296 20.0198 68.5052 21.6817 66.471 21.6817H12.3914L22.3663 29.7491C23.7597 31.1878 23.7502 33.4896 22.3378 34.9165Z'
  })
};

export const IconChevronLeft = {
  Solid: createIcon({
    viewBox: '0 0 42 70',
    fill: 'currentColor',
    pathOrChildren:
      'M36.9127 6.56907C38.4426 4.97835 38.3463 2.50098 36.6976 1.03572C35.0488 -0.429535 32.472 -0.327827 30.942 1.26289L1.12569 32.2638C0.637714 32.7712 0.315182 33.3687 0.155685 33.9949C-0.220486 35.266 0.0830956 36.6921 1.0873 37.7362L30.9037 68.7371C32.4336 70.3278 35.0104 70.4295 36.6592 68.9643C38.3079 67.499 38.4042 65.0217 36.8743 63.4309L9.54892 35.02L36.9127 6.56907Z'
  })
};

export const IconChevronRight = {
  Solid: createIcon({
    viewBox: '0 0 36 71',
    fill: 'currentColor',
    pathOrChildren:
      'M1.08731 7.37962C-0.442633 5.78889 -0.34632 3.31153 1.30243 1.84627C2.95118 0.381012 5.52801 0.482719 7.05795 2.07343L36.8743 33.0743C37.3623 33.5817 37.6848 34.1793 37.8443 34.8054C38.2205 36.0766 37.9169 37.5026 36.9127 38.5467L7.09634 69.5477C5.5664 71.1384 2.98957 71.2401 1.34082 69.7748C-0.307932 68.3096 -0.404246 65.8322 1.12569 64.2415L28.4511 35.8305L1.08731 7.37962Z'
  })
};

export const IconChevronUp = {
  Solid: createIcon({
    viewBox: '0 0 70 39',
    fill: 'currentColor',
    pathOrChildren:
      'M63.2595 37.0925C64.8459 38.6298 67.3166 38.5331 68.7779 36.8763C70.2392 35.2195 70.1377 32.6301 68.5513 31.0928L37.6342 1.13127C37.1282 0.640919 36.5323 0.316814 35.9078 0.156544C34.6401 -0.221458 33.2179 0.0835991 32.1766 1.09269L1.25948 31.0542C-0.326941 32.5916 -0.428374 35.1809 1.03293 36.8377C2.49423 38.4945 4.96489 38.5913 6.55131 37.0539L34.8855 9.5955L63.2595 37.0925Z'
  })
};

export const IconChevronDown = {
  Solid: createIcon({
    viewBox: '0 0 70 39',
    fill: 'currentColor',
    pathOrChildren:
      'M63.2595 1.0926C64.8459 -0.444788 67.3166 -0.348006 68.7779 1.30877C70.2392 2.96554 70.1377 5.55492 68.5513 7.09231L37.6342 37.0538C37.1282 37.5441 36.5323 37.8682 35.9078 38.0285C34.6401 38.4065 33.2179 38.1015 32.1766 37.0924L1.25948 7.13088C-0.326941 5.59349 -0.428374 3.00412 1.03293 1.34734C2.49423 -0.309431 4.96489 -0.406214 6.55131 1.13117L34.8855 28.5896L63.2595 1.0926Z'
  })
};

export const IconDownload = {
  Outline: createIcon({
    fill: 'currentColor',
    pathOrChildren: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.48276 91.9653C4.24558 91.9653 1.2569e-06 87.7198 1.02797e-06 82.4826L-1.38369e-06 60.3102C-1.48775e-06 57.9296 1.92981 55.9998 4.31034 55.9998C6.69088 55.9998 8.62069 57.9296 8.62069 60.3102L8.62069 82.4826C8.62069 82.9587 9.00665 83.3446 9.48276 83.3446L85.9195 83.3446C86.3956 83.3446 86.7816 82.9587 86.7816 82.4826L86.7816 60.3102C86.7816 57.9296 88.7114 55.9998 91.0919 55.9998C93.4725 55.9998 95.4023 57.9296 95.4023 60.3102L95.4023 82.4826C95.4023 87.7198 91.1567 91.9653 85.9195 91.9653L9.48276 91.9653Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.22 48.7205L50.7891 71.1513C49.1058 72.8346 46.3766 72.8346 44.6933 71.1513L20.2625 48.7205C18.5792 47.0372 18.5792 44.308 20.2625 42.6247C21.9458 40.9414 24.6749 40.9414 26.3582 42.6247L43.4309 57.6973L43.4309 4.31034C43.4309 1.92981 45.3607 0 47.7412 0C50.1218 0 52.0516 1.92981 52.0516 4.31034L52.0516 57.6973L69.1242 42.6247C70.8075 40.9414 73.5367 40.9414 75.22 42.6247C76.9032 44.308 76.9032 47.0372 75.22 48.7205Z"
        />
      </>
    ),
    viewBox: '0 0 96 92'
  })
};

export const IconLogout = {
  Outline: createIcon({
    viewBox: '0 0 175 166',
    pathOrChildren: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 17C0.5 7.88731 7.8873 0.5 17 0.5H113C117.142 0.5 120.5 3.85786 120.5 8C120.5 12.1421 117.142 15.5 113 15.5H17C16.1716 15.5 15.5 16.1716 15.5 17V150C15.5 150.828 16.1716 151.5 17 151.5H113C117.142 151.5 120.5 154.858 120.5 159C120.5 163.142 117.142 166.5 113 166.5H17C7.8873 166.5 0.5 159.113 0.5 150V17Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.574 30.9672L172.303 78.6969C175.232 81.6258 175.232 86.3746 172.303 89.3035L124.574 137.033C121.645 139.962 116.896 139.962 113.967 137.033C111.038 134.104 111.038 129.356 113.967 126.427L148.893 91.5002L56 91.5002C51.8579 91.5002 48.5 88.1423 48.5 84.0002C48.5 79.8581 51.8579 76.5002 56 76.5002L148.893 76.5002L113.967 41.5738C111.038 38.6449 111.038 33.8961 113.967 30.9672C116.896 28.0383 121.645 28.0383 124.574 30.9672Z"
        />
      </>
    ),
    fill: 'currentColor'
  })
};
