import { useProfile } from '@/context/profile.context';
import { useToggler } from '@/custom-hooks/useToggler';
import AppLink from '@/components/AppLink';
import { Router } from 'next/router';
import {
  Accordion,
  Button,
  ButtonProps,
  Popup,
  twCx
} from '@b2w/react-ui/core2';
import React, { useEffect, useRef } from 'react';
import { useOutsideClick } from '@b2w/shared/react-hooks';
import { theme } from '@/styles/sc-theme';
import { MenuItem } from '@/lib/menu-definition.types';
import {
  DesktopProfilePopupMenuItemCtxData,
  desktopProfilePopupMenuItems
} from './DesktopProfilePopupMenuItems';

type MenuListProps = {
  children?: React.ReactNode;
};

const MenuList = ({ children }: MenuListProps) => {
  return <ul>{children}</ul>;
};

type MenuListItemProps = {
  children?: React.ReactNode;
  /** @default false */
  isHoverable?: boolean;
};

const MenuListItem = ({ children, isHoverable = false }: MenuListItemProps) => {
  return (
    <li className={twCx('tw-text-left', isHoverable && 'hover:tw-bg-gray-100')}>
      {children}
    </li>
  );
};

const MenuView = () => {
  const { profile } = useProfile();

  const renderMenuListItem = (
    item: MenuItem<DesktopProfilePopupMenuItemCtxData>
  ) => {
    switch (item.type) {
      case 'conditional': {
        const conditionalLink = item.visibleByData(profile);

        return conditionalLink ? renderMenuListItem(conditionalLink) : null;
      }
      case 'separator': {
        return (
          <li aria-hidden="true">
            <hr className="tw-my-2" />
          </li>
        );
      }
      case 'custom': {
        return (
          <MenuListItem isHoverable={item.isHoverable}>
            {item.renderCustomItem(
              profile,
              twCx(
                'tw-p-3',
                item.isInsideSubmenu && 'tw-pl-5',
                item.isOutlined ? 'tw-font-bold' : 'tw-font-normal'
              )
            )}
          </MenuListItem>
        );
      }
      case 'link': {
        return (
          <MenuListItem isHoverable={item.isHoverable}>
            <AppLink
              href={item.href}
              className={twCx(
                'tw-p-3 tw-flex tw-items-center tw-justify-between hover:tw-no-underline tw-group',
                item.isInsideSubmenu && 'tw-pl-5',
                item.isOutlined ? 'tw-font-medium' : 'tw-font-normal'
              )}
            >
              <div className="group-hover:tw-underline">{item.text}</div>
              {!!item.iconContent && (
                <div className="tw-ml-4">{item.iconContent(profile)}</div>
              )}
            </AppLink>
          </MenuListItem>
        );
      }
      case 'accordion': {
        return (
          <MenuListItem isHoverable={item.isHoverable}>
            <Accordion applyFadeOnCollapse mountUnmout>
              <Accordion.Item
                className="tw-border-b-0 tw-p-0"
                summary={(props) => {
                  return (
                    <Accordion.Summary
                      {...props}
                      className={twCx(
                        'tw-p-3',
                        item.isInsideSubmenu && 'tw-pl-5',
                        item.isOutlined ? 'tw-font-medium' : 'tw-font-normal'
                      )}
                    >
                      {item.text}
                    </Accordion.Summary>
                  );
                }}
              >
                {(props) => (
                  <Accordion.Details {...props} className="tw-pb-0">
                    <MenuList>
                      {item.links.map((itemLink, idx) => (
                        <React.Fragment key={`accordion-item-${idx}`}>
                          {renderMenuListItem(itemLink)}
                        </React.Fragment>
                      ))}
                    </MenuList>
                  </Accordion.Details>
                )}
              </Accordion.Item>
            </Accordion>
          </MenuListItem>
        );
      }
    }
  };

  return (
    <MenuList>
      {desktopProfilePopupMenuItems.map((item, itemIdx) => (
        <React.Fragment key={`item-${itemIdx}`}>
          {renderMenuListItem(item)}
        </React.Fragment>
      ))}
    </MenuList>
  );
};

type DesktopProfilePopupMenuProps = ButtonProps;

const DesktopProfilePopupMenu = (btnProps: DesktopProfilePopupMenuProps) => {
  const [isOpen, toggle, setIsOpen] = useToggler(false);

  const popupBodyRef = useRef(null);
  const popupTriggerRef = useRef(null);

  useEffect(() => {
    const close = () => {
      setIsOpen(false);
    };
    Router.events.on('beforeHistoryChange', close);

    return () => {
      Router.events.off('beforeHistoryChange', close);
    };
  }, [setIsOpen]);

  useOutsideClick({
    enabled: isOpen,
    ref: popupBodyRef,
    handler: (event) => {
      if (!popupTriggerRef.current?.contains(event.target as HTMLElement)) {
        setIsOpen(false);
      }
    }
  });

  return (
    <Popup isOpen={isOpen} placement="bottom-end" inPortal={false} focusLock>
      <Popup.Trigger>
        <Button {...btnProps} onClick={toggle} ref={popupTriggerRef} />
      </Popup.Trigger>
      <Popup.Positioner>
        <Popup.Body
          ref={popupBodyRef}
          style={
            {
              '--menu-max-height': `calc(100vh - ${theme.navbarHeight + 10}px)`
            } as React.CSSProperties
          }
          className="tw-w-64 tw-max-h-[--menu-max-height] tw-bg-white tw-rounded-xl tw-shadow-lg tw-border tw-overflow-y-auto"
        >
          <MenuView />
        </Popup.Body>
      </Popup.Positioner>
    </Popup>
  );
};

export default DesktopProfilePopupMenu;
