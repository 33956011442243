import { useSignOut } from '@/custom-hooks/useSignOut';
import { Button, ButtonProps, IconLogout } from '@b2w/react-ui/core2';

type SignOutBtnProps = Omit<
  ButtonProps,
  'type' | 'rightAddon' | 'children' | 'onClick'
>;

const SignOutBtn = (props: SignOutBtnProps) => {
  const { signOut, isSigningOut } = useSignOut();

  return (
    <Button
      {...props}
      type="button"
      rightIcon={(s) => <IconLogout.Outline size={s} />}
      isLoading={props.isLoading || isSigningOut}
      onClick={signOut}
    >
      Log out
    </Button>
  );
};

export default SignOutBtn;
