export const accountLinks = [
  // {
  //   i18nKey: 'dashboard',
  //   href: '/dashboard'
  // },
  // {
  //   i18nKey: 'settings',
  //   href: '/dashboard/settings'
  // },
  // {
  //   i18nKey: 'verifications',
  //   href: '/dashboard/verifications'
  // },
  {
    i18nKey: 'profile_settings',
    href: '/dashboard/settings',
    text: 'Profile settings'
  }
];

export const renterLinks = [
  {
    i18nKey: 'rentals',
    href: '/dashboard/rentals',
    text: 'Rentals'
  },
  {
    i18nKey: 'favorites',
    href: '/dashboard/favorites',
    text: 'Favorites'
  },
  {
    i18nKey: 'b2w_points',
    href: '/dashboard/b2wpoints',
    text: 'B2W Points'
  }
];

export const ownerLinks = [
  {
    i18nKey: 'booking_schedule',
    href: '/dashboard/bookingschedule',
    text: 'Bookings Timetable'
  },
  {
    i18nKey: 'my_vehicles',
    href: '/dashboard/vehicles',
    text: 'My Vehicles'
  },
  {
    i18nKey: 'add_new_vehicle',
    href: '/dashboard/vehicles/add',
    text: 'List a Vehicle'
  },

  {
    i18nKey: 'gateway',
    href: '/dashboard/payouts',
    text: 'Payouts'
  },
  {
    i18nKey: 'earnings',
    href: '/dashboard/earnings',
    text: 'Earnings'
  }
];

export const helpLinks = [
  {
    i18nKey: 'support',
    text: 'Support',
    href: '/support'
  },
  {
    i18nKey: 'how_it_works',
    text: 'How it works?',
    href: '/howitworks'
  },
  {
    i18nKey: 'insurance',
    text: 'Insurance',
    href: '/insurance'
  },
  {
    i18nKey: 'contact_us',
    text: 'Contact us',
    href: '/contact'
  }
];
