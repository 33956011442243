import { forwardRef } from 'react';
import { Select, SelectHtmlProps, SelectRoot } from '../Select/Select';
import { UseInitSelectPublicProps } from '../Select/select.init';
import { Input, InputProps } from '../Input';
import {
  AutocompleteCtx,
  UseInitAutocompleteProps,
  useAutocompleteClearBtn,
  useAutocompleteCloseOpenBtn,
  useAutocompleteInput,
  useAutocompleteSelectedTag,
  useAutocompleteSelectedTags,
  useInitAutocomplete
} from './autocomplete.init';
import { IconTimes, IconTriangleDown } from '../Icon';
import { ComponentPropsWithRef } from '../types';
import { IconButton, IconButtonProps } from '../Button';
import { twCx } from '../twMerge';

export type AutocompleteHtmlProps<TValue, TMultiple> = Omit<
  SelectHtmlProps<TValue, TMultiple>,
  'renderTrigger' | 'popupReferenceRefPropName'
> & {
  /**
   * Custom renderer for autocomplete input
   *
   * @example
   * renderInput={(ctx) => (
   *   <Autocomplete.Input className="overrides" data-is-open={ctx.isOpen} />
   * )}
   */
  renderInput?: SelectHtmlProps<TValue, TMultiple>['renderTrigger'];
  /**
   * Prop name on input element for passing ref to position the popup.
   *
   * Use only when creating custom input component instead of using `Autocomplete.Input`, otherwise do not touch!
   *
   * @default 'refWrapper'
   */
  popupReferenceRefPropName?: string;
};

export type AutocompleteProps<TValue, TMultiple> = Omit<
  UseInitSelectPublicProps<TValue, TMultiple>,
  keyof UseInitAutocompleteProps<TValue>
> &
  UseInitAutocompleteProps<TValue> &
  AutocompleteHtmlProps<TValue, TMultiple>;

type AutocompleteComponent = <TValue, TMultiple = false>(
  props: AutocompleteProps<TValue, TMultiple>
) => React.JSX.Element;

const AutocompleteRoot = (<TValue, TMultiple extends boolean = false>(
  props: AutocompleteProps<TValue, TMultiple>
) => {
  const {
    renderInput,
    popupReferenceRefPropName = 'refWrapper',
    defaultInputValue,
    inputValue,
    onInputValueChange,
    placeholder,
    searchFilter,
    inputSelectionOnFocus,
    whenSelectedShow,
    visibleSelectedTagsLimit,
    clearSelectedValueOnTypingEmpty,
    hasClearBtn,
    ...selectRootProps
  } = props;

  const { selectRootOverrides, autocompleteCtx } = useInitAutocomplete(
    {
      defaultInputValue,
      inputValue,
      onInputValueChange,
      placeholder,
      searchFilter,
      inputSelectionOnFocus,
      whenSelectedShow,
      visibleSelectedTagsLimit,
      clearSelectedValueOnTypingEmpty,
      hasClearBtn
    },
    selectRootProps
  );

  const renderTrigger: SelectHtmlProps<TValue, TMultiple>['renderTrigger'] = (
    ctx
  ) => {
    return typeof renderInput === 'function' ? (
      renderInput(ctx)
    ) : (
      <AutocompleteInput />
    );
  };

  return (
    <AutocompleteCtx.Provider value={autocompleteCtx}>
      <SelectRoot
        {...selectRootProps}
        {...selectRootOverrides}
        popupReferenceRefPropName={popupReferenceRefPropName}
        renderTrigger={renderTrigger}
      />
    </AutocompleteCtx.Provider>
  );
}) as AutocompleteComponent;

export type AutocompleteInputProps = Omit<InputProps<'input'>, 'as'>;

export const AutocompleteInput = forwardRef(
  <TValue, TMultiple>(
    props: AutocompleteInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { inputProps } = useAutocompleteInput<TValue, TMultiple>(props, ref);
    const { visibleTags, visibleTagsNumOfMore } = useAutocompleteSelectedTags<
      TValue,
      TMultiple
    >();
    const { clearBtnProps, isClearBtnVisible, mustShowClearBtn } =
      useAutocompleteClearBtn<TValue, TMultiple>();
    const { closeOpenBtnProps, isCloseOpenBtnOpen } =
      useAutocompleteCloseOpenBtn<TValue, TMultiple>();

    return (
      <Input
        {...inputProps}
        as="input"
        className={twCx('tw-relative tw-group tw-pr-20', inputProps.className)}
        leftAddon={
          <>
            {inputProps.leftAddon}
            {visibleTags.length > 0 && (
              <>
                {visibleTags.map((optionValue, idx) => (
                  <SelectedTag key={idx} optionValue={optionValue} />
                ))}
                {visibleTagsNumOfMore > 0 && (
                  <span>+{visibleTagsNumOfMore}</span>
                )}
              </>
            )}
          </>
        }
        rightAddon={
          <>
            {inputProps.rightAddon}
            <div
              className={twCx(
                'tw-absolute tw-top-1/2 tw-right-0 -tw-translate-y-1/2 tw-mr-2 tw-flex tw-items-center'
              )}
            >
              {mustShowClearBtn && (
                <IconButton
                  {...(clearBtnProps as IconButtonProps<'button'>)}
                  variant="ghost"
                  className={twCx(
                    'tw-rounded-full group-hover:tw-inline-flex',
                    !isClearBtnVisible && 'tw-hidden'
                  )}
                  colorScheme="inherit"
                  size="xs"
                  icon={(s) => <IconTimes.Solid size={s} />}
                />
              )}
              <IconButton
                {...(closeOpenBtnProps as IconButtonProps<'button'>)}
                variant="ghost"
                className={twCx(
                  'tw-rounded-full',
                  'tw-transition-transform',
                  isCloseOpenBtnOpen && 'tw-rotate-180'
                )}
                colorScheme="inherit"
                size="xs"
                icon={(s) => <IconTriangleDown.Solid size={s} />}
              />
            </div>
          </>
        }
      />
    );
  }
);

export type AutocompleteSelectedTagProps<TValue> = ComponentPropsWithRef<
  'div',
  {
    optionValue: TValue;
  }
>;

type SelectedTagComponent = <TValue>(
  props: AutocompleteSelectedTagProps<TValue> & {
    ref?: React.ForwardedRef<HTMLDivElement>;
  }
) => React.JSX.Element;

export const SelectedTag = forwardRef(
  <TValue,>(
    props: AutocompleteSelectedTagProps<TValue>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { optionValue, ...htmlProps } = props;

    const { tagLabel, tagProps, tagRemoveBtnProps } =
      useAutocompleteSelectedTag<TValue, false>(optionValue, htmlProps, ref);

    return (
      <div
        {...tagProps}
        className="tw-flex tw-items-stretch tw-min-w-0 tw-h-[24px]"
      >
        <span className="tw-bg-gray-100 tw-text-gray-800 tw-rounded-l tw-flex tw-items-center tw-truncate tw-text-sm tw-pl-2 tw-pr-1">
          {tagLabel}
        </span>
        <IconButton
          {...(tagRemoveBtnProps as IconButtonProps)}
          colorScheme="unstyled"
          className="tw-bg-gray-100 hover:tw-bg-gray-200 tw-text-gray-800 tw-rounded-l-none tw-rounded-r tw-size-[24px] tw-p-0"
          icon={<IconTimes.Solid size="2xs" />}
        />
      </div>
    );
  }
) as SelectedTagComponent;

/** Autocomplete Select component. It composes `Select` component under the hood. */
export const Autocomplete = Object.assign(AutocompleteRoot, {
  Input: AutocompleteInput,
  SelectedTag: SelectedTag,
  PopupBody: Select.PopupBody,
  Listbox: Select.Listbox,
  Option: Select.Option,
  OptionGroup: Select.OptionGroup,
  NoOptions: Select.NoOptions
});
