import { CSSProperties, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { CommonFadeProps } from './types';
import { assignFadeCssVars, classes } from './Fade.classes';

export interface FadeProps extends CommonFadeProps {
  /**
   * Trigger fade
   */
  in: boolean;
  /**
   * Whether to mount and unmout children when animation start/completes
   *
   * @default false
   */
  mountUnmout?: boolean;
  /**
   * Whether to show fade animation when initial `in` state is `true`
   *
   * @default true
   */
  animateOnFirstMount?: boolean;
  transformOrigin?: string;
}

const animationClassnames = {
  appear: classes.enter,
  appearActive: classes.enterActive,
  enter: classes.enter,
  enterActive: classes.enterActive,
  exit: classes.exit,
  exitActive: classes.exitActive,
  exitDone: classes.exitActive
};

/**
 * Fade effect
 */
export const Fade = ({
  in: animate,
  duration = 300,
  initialOffsetX = 0,
  initialOffsetY = 0,
  finalOffsetX = 0,
  finalOffsetY = 0,
  initialScale = 1,
  finalScale = 1,
  mountUnmout = false,
  animateOnFirstMount = true,
  className = '',
  transformOrigin,
  children
}: FadeProps): JSX.Element => {
  const [isInitiallyExited] = useState(animate === false);
  const mustAddExitClass = isInitiallyExited && !mountUnmout;

  const wrapStyles = {
    ...assignFadeCssVars({
      duration,
      initialOffsetX,
      finalOffsetX,
      initialOffsetY,
      finalOffsetY,
      initialScale,
      finalScale
    }),
    transformOrigin
  } as CSSProperties;

  const onEnter: CSSTransitionProps['onEnter'] = (node: HTMLElement) => {
    if (node && mustAddExitClass) {
      node.classList.remove(...animationClassnames.exitDone.split(' '));
    }
  };

  return (
    <CSSTransition
      in={animate}
      unmountOnExit={mountUnmout}
      appear={animateOnFirstMount}
      timeout={duration}
      classNames={animationClassnames}
      onEnter={onEnter as any}
    >
      <div
        style={wrapStyles}
        // CSSTransition component does not add exitDone class
        // if initially in={false}, do that manually
        className={`${
          mustAddExitClass ? animationClassnames.exitDone : ''
        } ${className}`}
      >
        {children}
      </div>
    </CSSTransition>
  );
};
