import AppLink from '@/components/AppLink';
import { Alert } from '@b2w/react-ui/core2';

type Props = {
  onLinkClick?: () => any;
  showSupportLink?: boolean;
  title?: string;
  newStyle?: boolean;
  className?: string;
};

const YourAccSuspendedMsg = ({
  onLinkClick,
  title = 'Account suspended',
  showSupportLink = true,
  newStyle = false,
  className
}: Props) => {
  if (newStyle) {
    return (
      <Alert
        variant="soft"
        status="error"
        title={title}
        icon={showSupportLink ? null : undefined}
        className={className}
      >
        {showSupportLink && (
          <div>
            Visit our{' '}
            <AppLink
              href="/support"
              onClick={onLinkClick}
              className="tw-text-gray-600 tw-font-medium"
            >
              Support centre
            </AppLink>
          </div>
        )}
      </Alert>
    );
  } else
    return (
      <div className={className}>
        <div className="font-weight-500 text-danger">{title}</div>
        {showSupportLink && (
          <div className="small text-secondary-dark">
            Visit{' '}
            <AppLink href="/support" onClick={onLinkClick}>
              <b>Help & Support</b>
            </AppLink>{' '}
            for more information
          </div>
        )}
      </div>
    );
};

export default YourAccSuspendedMsg;
